@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0;
  transition: all ease-in 0.2s;
  @include media-breakpoint-down(sm) {
    height: unset;
  }
  .modalWrap {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    justify-content: space-between;
    background-color: white;
    width: 1200px;
    gap: 3rem;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
      width: 100%;
    }
    .head {
      max-width: 450px;
      align-self: center;
      & p {
        text-align: center;
        white-space: break-spaces;
      }
    }
    .content {
      display: block;
      max-width: 100vw;
      padding: 0 1rem;
    }
    .buttons {
      align-self: center;
      :first-child {
        border: 2px black solid;
        &:hover,
        &:focus {
          transform: scale(0.99);
        }
      }
    }
  }
}
