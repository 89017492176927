@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.heroImage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ece6dd;
  position: relative;
  .headerWrap {
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
    .ss {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: -60px;
    }
    & span {
      font-family: "Exmouth";
      font-size: 8rem;
      letter-spacing: 0;
      font-weight: 100;
      @include media-breakpoint-down(sm) {
        font-size: 5.5rem;
        padding-right: 0;
        line-height: 120px;
      }
    }
    .name {
      padding-top: 10px;
      font-weight: 100;
      letter-spacing: 0;
      font-size: 2.5rem;
      font-family: "Helvetica";
    }
  }
  .scrollDown {
    margin-top: 80px;
    & i {
      color: #e76852;
    }
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  .profilePic {
    position: absolute;
    bottom: 0;
    right: 25%;
    width: 200px;
    height: 200px;
    transform: translateY(50%);
    @include media-breakpoint-down(md) {
      right: 20%;
    }
    @include media-breakpoint-down(sm) {
      right: 50%;
      transform: translate(50%, 50%);
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 8px solid #fff;
      border-radius: 50%;
      z-index: 4;
    }

    .pulseOverlay {
      // z-index: -1;
      // position: absolute;
      // top: -6%;
      // left: -6%;
      // border-radius: 50%;
      // width: 112%;
      // height: 112%;
      // transform: scale(1);
      // animation: pulse 2s infinite;

      z-index: -1;
      position: absolute;
      background-color: #003f5a;
      bottom: -4%;
      left: -4%;
      border-bottom-left-radius: 110px;
      border-bottom-right-radius: 110px;
      width: 108%;
      height: 54%;
      transform: scale(1);
      animation: pulse 2s infinite;
    }

    & svg {
      position: absolute;
      z-index: 200;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      transform: translate(-50%, -50%);
      animation: opa 2s infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        opacity: 0;
      }

      50% {
        transform: scale(1);
        opacity: 0.6;
      }

      100% {
        transform: scale(0.95);
        opacity: 0;
      }
    }

    @keyframes opa {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}
/*------------------------------------------------------------------------------------------------*/
// Bio slider
.signatureWrapper {
  margin-top: 115px;
  padding-bottom: 0;
  @include media-breakpoint-down(md) {
    margin-top: 40px;
    & h3 {
      font-size: 2.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 120px;
    & h3 {
      font-size: 2rem;
    }
  }
  @include media-breakpoint-down(xs) {
    overflow: hidden;
  }
  .bioImgWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100px;
    @include media-breakpoint-down(md) {
      display: none;
    }
    & img {
      margin-top: 50px;
      position: absolute;
      max-width: 140%;
      top: 0%;
      right: -40%;
      @include media-breakpoint-down(xl) {
        max-width: 110%;
        right: -10%;
        margin-top: 10px;
      }
    }
    .bioImg2 {
      @include media-breakpoint-up(xl) {
        top: 45%;
      }
      max-width: 60%;
      top: 45%;
      left: -35px;
      margin-top: 0;
      @include media-breakpoint-down(xl) {
        max-width: 60%;
        top: 37%;
      }
    }
  }
}
.indicatorItem {
  transition: all ease-in-out 0.5s !important;
  letter-spacing: 3px !important;
  @include media-breakpoint-down(md) {
    font-size: 2rem !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }
  &.active {
    transform: scale(1.1) !important;
    color: black !important;
  }
  .indicatorBar {
    transition: all ease-in-out 0.5s !important;
    &.active {
      width: 180px !important;
      left: -200px !important;
    }
  }
}
.slide {
  min-height: 480px;
  @include media-breakpoint-down(lg) {
    min-height: 585px;
  }
  @include media-breakpoint-down(md) {
    min-height: 100%;
  }
  & p {
    font-size: 14px !important;
  }
}
.signatureCardWrapper {
  padding-top: 80px;
  padding-bottom: 50px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  @include media-breakpoint-down(sm) {
    margin: 0 15px;
  }
  &:last-child {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
}

// Player Modal
.playerPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .playerWrap {
    background-color: transparent;
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
    padding: 50px;
    position: relative;
    overflow: scroll;
    & span {
      position: absolute;
      top: 0%;
      right: 0%;
      background: transparent;
      color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer;
      & i {
        font-size: 20px;
        transform: translateY(4px);
      }
    }

    @include media-breakpoint-down(sm) {
      min-width: 90vw;
      height: 90vh;
      padding: 30px 15px;
    }
  }
}
