@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.filersWrap {
  @import "../../../../styles/Global.scss";
  display: flex;
  flex-direction: column;

  .clearFilters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba($color: $black, $alpha: 0.3);
    margin-top: 1rem;
    padding-top: 1rem;
    cursor: pointer;
  }

  .innerFilterWrap {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba($color: $black, $alpha: 0.3);
    margin-top: 1rem;
    height: auto !important;
    opacity: 1;

    .title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 1rem 0;
      cursor: pointer;

      & p {
        font-size: 0.9rem;
        letter-spacing: 1.2px;
      }

      & svg {
        transform: rotate(180deg);
        transition: all 0.2s ease-in;
        fill: rgba($color: $black, $alpha: 0.5);
      }
    }
    .rotateChevron {
      & svg {
        transform: rotate(0deg);
        transition: all 0.2s ease-in;
      }
    }
    .range {
      opacity: 1;
      transition: opacity 0.2s ease-in;
      & > div {
        &:first-child {
          width: 95%;
          margin: 0 auto;
        }
      }
      .rangeInput {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 1rem;

        & input {
          max-width: 48%;
        }
      }
    }
    .filterItem {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 0.2rem;
      transition: opacity 0.2s ease-in;
      height: auto;
      opacity: 1;

      .filterLabel {
        margin: 0;
        margin-left: 0.5rem;
        text-transform: capitalize;
        cursor: pointer;
        touch-action: manipulation;
      }
      .filterInput {
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 0;
        cursor: pointer;
        touch-action: manipulation;

        &:checked {
          filter: grayscale(100%);
        }
      }
    }
    & small {
      margin-top: 0.5rem;
      color: $orange;
      cursor: pointer;
    }
  }
  .collapsed {
    transition: opacity 0s ease-in;
    .filterItem,
    .range {
      height: 0 !important;
      opacity: 0;
      visibility: hidden;
      margin-bottom: 0;
    }
  }
}
