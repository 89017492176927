@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.collectiveDashboardPageWrap {
  @import "../../../styles/Global.scss";

  max-width: 100vw;
  /*------------------------------------------------------------------------------------------------*/
  // Hero Section
  .heroSection {
    min-height: 400px;
    padding: 4rem 1rem;
    .heroRow {
      .contentCol {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .title {
          display: flex;
          align-items: center;
          flex: 0 0 70%;
          order: 1;
        }
        .content {
          flex: 0 0 70%;
          min-width: 500px;
          order: 3;
          & p {
            padding: 1.5rem 0;
            font-weight: 400;
            color: rgba($black, 0.6);
            letter-spacing: 1px;
            max-width: 645px;
          }
        }
        .barrelbankFeature {
          flex: 0 0 30%;
          order: 2;
          .barrelbankWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 1rem;
            width: 100%;
            & img {
              max-width: 60px;
              opacity: 0.5;
            }
            .cta {
              & a {
                font-size: 0.9rem;
                font-weight: 400;
                text-decoration: underline;
                text-underline-offset: 4px;
                text-decoration-thickness: 1.5px;
              }
            }
          }
        }
        .accountLink {
          order: 4;
          flex: 0 0 30%;
          min-width: 200px;
          padding: 1.5rem 0;
          margin-top: auto;
          display: flex;
          justify-content: flex-end;
          & a {
            padding: 0.4rem 2rem;
          }
        }
      }
      .navLinks {
        margin-top: 1.5rem;
        overflow-y: scroll;
        white-space: nowrap;
        &::-webkit-scrollbar {
          display: none;
        }
        & a {
          border-bottom: 1px solid $black;
          padding-bottom: 0.2rem;
          margin-right: 0.75rem;
          font-weight: 400;
          font-size: 0.9rem;
          white-space: nowrap;
          & svg {
            margin-left: 0.3rem;
          }
        }
      }
    }
  }
  /*------------------------------------------------------------------------------------------------*/
  // Refer A Friend Section
  .referOuterWrap {
    background: url("../../../assets/home-page/.v2/home-collective-banner.jpg")
      no-repeat center;
    background-size: cover;
    background-position: 25% center;
    background-blend-mode: overlay;
    background-color: rgba($color: $black, $alpha: 0.5);
    .referInnerWrap {
      .referRow {
        .referCol {
          min-height: 600px;
          max-height: 100vh;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 3rem;

          & p {
            font-size: 2rem;
          }

          & h2,
          & p,
          & a {
            color: $beige;
            text-align: center;
            z-index: 2;
          }
        }
      }
    }
  }
  // BB Section
  .bbOuterWrap {
    .bbInnerWrap {
      padding: 2rem 0;
    }
  }
  // Events Section
  .eventsOuterWrap {
    background-color: $black;
    .eventsInnerWrap {
      min-height: 600px;
      padding: 4rem 1rem;
      .mainVidRow {
        .titleCol {
          & h4 {
            color: $white;
          }
          & p {
            margin-top: 2rem;
            color: rgba($white, 0.7);
            font-size: 0.9rem;
            max-width: 260px;
            text-align: justify;
          }
        }
        .contentCol {
          // background-color: rgba($white, 0.2);
          width: 100%;
          min-height: 500px;
          // padding: 0;

          .fullVid {
            position: relative;
            width: 100%;
            height: 100%;

            .mainVidMarkup {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              & > iframe {
                height: 100%;
                width: 100%;
              }
              & h3 {
                color: $beige;
                & span {
                  font-family: $domaineHeading;
                }
              }
            }

            .fullVidCover {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 101%;
              height: 100%;
              background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
              padding: 2rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              transition: height 0.15s ease-out;
              & small {
                color: rgba($beige, 0.7);
              }
              & h5 {
                font-size: 1.5rem;
                color: $beige;
                font-weight: normal;
                // font-family: $helvetica;
                margin-top: 0.5rem;
                margin-bottom: 1rem;
              }
              & p {
                color: $beige;
                text-decoration: underline;
                text-underline-offset: 3px;
                font-weight: bolder;
                cursor: pointer;
              }
            }
            // &:hover {
            .hideCover {
              padding: 0;
              height: 0% !important;
              & small,
              & h5,
              & p {
                display: none;
              }
            }
            // }
          }
        }
      }
      .cardsRow {
        .cardsCol {
          @include media-breakpoint-up(md) {
            padding-right: 3rem;
          }
          margin-top: 2rem;
          // padding: 0;
          .vidSort {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;
            gap: 1rem;
            & label {
              color: rgba($beige, 0.6);
              // margin: 0;
            }
            & select {
              background-color: transparent;
              border: none;
              color: $beige;
              outline: 0 transparent !important;
              border-bottom: 1px solid $beige;
              padding-bottom: 5px;
            }
          }
          .upcomingVids {
            .card {
              // max-width: 290px;
              opacity: 0.5;
              padding-left: 0.2rem;
              padding-right: 0.8rem;
              .vidWrap {
                height: 180px;
                width: 100%;
                overflow: hidden;
                position: relative;
                .tastingPackBanner {
                  position: absolute;
                  top: 0%;
                  left: 0;
                  font-size: 0.9rem;
                  padding: 0.2rem 0.5rem;
                  color: $beige;
                  background-color: $orange;
                }
                & img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
              .content {
                height: 100%;
                padding-right: 1rem;
                & p {
                  color: rgba($beige, $alpha: 0.6);
                  font-size: 0.9rem;
                  margin: 1rem 0;
                }
                & h5 {
                  color: $beige;
                  // font-family: $helvetica;
                  font-weight: normal;
                  line-height: 1.2;
                  height: 48px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                .btnPrimary {
                  margin-top: 1.5rem;
                  margin-bottom: 0.5rem;
                  padding: 0.5rem;
                  width: 100%;
                }
              }
            }
            .activeCard {
              opacity: 1;
            }
            .activeCards {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  /*------------------------------------------------------------------------------------------------*/
  // Exclusive Products Section
  .exclusiveProducts {
    // min-height: 600px;
    padding: 2rem 1rem;
    .innerRow {
      .titleCol {
        display: flex;
        align-items: flex-end;
      }
      .buttonsCol {
        display: flex;
        justify-content: flex-end;
        .buttonsWrap {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: nowrap;
          gap: 0.5rem;
          @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
            gap: 0.35rem;
          }
        }
      }
      .sorterCol {
        .sorterWrap {
          & small {
            cursor: pointer;
            margin-right: 1rem;
            font-weight: 500;
            color: rgba($black, 0.6);
            border-bottom: 2px solid transparent;
            transition: all 0.1s ease-in;
            padding-bottom: 0.3rem;
          }
          .activeSort {
            color: rgba($black, 1);
            border-bottom: 2px solid $black;
          }
        }
      }
      .productsCol {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 2rem;
        .caskUpsell {
          width: 100%;
          height: 100%;
          min-height: 400px;
          max-height: 400px;
          background: url("../../../assets/gifting-page/giftingCasks.jpg")
            no-repeat;
          background-blend-mode: overlay;
          background-color: rgba($black, 0.35);
          background-size: cover;
          background-position: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          & h3 {
            color: $beige;
            font-family: $bebas !important;
          }
          & p {
            color: $beige;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            text-align: center;
          }
        }
        .sampleCard {
          width: 24%;
          height: 100%;
          background-color: rgba($black, 0.6);
          min-height: 450px;
        }
      }
    }
  }
  /*------------------------------------------------------------------------------------------------*/
  // The Collective Podcasts Section
  .collectivePodcasts {
    min-height: 600px;
    padding: 3rem 1rem;
    .innerRow {
      .titleCol {
        & h4 {
        }
        & p {
          padding: 1rem 0;
          font-weight: 400;
          color: rgba($black, 0.6);
        }
      }
      .sorterCol {
        .sorterWrap {
          display: flex;
          flex-direction: row;
          & small {
            margin-right: 0.5rem;
            // font-size: 0.9rem;
            color: rgba($black, 0.6);
          }
          & p {
            font-size: 0.9rem;
            font-weight: 400;
            border-bottom: 1px solid $black;
          }
        }
      }
      .podcastsCol {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 2rem;
        .sampleCard {
          width: 24%;
          height: 100%;
          background-color: rgba($black, 0.6);
          min-height: 300px;
        }
      }
    }
  }
  /*------------------------------------------------------------------------------------------------*/
  // Benefits Section
  .benefitsWrap {
    min-height: 600px;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    .innerRow {
      .titleCol {
        text-align: center;
        & p {
          max-width: 650px;
          padding: 2rem 0;
          text-align: center;
          margin: 0 auto;
        }
      }
      .benefitsGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem 1rem;
        margin: 1rem 0;
        .gridItem {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .imgWrap {
            max-height: 40px;
            margin-bottom: 1rem;
            & img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          & h5 {
            // font-family: $helvetica;
            font-weight: 400;
            letter-spacing: 0px;
            margin-bottom: 0.5rem;
            text-align: center;
          }
          & small {
            color: rgba($black, $alpha: 0.5);
            max-width: 240px;
            min-height: 39px;
            text-align: center;
          }
        }
      }
      .reviewButton {
        margin-top: 3rem;
        text-align: center;
      }
      .renewalDate {
        & p {
          color: rgba($black, 0.6);
          margin-top: 3rem;
          text-align: center;

          & span {
            color: rgba($black, 0.7);
          }
        }
      }
    }
  }
  /*------------------------------------------------------------------------------------------------*/
  // Media Queries
  @include media-breakpoint-down(sm) {
    // Hero Section
    .heroSection {
      min-height: unset;
      padding: 2rem 1rem;
      .heroRow {
        .contentCol {
          align-items: center;
          .title {
            flex: 0 0 65%;
            & h3 {
              font-size: 1.7rem;
            }
          }
          .content {
            min-width: unset;
            flex: 100%;
            margin-top: 1rem;
            & p {
              text-align: center;
            }
          }
          .barrelbankFeature {
            flex: 0 0 35%;
            .barrelbankWrap {
              gap: 0.5rem;
              & img {
                max-width: 40px;
              }
              .cta {
                & h4 {
                  font-size: 1.25rem;
                  line-height: 0.7;
                }
                & a {
                  font-size: 0.9rem;
                }
              }
            }
          }
          .accountLink {
            justify-content: center;
          }
        }
        .navLinks {
          & a {
            & svg {
            }
          }
        }
      }
    }
    /*------------------------------------------------------------------------------------------------*/
    // Events Section
    .eventsOuterWrap {
      .eventsInnerWrap {
        padding: 3rem 1rem;
        .mainVidRow {
          .titleCol {
            margin-bottom: 2rem;
            & h4 {
              text-align: center;
            }
            & p {
              max-width: 100%;
              text-align: center;
              color: rgba($beige, 0.8);
              // display: none;
            }
          }
          .contentCol {
            .fullVid {
              .mainVidMarkup {
                & > iframe {
                }
              }

              .fullVidCover {
                padding: 2rem 1rem;
                & small {
                }
                & h5 {
                }
                & p {
                }
              }
            }
          }
        }
        .cardsRow {
          .cardsCol {
            .vidSort {
              & label {
              }
              & select {
              }
            }
            .upcomingVids {
              .card {
                .vidWrap {
                }
                .content {
                  & p {
                  }
                  & h5 {
                  }
                  .btnPrimary {
                  }
                }
              }
              .activeCard {
              }
            }
          }
        }
      }
    }
    .exclusiveProducts {
      padding: 2rem 1rem;
      .innerRow {
        .titleCol {
          justify-content: center;
        }
        .buttonsCol {
          margin-top: 1.5rem;
          justify-content: center;
        }
      }
    }
    .benefitsWrap {
      padding: 2rem 1rem;

      .innerRow {
        .benefitsGrid {
          grid-template-columns: repeat(2, 1fr);
          .gridItem {
            & h5 {
              min-height: 36px;
            }
          }
        }
      }
    }
  }
}
