@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.privateSalePageWrapper {
  @import "../../../styles/Global.scss";
  max-width: 100vw;
  width: 100%;
  overflow: hidden !important;
  position: relative;

  .heroContainer {
    position: relative;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 10%;
      height: 90%;
      width: 100vw;
      background-color: $beige;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      width: 200px;
      height: 200px;
      background: url("../../../assets/images/share-dots.svg") no-repeat;
      background-size: contain;
      filter: invert(60%) sepia(82%) saturate(3985%) hue-rotate(333deg)
        brightness(103%) contrast(81%);
      // filter: invert(88%) sepia(77%) saturate(0%) hue-rotate(203deg)
      //   brightness(93%) contrast(78%);
      // filter: invert(62%) sepia(4%) saturate(1756%) hue-rotate(135deg)
      //   brightness(88%) contrast(83%);
      bottom: 5%;
      right: 0px;
      z-index: 23;
    }
    .heroRow {
      position: relative;
      height: calc(100vh - 100px);
      max-height: 850px;
      .imgCol {
        padding: 2rem;
        width: 100%;
        height: 100%;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .fallbackImgCol {
        width: 100%;
        .imgWrap {
          position: relative;
          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .caskShadow {
            width: 100%;
            height: 106%;
            position: absolute;
            left: 0;
            top: -1px;
          }
          & h4 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            width: 100%;
            color: #222;
            line-height: 1.2;
            font-family: Stencilia-A Regular;
            text-transform: uppercase;
          }
        }
      }
      .contentCol {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: -10%;
        .content {
          .subTitle {
            margin-bottom: 0.5rem;
          }
          .title {
            font-size: 2.5rem;
            & h3 {
              font-size: 2.5rem;
            }
            margin-bottom: 0.5rem;
            & span {
              font-size: 1.25rem;
              letter-spacing: 1px;
            }
          }
          .distilleryName {
            line-height: 1;
          }
          .age {
            line-height: 0.5;
            font-size: 1.5rem;
            margin-top: 2rem;
          }
          .price {
            margin-bottom: 1rem;
            & span {
              font-size: 1.5rem;
              font-weight: 500;
            }
          }
          .bottles {
            margin-bottom: 1rem;
          }
          & a {
            font-weight: 500;
            margin-right: 2rem;
            white-space: nowrap;
            & i {
              color: $orange;
              transform: translateY(0);
              transition: transform 0.15s ease-in;
            }
            &:hover {
              & i {
                transform: translateY(5px);
              }
            }
          }
          .paragraphFall {
            max-width: 290px;
            margin-bottom: 2rem;
            &:first-of-type {
              max-width: 320px;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }
          }
          & div {
            & p {
              max-width: 325px;
              margin-bottom: 1rem;
              text-align: start;
              white-space: pre-wrap;
              line-height: 1.4;
              &:first-of-type {
                max-width: 325px;
                margin-top: 2rem;
                margin-bottom: 1rem;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            .title {
              margin-top: 0.75rem;
              font-size: 1.5rem;
              & h3 {
                font-size: 1.5rem;
              }
              & span {
                font-size: 1rem;
              }
            }
            .distilleryName {
              margin-top: 0.25rem;
              line-height: 1;
            }
            .age {
              font-size: 1.25rem;
            }
          }
          @include media-breakpoint-down(xs) {
            .title {
              word-break: break-word;
              & * {
                word-break: break-word;
              }
            }
          }
        }
      }
      .contentFallback {
        justify-content: flex-start;
        .content {
        }
      }
    }
  }
  .enterBallotWrap {
    height: 600px;
    // padding-top: 2rem;
    margin-bottom: 6rem;
    .imgWrap {
      position: relative;
      @include media-breakpoint-up(lg) {
        height: 100%;
      }
      .top {
        height: 85%;
        width: 80%;
        margin-bottom: auto;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .bottom {
        position: absolute;
        bottom: 0%;
        right: 10%;
        // height: 50%;
        // width: 40%;
        height: 300px;
        width: 300px;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .contentWrap {
      display: flex;
      align-items: center;
      min-height: 500px;
      padding-bottom: 2rem;
      .inner {
        margin-left: 0%;
        overflow: hidden;
        padding-right: 1rem;
        @include media-breakpoint-down(lg) {
          margin-left: 0%;
        }
        & h3 {
          max-width: 440px;
          letter-spacing: 4px;
          margin-bottom: 1rem;
          @include media-breakpoint-up(md) {
            font-size: 3rem;
          }
          margin-top: 0.1rem;
        }
        & h5 {
          margin-top: -1rem;
        }
        .descriptionWrap {
          // max-height: 400px;
          // overflow-y: scroll;
          & p {
            max-width: 700px;
            margin: 1rem 0;
            text-align: justify;
          }
        }
        .selectionGrid {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          margin-top: 2rem;
          @media (max-width: 420px) {
            flex-wrap: wrap;
            .currency {
              flex: 0 0 100% !important;
            }
          }
          .wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1 1 48%;

            & p {
              margin: 0;
              margin-bottom: 0.5rem;
            }

            & > div {
              display: flex;
              justify-content: center;
              height: 100%;
            }
            .selectWrap {
              width: 100%;
              margin: 0;
              & select {
                padding: 0.62rem 1rem;
                border-radius: 0;
                // font-family: $helvetica;
                height: initial;
              }
            }
          }
          .currency {
            flex: 3 1 100%;
          }
        }
        .buttonWrap {
          margin-top: 2rem;
          display: flex;
          flex-direction: row;
          .btnLight {
            margin-left: 1rem;
            border-color: transparent;
            & i {
              color: $orange;
              transform: translateY(0);
              transition: transform 0.15s ease-in;
            }
            &:hover {
              background-color: transparent !important;
              border-color: transparent !important;
              & i {
                transform: translateY(5px);
              }
            }
            &:focus {
              outline-color: transparent !important;
              border-color: transparent !important;
            }
          }
        }
        .fallbackP {
          @include media-breakpoint-up(md) {
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .artistWrap {
    height: 550px;
  }

  .mapSection {
    margin-bottom: 6rem;
    min-height: 400px;
    .contentWrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .inner {
        text-align: left;
        padding: 2rem 0rem;
        .descriptionWrap {
          padding: 1rem 0;
        }
        & h3 {
          margin-bottom: 1rem;
        }
      }
    }
    .mapWrap {
      .top {
        width: 100%;
        height: 100%;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left center;
        }
      }
    }
  }

  .ballotInfoCon {
    padding-bottom: 0;
    .ballotInfo {
      .contentCol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content {
          .subTitle {
            margin-bottom: 0.5rem;
          }
          .title {
            max-width: 370px;
            font-size: 2.5rem;
            margin-bottom: 0.5rem;
            & h3 {
              font-size: 2.5rem;
            }
            & span {
              font-size: 1.25rem;
              letter-spacing: 1px;
            }
          }
          .slider {
            margin-top: 1rem;
          }
          .sliderFallback {
            & > div > div {
              justify-content: flex-start;
              & h3 {
                margin-right: 2rem;
              }
            }
          }
          @include media-breakpoint-down(sm) {
            .title {
              font-size: 1.5rem;
              & h3 {
                font-size: 1.5rem;
              }
            }
            .distilleryName {
              margin-top: 0.25rem;
              line-height: 1;
            }
          }
          @include media-breakpoint-down(xs) {
            .title {
              word-break: break-word;
              & * {
                word-break: break-word;
              }
            }
          }
        }
      }
      .imgCol {
        position: relative;
        width: 100%;
        z-index: -1;
        min-height: 600px;
        & img {
          position: absolute;
          object-fit: cover;
          object-position: bottom;
          top: 0%;
          left: 0;
          width: 50vw;
          height: 100%;
          z-index: -1;
        }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   width: 100vw;
        //   height: 100%;
        //   top: 0;
        //   left: 0;
        //   background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        // }
      }
    }
  }

  // Footer Block
  /*------------------------------------------------------------------------------------------------*/
  .startCollection {
    position: relative;
    height: 600px;
    max-height: 90vh;
    background-color: $beige;
    overflow: hidden;
    padding: 0 1rem;
    margin-top: 0px;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../../../assets/images/share-dots-light.png") no-repeat;
      height: 400px;
      width: 400px;
      background-size: contain;
      opacity: 0.9;
    }
    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .contactDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & a {
          margin-bottom: 0;
          margin-left: 0.5rem;
        }
      }

      & span {
        width: 100px;
        height: 6px;
        background-color: $black;
        margin-bottom: 4rem;
      }

      & > * {
        color: $black;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
      }

      & h2 {
        margin-bottom: 3rem;
      }

      & p,
      & a,
      & h5 {
        max-width: 530px;
        color: $black;
        margin-bottom: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 0;
      &::after {
        height: 300px;
        width: 300px;
      }
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      .inner {
        padding: 2.5rem;
      }
    }
  }

  // Media Queries
  /*------------------------------------------------------------------------------------------------*/

  @include media-breakpoint-down(md) {
    .heroContainer {
      padding-bottom: 0;
      &::after {
        left: 0%;
        height: 100%;
      }
      &::before {
        display: none;
      }
      .heroRow {
        padding: 2rem 0;
        height: auto;
        max-height: unset;
        .imgCol {
          order: 2;
          .imgWrap {
            & h4 {
              font-size: 1.5rem;
            }
          }
        }
        .contentCol {
          padding-top: 0rem;
          order: 1;
          width: 100%;
        }
      }
    }
    .enterBallotWrap {
      height: 100%;
      padding-top: 2rem;
      margin-bottom: 3rem;
      .imgWrap {
        .top {
          width: 100%;
          height: 100%;
        }
        .bottom {
          display: none;
        }
      }
      .contentWrap {
        min-height: unset;
        .inner {
          margin-left: initial !important;
        }
      }
    }
    .mapSection {
      margin-bottom: 3rem;
      min-height: unset;
    }
    .ballotInfoCon {
      padding-top: 2rem;
      margin-bottom: 3rem;
      .ballotInfo {
        justify-content: flex-start;
        .contentCol {
          .content {
            // .slider {
            //   margin-top: 0rem !important;
            //   & > div {
            //     padding: 1rem !important;
            //     & p {
            //       font-size: 1rem !important;
            //     }
            //     & h3 {
            //       font-size: 1.15rem !important;
            //       padding: 0 !important;
            //       margin-bottom: 0 !important;
            //       padding-right: 0.75rem !important;
            //       &:last-child {
            //         padding-right: 0 !important;
            //       }
            //     }
            //   }
            // }
          }
        }
        .imgCol {
          min-height: 400px;
          &::before {
            content: "";
            width: 100vw;
          }
          & img {
            width: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .heroContainer {
      .heroRow {
        height: auto;
        max-height: unset;
        .imgCol {
          order: 2;
          & > div {
            max-width: 300px;
          }
        }
        .contentCol {
          order: 1;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          .title {
            margin: 0.5rem 0;
          }
        }
      }
    }
    .enterBallotWrap {
      padding-top: 0;
      height: auto;
      margin-bottom: 0;
      .imgWrap {
        order: 2;
        .top {
          width: 100%;
          height: 400px;
        }
        .bottom {
          display: none;
        }
      }
      .contentWrap {
        order: 1;
        padding: 2rem;
        .inner {
          padding-right: 0rem;
          & h3 {
            margin-top: 0.1rem;
          }
          .buttonWrap {
            & button {
              font-size: 0.9rem;
              padding: 0.7rem 1rem;
            }
          }
        }
      }
    }
    .mapSection {
      margin-bottom: 0rem;
      min-height: unset;
    }
    .artistWrap {
      margin-bottom: 0;
    }
    .ballotInfoCon {
      margin-bottom: 0;
      .ballotInfo {
        .contentCol {
          .slider {
            margin-top: 0rem !important;
            & > div {
              padding: 1rem !important;
              & > div {
                padding: 0;
              }
            }
          }
        }
        .imgCol {
          min-height: 400px;
          &::before {
            content: "";
            width: 100vw;
          }
        }
      }
    }
    // Footer Block
    /*------------------------------------------------------------------------------------------------*/
    .startCollection {
      height: 100%;
      min-height: 400px;
      margin-top: 0;
      .inner {
        & span {
          width: 60px;
          height: 4px;
          margin-bottom: 2rem;
        }
        & h2 {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .heroContainer {
      .heroRow {
        height: auto;
        max-height: unset;
        .imgCol {
          order: 2;
          margin-top: 0rem;
          justify-content: flex-end;
          padding: 1rem;
          & > div {
            max-width: 300px;
          }
        }
        .contentCol {
          order: 1;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
        }
      }
    }
    .enterBallotWrap {
      .imgWrap {
        .top {
        }
        .bottom {
        }
      }
      .contentWrap {
        .inner {
        }
      }
    }
    .ballotInfoCon {
      padding-top: 1rem;
      .ballotInfo {
        .contentCol {
        }
        .imgCol {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  // QTY PICKER
  .qtyPicker {
    display: flex;
    flex-direction: row;
    // gap: 0.5rem;
    justify-content: center;
    align-items: center;

    & p {
      margin: 0 !important;
    }

    & * {
      border: 1px solid black;
      padding: 0.5rem 1rem;
      background-color: transparent;
      font-weight: bolder;

      &:disabled {
        opacity: 0.3;
      }

      &:focus {
        outline: transparent;
      }
    }

    & button {
      &:first-child {
        border-right: 0px;
      }
      &:last-child {
        border-left: 0px;
      }
    }
  }
}

// Success Page
/*------------------------------------------------------------------------------------------------*/
.successWrap {
  @import "../../../styles/Global.scss";

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100%);
  margin: 0 0.5rem;
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
    & h4 {
      text-align: center;
    }
    & p {
      margin: 1rem 0;
    }
    & span {
      width: 100%;
      height: 2px;
      background: black;
      border-bottom: 2px solid black;
    }
  }

  .sectionWrap {
    padding: 0 2rem;
    width: 100%;
    // Cask Details
    .caskDetailRow {
      padding: 3rem 0;
      // padding-bottom: 6rem;
      .imgWrap {
        max-height: 300px;
        margin: 2rem 0;
        cursor: pointer;
        position: relative;
        & > div {
          height: 100%;
        }
      }

      .contentWrap {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .titleWrap {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .distillery {
            border-bottom: 2px black solid;
            width: min-content;
            & p {
              font-weight: bold;
              white-space: nowrap;
              padding-bottom: 0.5rem;
            }
          }

          & h3 {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            & a {
              text-decoration: none !important;
              word-break: break-all;
            }
            &:hover {
              & a {
                text-decoration: underline !important;
              }
            }
          }
          .descriptionWrap {
            .description {
              max-width: 470px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .fullDescription {
              overflow: visible;
              height: auto;
              display: block;
            }
            .more {
              color: $orange;
              text-decoration: underline;
              margin-top: -0.25rem;
              cursor: pointer;
            }
          }
        }
        .detailWrap {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .caskInfoWrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem 0;
            .inner {
              & p {
                font-size: 0.8rem;
              }
              .info {
                color: rgba($black, 0.75);
              }
            }
          }
        }

        .ctaWrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 1rem;
          margin-top: 1rem;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .heading {
      margin-top: 2rem;
      & * {
        text-align: center;
      }
    }
    .sectionWrap {
      padding: 0;
      .caskDetailRow {
        padding: 1rem 0;
        .imgWrap {
          max-height: 250px;
        }
      }
    }
  }
}

// Ballot Closed
/*------------------------------------------------------------------------------------------------*/
.ballotClosed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 40vh);
  text-align: center;
  margin: 0 0.5rem;
  white-space: break-spaces;
  & h4 {
    text-align: center;
  }
  & p {
    margin: 1rem 0;
  }
  .btnPrimary {
    margin-top: 0.5rem;
  }
}
