@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.aboutUs {
  @import "../../styles/Global.scss";

  .spaceTop {
    margin-top: 4rem;
    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
  & p {
    @include media-breakpoint-down(md) {
      font-size: 0.9rem;
    }
  }
  .link {
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    i {
      font-size: 30px;
      color: #e76852;
    }
    & a {
      border-bottom: 2px solid transparent;
      text-decoration: none;
      color: #000;
      height: 100%;
      font-weight: bold;
      text-transform: uppercase;
      &:focus {
        outline: 0;
      }
      &:hover {
        border-bottom: 2px solid #000;
      }
      i {
        font-size: 20px;
        margin-left: 15px;
        vertical-align: middle;
        color: #e76852;
      }
    }
  }
  .press {
    .headingCol {
      display: flex;
      justify-content: center;
      & img {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        & img {
          padding-top: 20px;
          display: block;
          width: 100vw;
        }
        & h3 {
          text-align: center;
        }
        & br {
          display: none;
        }
      }
      .underline {
        text-decoration: underline;
      }
    }
    .quoteCol {
      padding: 0 50px;
      @include media-breakpoint-down(sm) {
        padding: 30px;
        & .link {
          display: none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      & .link {
        margin-top: 50px;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        & img {
          position: absolute;
          inset: 0 0;
        }
      }
    }
    .imageCol {
      display: flex;
      justify-content: flex-start;
      height: 450px;
      & img {
        width: 100%;
        object-fit: cover;
      }
      @include media-breakpoint-down(md) {
        margin-top: -50px;
        padding: 0;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .quoteWrapper {
      padding: 70px 0 0 55px;

      @include media-breakpoint-down(md) {
        padding: 10px 0 0 55px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 50px 50px 50px;
      }
      & img {
        height: 70px;
        padding-bottom: 30px;
      }
      & h5 {
        font-size: 1.45rem;
        padding-bottom: 20px;
        line-height: 1;
      }
      small {
        opacity: 0.6;
        font-size: 1rem;
      }
      & .link {
        display: none;
      }
    }
  }
  .ourJourney {
    background-color: #ece6dd;
    margin-top: -50px;
    min-height: 550px;
    max-width: 100vw;
    position: relative;
    z-index: -1;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      min-height: 500px;
      margin-top: 1rem;
    }
    & img {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 120%;
      object-fit: cover;
      opacity: 0.3;
      @include media-breakpoint-down(lg) {
        width: 140%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .titleCol {
      display: flex;
      justify-content: space-between;
      padding-top: 100px;
      margin: 0 auto;
      max-width: 1150px;
      @include media-breakpoint-down(md) {
        max-width: 100vw;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin: 0 20px;
        & p {
          display: none;
        }
      }
      p {
        max-width: 450px;
        @include media-breakpoint-down(sm) {
          margin-left: 0px;
        }
      }
    }
  }
  .timelineGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    min-height: 200px;
    position: relative;
    margin: 0 auto;
    height: calc(20vh - 10px);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      border-top: 2px dashed rgba(0, 0, 0, 0.5);
      top: 100px;
      left: 0;
      height: 0;
      width: 120%;
      transform: translateX(-33%);
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: none;
      grid-template-rows: repeat(5, 1fr);
      height: auto;
      &::before {
        border-top: 0;
        border-left: 2px dashed rgba(0, 0, 0, 0.5);
        top: 0px;
        left: 40px;
        height: 100%;
        width: 0px;
        transform: translateX(0);
        transform: translateY(20px);
      }
    }
  }
  .pressGrid {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    min-height: 200px;
    max-width: 1600px;
    margin: 0 auto;
    grid-column-gap: 10px;
    @include media-breakpoint-up(md) {
      padding: 0 30px;
    }
    .pressItem {
      &:nth-last-child(2) {
        display: none;
      }
    }
    & .link {
      justify-self: center;
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      min-height: 100px;
      & a {
        width: 100%;
        margin: 0 auto;
      }
    }
    @include media-breakpoint-up(lg) {
      justify-self: flex-start;
    }
    @include media-breakpoint-down(md) {
      grid-auto-flow: unset;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      & .link {
        justify-self: center;
      }
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-wrap: wrap;
      & .link {
        display: flex;
        align-items: center;
        min-width: 250px;
        margin: 0 auto;
        padding: 0 60px;
        text-align: center;
        &:nth-last-child(6) {
          display: none;
        }
      }
    }
  }
  .parallaxCol {
    min-height: 40vh;
    height: 600px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/Rye-Cask.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-attachment: fixed;
    & img {
      max-width: 50vw;
      filter: invert(60%) sepia(82%) saturate(3985%) hue-rotate(333deg)
        brightness(103%) contrast(81%);
    }
    @include media-breakpoint-down(sm) {
      height: 0;
      background-size: cover;
      & img {
        max-width: 30vw;
      }
    }
  }
  .teamHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 100px;
    gap: 1rem;
    &::before {
      content: "";
      position: absolute;
      top: -80px;
      left: 50%;
      width: 100px;
      border-top: 6px solid #000;
      transform: translateX(-50%);
    }
    & h3 {
      max-width: 750px;
      text-align: center;
    }
    & p {
      max-width: 450px;
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 120px;
      & * {
        padding: 0 1rem;
      }

      &::before {
        top: -50px;
      }
    }
  }
  .caskWrapper {
    overflow-x: hidden;
    .caskHeading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 120px;
      &::before {
        content: "";
        position: absolute;
        top: -60px;
        left: 50%;
        width: 100px;
        border-top: 6px solid #000;
        transform: translateX(-50%);
      }
      & h3 {
        max-width: 675px;
        text-align: center;
        margin-top: 0.1rem;
      }
    }
    .caskButton {
      text-align: center;
      margin-top: 60px;

      @include media-breakpoint-down(sm) {
        margin: 0px;
        padding: 2rem;
        margin-bottom: 1rem;
      }
    }

    .pouringCol {
      min-height: 650px;
      height: 40vh;
      width: 100vw;
      background: url("../../assets/images/pouring.png") no-repeat 50% 40%;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.2);
      background-blend-mode: multiply;
      .pouringWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        @include media-breakpoint-down(lg) {
          grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(sm) {
          grid-template-columns: 1fr;
          padding: 50px 0px;
          height: 0;
        }
        .heading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          gap: 1rem;
          & p {
            color: #fff;
          }
          & h3 {
            color: #ece6dd;
            margin-bottom: 2rem;
          }
        }
      }
      .pouringButton {
        margin-top: 50px;
        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
        & button {
          background-color: transparent;
          border-color: #fff;
          padding: 20px 20px 13px 20px;
          .link {
            & a,
            i {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.whoWeAre {
  @import "../../styles/Global.scss";
  .innerWrap {
    padding-top: 4rem;
    .contentCol {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      & h3 {
        margin-bottom: 2rem;
        & span {
          font-family: $domaineHeading;
        }
      }
      & p {
        & span {
          font-weight: bold;
        }
      }
    }
    .titleCol {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .imgWrap {
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: invert(60%) sepia(82%) saturate(3985%) hue-rotate(333deg)
            brightness(103%) contrast(81%);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .titleCol {
        display: none;
      }
      .contentCol {
        text-align: center;
      }
    }
  }
}

.mobileView {
  @include media-breakpoint-down(sm) {
    padding: 0;
    margin: 0;
    max-width: 100vw;
    width: 100%;
    overflow: hidden;
  }
}
