@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.priceRow {
  @import "../styles/Global.scss";

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  .priceWrap {
    padding: 0;
    min-width: 120px;
    & small {
      font-weight: 300;
      font-size: 0.8rem;
    }
    .collectivePrice {
      font-size: 1.5rem;
      font-weight: 300;
      color: $orange;
      background-color: transparent !important;
    }
    .nonCollective {
      font-size: 1.5rem;
      font-weight: 300;
      color: $black;
      background-color: transparent !important;
    }
  }
  .buttonWrap {
    min-width: 170px;
    padding: 0;
    min-width: 170px;
    .btnPrimary,
    .btnLight {
      width: 100%;
    }
    .btnLight {
      margin-bottom: 0.5rem;
    }
  }
  .banner {
    margin: 0 auto;
    margin-top: 1rem;
    width: calc(100% - 30px);
    padding: 0.2rem;
    text-align: center;
    & p,
    & a {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
  .collectivePrice {
    background-color: rgba($color: $orange, $alpha: 1);
    & p,
    & a {
      color: $white;
    }
  }
  .nonCollective {
    background-color: rgba($color: $blue, $alpha: 0.2);
    & p,
    & a {
      color: $black;
    }
  }
}

.imageModal {
  animation-duration: 0.2s;
  animation-name: slidein;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $black, $alpha: 8);

  .imgWrap {
    height: 100%;
    width: 100%;
    max-height: 700px;
    max-width: 600px;
    background-color: white;
    padding: 2rem;
    & img {
      height: 100%;
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;

    & svg {
      & * {
        stroke: white;
      }
      @media (max-width: 660px) {
        & * {
          stroke: black;
        }
      }
    }
  }
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
