.expired-notice {
  text-align: center;
  & span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;
  line-height: 1.25rem;
  font-weight: 700;
  font-size: 1.25rem;
  gap: 1rem;

  & > p {
    font-weight: normal;
    font-size: 1rem;
  }

  .countdown-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    // padding: 0.5rem;
    // border: 1px solid #ebebeb;
    // border-radius: 0.25rem;
    text-decoration: none;
    color: #000;

    .countdown {
      line-height: 1.25rem;
      padding: 0 0.25rem 0 0.25rem;
      align-items: center;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      & span {
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    & p {
      margin: 0 !important;
      padding: 0;
    }

    .bounceTime {
      animation: bounce 1400ms ease-in;
    }

    .bounceSec {
      animation: bounce 1000ms infinite;
    }

    .danger p {
      color: #ff0000;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
  }
  75% {
    transform: translateY(0px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}
