@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.outerPageWrap {
  @import "../../styles/Global.scss";

  max-width: 100vw;
  visibility: hidden;
  opacity: 0;

  .innerPageWrap {
    padding-bottom: 0;
    .videoRow {
      padding-top: 2rem;
      .videoCol {
        min-height: 400px;
        .videoWrapper {
          position: relative;
          margin: 0 -15px;
          height: 100%;
          &::after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.25);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          & svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5rem;
            height: 5rem;
            cursor: pointer;
            transform: translate(-50%, -50%);
            z-index: 2;
            z-index: 2;
            color: $white;
            font-size: 5rem;
            animation: opa 2s infinite;
          }

          .background {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5rem;
            height: 5rem;
            transform: translate(-58%, -50%);
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.7);
            animation: pulse 2s infinite;
            z-index: 1;
          }
        }
      }
      .contentCol {
        display: flex;
        flex-direction: column;
        & p {
          margin-top: 1rem;
        }
        .button {
          padding-top: 1rem;
          margin-top: auto;
        }
      }
      @include media-breakpoint-down(sm) {
        .videoCol {
          order: 2;
        }
        .contentCol {
          order: 1;
          padding-bottom: 2rem;
        }
      }
    }

    .supportRow {
      padding-top: 2rem;
      .supportItem {
        display: flex;
        flex-direction: column;

        & p {
          padding-top: 1rem;
          max-width: 430px;
          & span {
            font-weight: bolder;
            cursor: pointer;
          }
        }

        .imgWrapper {
          position: relative;
          max-height: 350px;
          margin: 0 -15px;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0.25);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }

          & h4 {
            position: absolute;
            top: 50%;
            left: 1rem;
            letter-spacing: 5px;
            line-height: 1.1;
            transform: translateY(-50%);
            color: white;
            z-index: 2;
            &::after {
              content: "";
              position: absolute;
              top: -1rem;
              left: 0;
              width: 80px;
              height: 4px;
              background-color: white;
            }
          }
        }
        @include media-breakpoint-up(md) {
          // padding: 0;
          // .imgWrapper {
          //   margin: 0px;
          // }
        }
      }
      @include media-breakpoint-down(md) {
        .supportItem {
          & p {
            padding-bottom: 2rem;
          }

          & h4 {
            font-size: 1.75rem;
          }
        }
      }
    }
  }

  // Player Modal
  .playerPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .playerWrap {
      background-color: transparent;
      max-height: 100vh;
      max-width: 100vw;
      width: 100%;
      height: 100%;
      padding: 50px;
      position: relative;
      overflow: scroll;
      & span {
        position: absolute;
        top: 0%;
        right: 0%;
        background: transparent;
        color: white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        cursor: pointer;
        & i {
          font-size: 20px;
          transform: translateY(4px);
        }
      }

      @include media-breakpoint-down(sm) {
        min-width: 90vw;
        height: 90vh;
        padding: 30px 15px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    // transform: scale(0.95);
    opacity: 0;
  }

  50% {
    // transform: scale(1);
    opacity: 0.6;
  }

  100% {
    // transform: scale(0.95);
    opacity: 0;
  }
}

@keyframes opa {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.95;
  }

  100% {
    opacity: 1;
  }
}
