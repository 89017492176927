@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.blur {
  filter: blur(4px);
}

.marketModal {
  @import "../../../styles/Global.scss";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: white;
    max-width: 450px;
    z-index: 3;
    box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);

    .content {
      text-align: center;
      & p {
        & span {
          font-weight: bold;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;

      & button {
        width: fit-content;
      }
    }
  }
}

.privateSalePageWrap {
  @import "../../../styles/Global.scss";
  .blackBG {
    background-color: $black;
    display: flex;
    z-index: 3;
  }
  .beigeBG {
    background-color: $beige;
    display: flex;
    z-index: 2;
  }
  .whiteBG {
    background-color: $white;
    display: flex;
    z-index: 1;
  }

  .spacer {
    margin-bottom: 6rem;
    padding: 0 1rem;
    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }

  // Desktop
  .sectionWrap {
    padding: 0 2rem;
    //Active Step
    .activeStepRow {
      min-height: 286px;
      .activeStep {
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5rem;
        gap: 2rem;

        & h3 {
          min-width: 200px;
          color: $beige;
        }
        .btnWhite {
          white-space: nowrap;
          margin-left: auto;
        }
      }
    }
    // Cask Details
    .caskDetailRow {
      padding-top: 6rem;
      // padding-bottom: 6rem;
      .imgWrap {
        max-height: 530px;
        // cursor: pointer;
        position: relative;
        & > div {
          height: 100%;
        }
        @include media-breakpoint-down(lg) {
          max-height: 400px;
        }
      }

      .contentWrap {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .titleWrap {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .distillery {
            border-bottom: 2px black solid;
            width: min-content;
            & p {
              font-weight: bold;
              white-space: nowrap;
              padding-bottom: 0.5rem;
            }
          }

          & h3 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            & a {
              text-decoration: none !important;
              word-break: break-all;
            }
            &:hover {
              & a {
                text-decoration: underline !important;
              }
            }
          }
          .descriptionWrap {
            .description {
              max-width: 470px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .fullDescription {
              overflow: visible;
              height: auto;
              display: block;
              // white-space: pre-wrap;
            }
            .more {
              color: $orange;
              text-decoration: underline;
              margin-top: -0.25rem;
              cursor: pointer;
            }
          }
        }
        .detailWrap {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .caskInfoWrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem 0;
            .inner {
              & p {
                font-size: 0.8rem;
              }
              .info {
                color: rgba($black, 0.75);
              }
            }
          }
        }

        .priceWrap {
          display: flex;
          flex-direction: column;
          max-width: 350px;
          gap: 0rem;
          .infoRow,
          .shippingInfo {
            margin-bottom: 1rem;
            & p,
            & a {
              color: $orange;
              font-size: 0.9rem;
              letter-spacing: 0;
            }
            & a {
              cursor: pointer;
              white-space: nowrap;
              & svg {
                transition: transform ease 0.2s;
                transform: translateX(0rem);
              }
              &:hover {
                & svg {
                  transform: translateX(0.5rem);
                  transition: transform ease 0.2s;
                }
              }
            }
          }
          .shippingInfo {
            color: $black;
            margin-bottom: 1rem;
            & p,
            & a {
              color: $black;
              & span {
                // text-decoration: underline;
                font-weight: bold;
              }
            }
          }
          .innerWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
            gap: 0.25rem;
            .price {
              font-weight: bold;
            }
          }
          .buttonRow {
            .btnCoralOutline {
              width: 100%;
            }
          }
          .btnCoralOutline {
            margin-top: 1.5rem;
          }
        }
      }

      .flavourWrap {
        background-color: transparent;
        .productDetails {
          min-height: 6rem;
          background-color: #00313c;
          color: #ece6dd;
          margin-top: 4rem;
          padding: 3rem 5rem;
          font-size: .9rem;
          @include media-breakpoint-down(md) {
            padding: 2rem 1.5rem;
          }
          .logoWrap {
            margin: auto;
          }
          .details {
            margin: auto;
            span {
              & p,
              & p a {
                color: #ece6dd;
              }
            }
          }
        }
      }
    }
    // How it works
    .howItWorksRow {
      padding-top: 5rem;
      min-height: 300px;
      .inner {
        & h3 {
          text-align: center;
          font-weight: 600;
        }
      }
    }
    // How it works cards
    .stepCardsRow {
      margin-top: -7.5rem;
      margin-bottom: 7.5rem;
      padding-bottom: 2.5rem;
      height: 100%;
      gap: 1rem;
      justify-content: space-between;
      .stepCard {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0;
        flex: 1;
        .imgWrap {
          max-height: 400px;
          flex: 1 1 60%;
          overflow: hidden;
          height: auto;
          & img {
            height: 100%;
            width: 100.5%;
            object-fit: cover;
            object-position: center;
          }
        }
        .contentWrap {
          flex: 1 1 40%;
          height: 100%;
          background-color: $black;
          padding: 4rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          & * {
            color: $beige;
          }
        }
      }
    }
    .noMargin {
      margin-bottom: -4rem !important;
    }

    // Distillery Section
    .distilleryRow {
      gap: 2rem;
      justify-content: space-between;
      min-height: 700px;
      position: relative;
      padding-top: 2rem;
      padding-bottom: 2rem;

      .imgsWrap {
        position: relative;
        padding: 0;
        flex: 1;
        .topImg {
          max-height: 73%;
          height: 100%;
          width: 100%;
          margin-top: 3rem;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
        .bottomImg {
          max-height: 73%;
          position: absolute;
          bottom: 5rem;
          right: 25%;
          height: 100%;
          width: 100%;
          z-index: 3;
          & img {
            height: 100%;
            width: 100%;
            object-position: left;
            object-fit: contain;
          }
        }
      }
      .contentWrap {
        z-index: 2;
        padding: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .inner {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .btnBlackOutline {
            width: fit-content;
          }
          .selectionGrid {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            width: 100%;
            @media (max-width: 420px) {
              flex-wrap: wrap;
              .currency {
                flex: 0 0 100% !important;
              }
            }
            & p {
              font-size: 1rem !important;
            }
            .wrap {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              flex: 1 1 48%;

              & p {
                margin: 0;
                margin-bottom: 0.5rem;
              }

              & > div {
                display: flex;
                justify-content: center;
                height: 100%;
              }
              .selectWrap {
                width: 100%;
                margin: 0;
                & select {
                  background-color: transparent;
                  padding: 0.62rem 1rem;
                  border-radius: 0;
                  // font-family: $helvetica;
                  height: initial;
                }
              }
            }
            .currency {
              flex: 3 1 100%;
            }
            // QTY PICKER
            .qtyPicker {
              display: flex;
              flex-direction: row;
              // gap: 0.5rem;
              justify-content: center;
              align-items: center;

              & p {
                margin: 0 !important;
              }

              & * {
                border: 1px solid black;
                padding: 0.5rem 1rem;
                background-color: transparent;
                font-weight: bolder;

                &:disabled {
                  opacity: 0.3;
                }

                &:focus {
                  outline: transparent;
                }
                // @include media-breakpoint-down(md) {
                //   font-size: 1rem;
                // }
              }

              & button {
                &:first-child {
                  border-right: 0px;
                }
                &:last-child {
                  border-left: 0px;
                }
              }
            }
          }
          .buttonWrap {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 1rem;
            @include media-breakpoint-down(xs) {
              gap: 0;
            }
            .btnLight {
              border-color: transparent;
              & i {
                color: $orange;
                transform: translateY(0);
                transition: transform 0.15s ease-in;
              }
              &:hover {
                background-color: transparent !important;
                border-color: transparent !important;
                & i {
                  transform: translateY(5px);
                }
              }
              &:focus {
                outline-color: transparent !important;
                border-color: transparent !important;
              }
            }
            .btnPrimary {
              text-transform: uppercase;
            }
          }
        }
      }
    }
    // Artist section
    .artistRow {
      min-height: 800px;
      justify-content: space-between;
      .contentWrap {
        display: flex;
        align-items: center;
        padding: 0;
        flex: 1;
        .inner {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .description {
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .showShadow {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 40px;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }

          .btnBlackOutline {
            width: fit-content;
          }
        }
      }
      .imgsWrap {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 0;
        flex: 1;
        .artistImg {
          max-height: 540px;
          max-width: 450px;
          height: 100%;
          width: 100%;
          margin-right: -6rem;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
        .bottleImg {
          max-height: 500px;
          max-width: 200px;
          height: 100%;
          position: absolute;
          left: 17%;
          bottom: 10%;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: contain;
          }
          @include media-breakpoint-down(lg) {
            left: 2%;
          }
        }
      }
    }
  }

  // Detail Section
  .ballotInfoCon {
    padding-bottom: 0;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ballotInfo {
      // padding: 4rem 0;
      .contentCol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .distillery {
            border-bottom: 2px black solid;
            width: min-content;
            & p {
              font-weight: bold;
              white-space: nowrap;
              padding-bottom: 0.5rem;
            }
          }
          .title {
            font-size: 2.5rem;
            & h3 {
              font-size: 2.5rem;
              & a {
                text-decoration: none !important;
                word-break: break-all;
                &:hover {
                  text-decoration: underline !important;
                }
              }
            }
            & span {
              font-size: 1.25rem;
              letter-spacing: 1px;
            }
          }
          .slider {
          }
          .sliderFallback {
            & > div > div {
              justify-content: flex-start;
              & h3 {
                margin-right: 2rem;
              }
            }
          }
          @include media-breakpoint-down(md) {
            padding-top: 2rem;
          }
          @include media-breakpoint-down(sm) {
            .title {
              font-size: 1.5rem;
              & h3 {
                font-size: 1.5rem;
              }
            }
            .distilleryName {
              margin-top: 0.25rem;
              line-height: 1;
            }
          }
          @include media-breakpoint-down(xs) {
            .title {
              word-break: break-word;
              & * {
                word-break: break-word;
              }
            }
          }
        }
      }
      .imgCol {
        position: relative;
        width: 100%;
        z-index: 1;
        min-height: 400px;
        & img {
          position: absolute;
          object-fit: cover;
          object-position: bottom;
          top: 0%;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
  }

  // -------------------------  Media Queries  -------------------------
  // Tablet
  @include media-breakpoint-down(md) {
    .caskDetailSection {
      margin-top: 0px !important;
    }
    .sectionWrap {
      padding: 0 2rem;
      //Active Step
      .activeStepRow {
        min-height: 0px;
        .activeStep {
          justify-content: center;
          align-items: center;
          margin: 0;
          & h3 {
            padding: 2rem;
          }
          .btnWhite {
            display: none;
          }
        }
      }
      // Cask Details
      .caskDetailRow {
        margin-top: 1rem;
        .imgWrap {
          margin-top: 4rem;
          & > div {
          }
        }

        .contentWrap {
          .titleWrap {
            .distillery {
              & p {
              }
            }
            & h3 {
            }
            .descriptionWrap {
              .description {
              }
              .fullDescription {
              }
              .more {
              }
            }
          }
          .detailWrap {
            .caskInfoWrap {
              .inner {
                & p {
                }
                .info {
                }
              }
            }
          }

          .priceWrap {
            .innerWrap {
              .price {
              }
            }
            .colDisc {
            }
            .btnCoralOutline {
            }
          }
        }

        .flavourWrap {
        }
      }
      // How it works
      .howItWorksRow {
        padding-top: 3rem;
        min-height: 250px;

        .inner {
          & h3 {
          }
        }
      }
      // How it works cards
      .stepCardsRow {
        padding-bottom: 0;
        .stepCard {
          .imgWrap {
            max-height: 300px;
            & img {
            }
          }
          .contentWrap {
            padding: 2rem;
            gap: 1.5rem;
            & * {
            }
          }
        }
      }
      .noMargin {
        margin-bottom: 0 !important;
      }

      // Distillery Section
      .distilleryRow {
        min-height: 500px;
        .imgsWrap {
          .topImg {
            max-height: 400px;
            max-width: 400px;
            & img {
            }
          }
          .bottomImg {
            max-height: 350px;
            max-width: 400px;
            right: unset;
            left: -30%;
            & img {
            }
          }
        }
        .contentWrap {
          .inner {
            padding: 2rem 0;
            .btnBlackOutline {
            }
          }
        }
      }
      // Artist section
      .artistRow {
        min-height: 700px;
        .contentWrap {
          .inner {
            .btnBlackOutline {
            }
          }
        }
        .imgsWrap {
          .artistImg {
            & img {
            }
          }
          .bottleImg {
            max-height: 400px;
            max-width: 200px;
            left: 2%;
            bottom: 5%;
            & img {
            }
          }
        }
      }
      // Featured section
      .featuredSection {
        padding-top: 175px;
        padding-bottom: 125px;
      }
    }
  }
  // Mobile
  @include media-breakpoint-down(sm) {
    .caskDetailSection {
    }
    .sectionWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //Active Step
      .activeStepRow {
        .activeStep {
          & h3 {
          }
          .btnWhite {
          }
        }
      }
      // Cask Details
      .caskDetailRow {
        padding-top: 0;
        gap: 1.5rem;

        .imgWrap {
          margin-top: 1.75rem;
          max-height: 250px;
          & > div {
          }
        }

        .contentWrap {
          align-items: center;
          padding: 0;
          & * {
            text-align: center;
          }
          .titleWrap {
            order: 1;
            align-items: center;
            padding: 0 1rem;
            .distillery {
              & p {
              }
            }
            & h3 {
            }
            .descriptionWrap {
              .description {
              }
              .fullDescription {
              }
              .more {
              }
            }
          }
          .detailWrap {
            width: 100%;
            order: 3;
            .caskInfoWrap {
              grid-template-columns: repeat(2, 1fr);
              .inner {
                & p {
                }
                .info {
                }
              }
            }
          }

          .priceWrap {
            order: 2;
            background-color: $orange;
            max-width: 100%;
            width: 100%;
            padding: 1.5rem 1rem;
            & * {
              color: $beige !important;
            }
            align-items: center;
            .innerWrap {
              .price {
              }
            }
            .btnCoralOutline {
              width: 100%;
              border-color: $beige;
              max-width: 300px;
            }
          }
        }

        .flavourWrap {
        }
      }
      // How it works
      .howItWorksRow {
        min-height: unset;
        padding: 2rem 0;
        .inner {
          & h3 {
          }
        }
      }
      // How it works cards
      .stepCardsRow {
        gap: 2rem;
        margin-top: 0;
        margin-bottom: 2rem;
        .stepCard {
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & * {
            }
          }
        }
      }

      // Distillery Section
      .distilleryRow {
        gap: 0;
        padding-bottom: 8rem;
        flex-direction: column;
        .imgsWrap {
          order: 2;
          .topImg {
            margin-top: 0;
            margin-left: auto;
            max-height: 270px;
            margin-right: -1.1rem;
            max-width: 80%;

            & img {
            }
          }
          .bottomImg {
            left: -1.1rem;
            max-width: 100%;
            max-height: 260px;
            bottom: -6rem;
            & img {
            }
          }
        }
        .contentWrap {
          padding: 2rem 0rem;
          order: 1;
          .inner {
            align-items: center;
            text-align: center;
            padding: 0;
            .btnBlackOutline {
            }
          }
        }
      }
      // Artist section
      .artistRow {
        gap: 2rem;
        padding: 2rem 0;
        min-height: unset;
        flex-direction: column;
        .contentWrap {
          justify-content: center;
          .inner {
            align-items: center;
            text-align: center;
            gap: 1rem;
            .description {
              -webkit-line-clamp: 4;
            }
            .btnBlackOutline {
            }
          }
        }
        .imgsWrap {
          margin-bottom: 1rem;
          .artistImg {
            max-width: 80%;
            max-height: 450px;
            margin-right: auto;
            & img {
            }
          }
          .bottleImg {
            left: unset;
            right: 5%;
            bottom: -3rem;
            & img {
            }
          }

          @media (max-width: 690px) {
            .artistImg {
              max-width: 80%;
              max-height: 350px;
              margin-right: auto;
              & img {
              }
            }
            .bottleImg {
              width: 30%;
              right: 5%;
              bottom: -2rem;
              & img {
              }
            }
          }
          @media (max-width: 450px) {
            .artistImg {
              max-width: 85%;
              max-height: 325px;
              margin-right: auto;
              & img {
              }
            }
            .bottleImg {
              width: 35%;
              height: auto;
              right: 0%;
              bottom: -2rem;
              & img {
              }
            }
          }
        }
      }
      .btnWhite {
        margin: 2rem auto;
        align-self: center;
      }
    }
  }
}

// Success Page
/*------------------------------------------------------------------------------------------------*/
.successWrap {
  @import "../../../styles/Global.scss";

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 30vh);
  text-align: center;
  margin: 0 0.5rem;
  white-space: break-spaces;

  & h4 {
    text-align: center;
  }

  & p {
    margin: 1rem 0;
  }

  .btnPrimary {
    margin-top: 0.5rem;
  }
}

// Ballot Closed
/*------------------------------------------------------------------------------------------------*/
.ballotClosed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 40vh);
  text-align: center;
  margin: 0 0.5rem;
  white-space: break-spaces;

  & h4 {
    text-align: center;
  }

  & p {
    margin: 1rem 0;
  }

  .btnPrimary {
    margin-top: 0.5rem;
  }
}
