@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.container-fluid {
  padding: 0;
  overflow-x: hidden;
  @include media-breakpoint-down(sm) {
    overflow-y: hidden;
  }
}
.pressItem {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  padding: 10px;
  text-align: center;
  & img {
    width: 100%;
    max-width: 220px;
    filter: grayscale(1);
    opacity: 0.8;
  }
  @include media-breakpoint-down(lg) {
    flex-basis: 50%;
    max-width: 150px;
    justify-self: center;
  }
  @include media-breakpoint-down(md) {
    min-height: 100px;
    & img {
      width: 100%;
    }
  }
}
