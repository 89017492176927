.startCollection {
  @import "../../../styles/Global.scss";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins/_breakpoints";

  position: relative;
  min-height: 600px;
  max-height: 90vh;
  background-color: $beige;
  overflow: hidden;
  margin-top: -90px;
  padding: 0 1rem;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../../../assets/images/share-dots-light.png") no-repeat;
    height: 400px;
    width: 400px;
    background-size: contain;
    opacity: 0.9;
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;

    & span {
      margin-top: 4rem;
      width: 100px;
      height: 6px;
      background-color: $black;
    }

    & > * {
      margin-bottom: 3rem;
      color: $black;
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }
    }

    & p,
    & a {
      max-width: 530px;
      color: $black;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 0;
    &::after {
      height: 300px;
      width: 300px;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    min-height: unset;
    .inner {
      padding: 2.5rem;
    }
  }
}
