@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.contentSection {
  @import "../../../styles/Global.scss";
  padding-bottom: 0;
  padding: 0 2rem;

  .featuredSection {
    padding-top: 250px;
    padding-bottom: 200px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: visible;
    .contentWrap {
      padding: 4rem 1rem;
      min-width: 30%;
      display: flex;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        & * {
          color: $beige;
        }
        .howItWorks {
          width: min-content;
          border-bottom: 2px solid $beige;
          & p {
            font-family: $domaineHeading;
            color: $beige;
            padding-bottom: 0.5rem;
            white-space: nowrap;
          }
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        .innerContent {
          max-width: 280px;
          & * {
            margin-bottom: 1rem;
          }
          & h3 {
            & span {
              font-family: $domaineHeading;
            }
          }
          & a {
            color: $orange;
          }
          & button {
            margin-top: 2rem;
          }
        }
      }
    }
    .cardsWrap {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      overflow-x: visible;
      width: 100%;
      max-width: 1000px;
    }
  }

  @include media-breakpoint-down(md) {
    .featuredSection {
      // padding-top: 200px;
      // padding-top: 1500px;
      .cardsWrap {
        max-width: 700px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .featuredSection {
      padding-top: 1rem;
      padding-bottom: 3rem;
      flex-direction: column;
      .contentWrap {
        padding: 2rem 0;
        width: 100%;

        .content {
          width: 100%;
          & * {
          }
          .innerContent {
            width: 100%;
            max-width: unset;
            text-align: center;
            & * {
            }
            & h3 {
              & br {
                display: none;
              }
            }
            & p {
              display: none;
            }
            & a {
            }
            & button {
              margin: 0;
            }
          }
        }
      }
      .cardsWrap {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .featuredSection {
      padding-top: 1rem;
      padding-bottom: 3rem;
      flex-direction: column;
      .contentWrap {
        padding: 2rem 0;
        width: 100%;

        .content {
          width: 100%;
          & * {
          }
          .innerContent {
            width: 100%;
            max-width: unset;
            text-align: center;
            & * {
            }
            & h3 {
              & br {
                display: none;
              }
            }
            & p {
              display: none;
            }
            & a {
            }
            & button {
              margin: 0;
            }
          }
        }
      }
      .cardsWrap {
        width: 100%;
      }
    }
  }
}
