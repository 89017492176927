@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../styles/Global.scss";

.spaceTop {
  margin-top: 100px;
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
  }
}
.link {
  display: flex;
  justify-content: space-between;
  min-height: 20px;
  i {
    font-size: 30px;
    color: #e76852;
  }
  & a {
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: #000;
    height: 100%;
    font-weight: 500;
    text-transform: uppercase;
    &:focus {
      outline: 0;
    }
    &:hover {
      border-bottom: 2px solid #000;
    }
    i {
      font-size: 20px;
      margin-left: 15px;
      vertical-align: middle;
      color: #e76852;
    }
  }
}
.hero {
  margin-top: 100px;
  p {
    @include media-breakpoint-down(md) {
      font-size: 0.9rem;
    }
  }
  & .heroM {
    margin: 0;
    width: 100%;
  }
  .underline {
    text-decoration: underline;
  }
  .paragraphWrapper {
    display: flex;
    margin-top: 30px;
    align-items: center;
    & .link {
      width: 80%;
      justify-content: center;
    }
    & p {
      max-width: 260px;
    }
  }
  .heroImg {
    padding: 0;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .paragraphWrapper {
      display: none;
      & p {
        margin: 0;
      }
      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 20px;
      }
    }
  }
  .pressGrid {
    display: grid;
    grid-auto-flow: column;
    min-height: 200px;
    background-color: #ece6dd;
    width: 100%;
    @include media-breakpoint-down(md) {
      grid-auto-flow: unset;
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
    .heroCol {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      & br {
        display: none;
      }
      .paragraphWrapper {
        display: none;
      }
    }
  }
}
.articles {
  margin-top: 100px;
  @include media-breakpoint-down(md) {
    margin: 50px 0;
  }
  & .sort {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    @include media-breakpoint-down(md) {
      align-items: unset;
    }
  }
  .cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    min-height: 500px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
}
.social {
  margin-top: 40px;
  height: 100%;
  width: 100%;
  & .socialWrapper {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 20px;
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    margin: 0 auto;
    & .socialWrapper {
      display: block;
    }
  }
}
.caskWrapper {
  margin: 150px 0;
  @include media-breakpoint-down(md) {
    margin: 100px 0 0 0;
  }
  .caskHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
    &::before {
      content: "";
      position: absolute;
      top: -80px;
      left: 50%;
      width: 100px;
      border-top: 6px solid #000;
      transform: translateX(-55%);
      @include media-breakpoint-down(md) {
        top: -45px;
        width: 80px;
        border-top: 4px solid #000;
      }
    }
    & h3 {
      max-width: 700px;
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      margin: 20px 0;
      padding: 0 50px;
    }
  }
  .caskButton {
    text-align: center;
    margin-top: 60px;
    @include media-breakpoint-down(sm) {
      margin: 1rem 0;
    }
  }
}
