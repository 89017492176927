@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.articleWrapper {
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
  width: 100%;
  padding-left: 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 100px;
  & h4 {
    font-size: 2rem;
  }
  & p {
    max-width: 90%;
  }
  & button {
    text-transform: uppercase;
    background-color: transparent;
    max-width: 90%;
    align-self: center;
    display: flex;
    justify-content: space-between;
    padding: 0;
    border: 2px solid #000;
    font-weight: 500;
    color: black;
    &:hover {
      background-color: transparent;

      & a {
        text-decoration-thickness: 2px;
      }
    }

    & a {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 15px 0px;
      color: #000;
      transform: scale(1, 1.1);

      &:hover {
        color: #000;
      }

      & img {
        height: 100%;
      }
    }
  }
}
