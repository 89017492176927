/*------------------------------------------------------------------------------------------------*/
/* currency dropdown */
// #currency-dropdown
// .currency-dropdown-toggle {
//   font-family: inherit;
//   font-weight: 300;
// }

.currencyDropdown {
  .currencyDropdownToggle {
    font-family: inherit;
    font-weight: 300;
    font-size: 0.75rem;
  }
  & > div {
    // display: block;
    & a {
      padding: 0.25rem 1.5rem !important;
    }
  }
  .currencyDropdownMenu {
  }

  .currencySymbol {
  }
  .currencyExtra {
  }

  & button {
    font-family: "Helvetica";
  }
}
