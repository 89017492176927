.formWrap {
  @import "../../styles/Global.scss";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 2;
  & h5 {
    margin-bottom: 1rem !important;
  }
  .textarea {
    width: 100%;
    padding: 0 0.25rem;
    & textarea {
      max-height: 100px;
    }
  }
  .errorMessage {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0.5rem;
    & p,
    & svg {
      color: $orange;
      font-weight: 400;
    }
    & p {
      margin-left: 0.5rem;
    }
  }
  .btnWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .btnLight {
      border-color: transparent;
      &:focus-within {
        border-color: transparent !important;
      }
    }
  }
}
.formWrapAccount {
  position: unset !important;
}
