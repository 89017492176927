@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../styles/Global.scss";

.bottleImage {
  width: 100%;
  height: auto;
  position: relative;

  .overlay {
    position: absolute;
    content: "";
    top: -5%;
    left: 50%;
    transform: translateX(-50%);

    height: calc(100% - 10%);
    width: calc(100% - 25%);
    border-radius: 50%;
    background-color: $beige;
    z-index: 0;
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    z-index: 1;
  }

  .imgLoading {
    filter: blur(10px);
    clip-path: inset(0);
    padding: 1rem;
  }
  .imgLoaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }
}
