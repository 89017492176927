@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.embeddedCheckoutPageWrap {
  @import "../styles/Global.scss";

  max-width: 100vw;

  .spacer1 {
    height: 1rem;
    @include media-breakpoint-down(xs) {
      height: 1rem;
    }
  }

  .blackBG {
    background-color: $black;
  }

  .collectiveBG {
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: cover;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      // background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 35%
      );
    }

    @include media-breakpoint-down(md) {
      &::before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 10%,
          rgba(0, 0, 0, 0) 75%
        );
      }
    }

    @include media-breakpoint-down(sm) {
      &::before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 30%,
          rgba(0, 0, 0, 0) 75%
        );
      }
    }
  }

  .howItWorks {
    width: min-content;
    border-bottom: 2px solid $beige;
    & p {
      font-family: $domaineHeading;
      color: $beige;
      padding-bottom: 0.5rem;
      white-space: nowrap;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .contentSection {
    padding-bottom: 0;
    padding: 0 2rem;
    z-index: 2;

    .sectionNav {
      display: flex;
      flex-direction: row;
      min-height: 150px;
      margin-top: -50px;
      margin-bottom: 100px;

      .colItem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(1) {
          background-color: black;
        }
        &:nth-child(2) {
          background-color: #00313c;
        }
        &:nth-child(3) {
          background-color: $orange;
        }

        .active {
          color: $beige;
          border-color: $beige;
        }
      }
    }
  }

  .collectiveSection {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 5rem;

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .innerContent {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 500px;
        & h3,
        & p {
          color: $beige;
          max-width: 500px;
        }
        & h3 {
          & span {
            font-family: $domaineHeading;
            color: $orange;
          }
        }
        & button {
          width: fit-content;
        }
      }

      .benefits {
        .benefitsWrap {
          margin: 3rem 0;
          display: flex;
          // grid-template-columns: repeat(3, 1fr);
          flex-wrap: wrap;
          gap: 2rem;

          & p {
            position: relative;
            max-width: 160px;
            color: $orange;

            &::after {
              position: absolute;
              content: "";
              top: -0.5rem;
              left: 0;
              width: 12px;
              height: 2px;
              background-color: $beige;
            }
          }
        }
      }
      .imgWrap {
        & img {
          max-width: 750px;
        }
      }
      .joinWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 3rem;
        padding-right: 2rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
      }
    }

    .leftImg {
      position: absolute;
      bottom: 0%;
      left: 10%;
      width: 100%;
      height: 100%;
      max-height: 350px;
      max-width: 45%;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center right;
      }
    }
  }

  .benefitsSection {
    min-height: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2rem;
    & * {
      color: $beige;
    }
    .innerContent {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      justify-content: center;
      & h3 {
        text-align: center;
        color: $beige;
        & span {
          font-family: $domaineHeading;
          color: $orange;
        }
      }
      .benefits {
        width: 100%;
        display: grid;
        gap: 3rem 2rem;
        grid-template-columns: repeat(2, 1fr);
        & p {
          color: $beige;
          position: relative;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            top: -0.75rem;
            left: 50%;
            transform: translateX(-50%);
            width: 3rem;
            height: 2px;
            background-color: $orange;
          }
        }
      }
    }
  }

  .reviewSection {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & * {
      color: $beige;
    }
    .innerContent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 3rem;
      justify-content: center;
      .reviewCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 30%;
        gap: 1rem;

        .stars {
          display: flex;
          gap: 0.25rem;
          & * {
            color: $orange;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .collectiveBanner {
      justify-content: center;
      padding: 4rem 0;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
        gap: 2rem;
        .howItWorks {
          display: block;
        }
        .innerContent {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          justify-content: center;
          align-items: center;
          text-align: center;
          & h3 {
            color: $beige;
            & span {
              color: $orange;
              font-family: $domaineHeading;
            }
          }
          & button {
            width: fit-content;
          }
        }
      }
    }

    .collectiveContentBG {
      background-color: black;
      .collectiveContent {
        justify-content: center;
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
          padding: 0;
          width: 100%;
          & p {
            color: $beige;
            max-width: 520px;
            text-align: center;
          }
          .benefits {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 540px;
            width: 100%;
            .howItWorks {
              display: block;
            }
            .benefitsWrap {
              margin: 2rem 0;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 3rem;
              justify-content: center;

              & p {
                position: relative;
                text-align: left;
                max-width: 160px;
                color: $orange;
                text-align: center;

                &::after {
                  position: absolute;
                  content: "";
                  top: -1rem;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 3rem;
                  height: 2px;
                  background-color: $beige;
                }
              }
            }
          }
          .innerContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            & * {
              color: $beige;
            }
            & > p {
              max-width: 600px;
              text-align: center;
              padding-bottom: 2rem;
            }
          }
          .imgWrap {
            margin-top: 2rem;
            height: 300px;
            width: 100%;
            z-index: 2;
            & img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }

    .benefitsSection {
      min-height: 300px;
    }
  }

  @include media-breakpoint-down(sm) {
    .collectiveBanner {
      padding: 3rem 0;
    }

    .collectiveContentBG {
      .collectiveContent {
        .content {
          gap: 1rem;
          .benefits {
            .benefitsWrap {
              gap: 2.5rem 3rem;
            }
          }
          .innerContent {
            & p {
              padding-bottom: 1rem;
            }
          }
        }
      }
    }

    .benefitsSection {
      min-height: 270px;
      width: auto;
      padding: 2rem 0;
    }

    .reviewSection {
      width: auto;
      padding: 1rem;
      .innerContent {
        flex-wrap: wrap;
        .reviewCard {
          max-width: 45%;
          @include media-breakpoint-down(xs) {
            max-width: 90%;
          }
        }
      }
    }
  }
}
