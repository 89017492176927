@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.test {
  min-height: 3000px;
}

.heroImage {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 600px;
  max-width: 100vw;
  z-index: 21;
  @include media-breakpoint-down(md) {
    min-height: 80vh;
  }
}
.footNoteWrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  .footNoteHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
    }
    &::before {
      content: "";
      position: absolute;
      top: -80px;
      left: 50%;
      width: 100px;
      border-top: 6px solid #000;
      transform: translateX(-55%);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    & h3 {
      max-width: 600px;
      text-align: center;
      position: relative;
      @include media-breakpoint-down(md) {
        margin-top: 10px;
        max-width: 60%;
      }
    }
    .allCask {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      @include media-breakpoint-down(sm) {
        top: unset;
        bottom: -30px;
        right: 50%;
        transform: translateX(50%);
      }
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 1px;
        font-family: "Bebas Neue";
        text-decoration: none;

        & i {
          vertical-align: middle;
          color: #e76852;
          padding-left: 15px;
          padding-bottom: 5px;
          text-decoration: none;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 20px 0;
    }
  }
}
.flavour-map-graphic-component {
  position: relative;
  .center-marker {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #aaa;
    border-radius: 100%;
    border: 1px solid red;
  }
  .ring-marker {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .blob {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #aaa;
    border-radius: 100%;
    &.fruitiness {
      background-color: #e76852;
    }
    &.sweetness {
      background-color: #ece6dd;
    }
    &.spice {
      background-color: #896e4b;
    }
    &.smokiness {
      background-color: #aaaaaa;
    }
    &.complexity {
      background-color: #003f5a;
    }
  }
}
