.basket-item,
.distillery-item {
  background-color: rgb(244, 240, 236);
  padding: 15px;
  // margin-bottom: 0.5rem;
  position: relative;

  .early-bird-banner {
    display: none;
  }
}
.basket-item {
  z-index: 1;
  .product-image {
    & img {
      // max-height: 200px;
      height: 100%;
      width: 100%;
      object-fit: contain;
      @include media-breakpoint-down(sm) {
        max-height: 200px;
      }
    }
  }
}

.basket-item-child {
  padding-top: 0;
  .product-image {
    padding-left: 0;
  }
  .cask-bottom-area {
    width: 100% !important;
  }
}

.collective-upsell-row {
  margin-top: 0.3rem;
  margin-bottom: 0.675rem;
  background-color: rgb(244, 240, 236);
  padding: 0.5rem 1rem;
  .member-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    & p {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
  .nonMember-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    & p {
      margin: 0;
      margin-left: 0.5rem;
    }
  }
}

.gifting-lineItem-row {
  background-color: #f4f0ec;
  padding: 1rem;
  .first-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    & input {
      flex: 1.5;

      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #f7cac2;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(231, 104, 82, 0.25);
      }

      &:first-child {
        margin-right: 1rem;
      }
    }
    .gift-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      padding-left: 0.5rem;
      & label {
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
  }
  .second-col {
    width: 100%;
    & textarea {
      width: 100%;
    }
  }
  .button-col {
    @import "../styles/Global.scss";
    margin: 0%;
    display: flex;
    & button {
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .first-col {
      .gift-card {
        min-width: 100%;
        justify-content: flex-start;
        margin-top: 1rem;
      }
    }
  }
}

.gifting-lineItem-row-active {
  animation: fadeIn ease 0.5s;
}

/* this is a copy of Bootstrap v4.5.0 .form-control */
.card-element-container {
  .StripeElement {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.modal-header {
  font-family: "Bebas Neue";
}

.payment-request {
  &::before {
    content: "";
    display: block;
    margin: 35px auto 0 auto;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid lightgray;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-155%);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    transition: transform ease-in-out 0.1s;
  }
}
