@font-face {
  font-family: "Bebas Neue Book";
  src: url("BebasNeue-Book.woff2") format("woff2"),
    url("BebasNeue-Book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Regular.woff2") format("woff2"),
    url("BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Light.woff2") format("woff2"),
    url("BebasNeue-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Thin.woff2") format("woff2"),
    url("BebasNeue-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Bold.woff2") format("woff2"),
    url("BebasNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Thin.woff2") format("woff2"),
    url("BebasNeue-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Regular.woff2") format("woff2"),
    url("BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue Book";
  src: url("BebasNeue-Book.woff2") format("woff2"),
    url("BebasNeue-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Bold.woff2") format("woff2"),
    url("BebasNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("BebasNeue-Light.woff2") format("woff2"),
    url("BebasNeue-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stencilia-A Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Stencilia-A Regular"), url("Stencilia-A.woff") format("woff");
}

@font-face {
  font-family: "Exmouth";
  font-style: normal;
  font-weight: 100;
  src: local("Exmouth"), url("Exmouth.woff") format("woff");
}

@font-face {
  font-family: "GT-Pressura-Extended-Text";
  src: url("GT-Pressura-Extended-Text.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT-Pressura-Extended-Medium";
  src: url("GT-Pressura-Extended-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DomaineText-Regular";
  src: url("DomaineText-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
