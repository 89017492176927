.promoCodeArea {
  padding: 1rem 0;
  .innerWrap {
    padding: 0;
    .promoHeader {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .rotate {
      transition: transform 0.2s ease-in;
      transform: rotate(180deg);
    }

    .visible {
      margin-top: 1rem;
      .inputWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        margin-bottom: 0.5rem;
        .clearCode {
          min-height: 48px;
          max-width: 40px;
          width: 100%;
          padding: 0rem;
          text-align: center;
        }
      }
      & input {
        border-radius: 0;
        width: calc(100% - 40px);
      }
      & [class*="invalid-feedback"] {
        order: 3;
        width: 100%;
        flex: 100%;
      }
      & button {
      }
    }

    .success {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;

      & svg * {
        fill: green;
      }
    }
  }
}

.undiscountedLineItems {
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: rgba(#e76852, 0.25);
}
