@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.sell-your-cask {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #ece6dd;
  position: relative;
  margin-top: -110px;
  margin-bottom: -80px;
  background-blend-mode: overlay;
  background-color: rgba($color: #000000, $alpha: 0.2);
  background-image: url("../assets/images/sell-your-cask-casks.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 650px;
  max-width: 100vw;
  z-index: 21;
  @include media-breakpoint-down(md) {
    align-items: center;
  }

  .row {
    width: 100%;
    margin-bottom: 5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .show {
    width: 100%;
    margin: 0 auto;
    .headerWrap {
      .header {
        font-size: 7rem;
        color: #003f5a;
        @include media-breakpoint-down(md) {
          font-size: 4rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2.4rem;
        }
      }
      .subheader {
        margin-top: 1rem;
        line-height: 1.2;
        & br {
          display: none;
          @include media-breakpoint-up(xl) {
            display: block;
          }
        }
        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.hide {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.sell-your-cask-wrapper {
  padding-top: 3rem;
  padding-bottom: 2rem !important;
  background: #f5f2ee;

  .imgWrap {
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding-bottom: 2rem;
    }
    & img {
      max-width: 150px;
      width: 100%;
    }
  }

  .textWrap {
    margin-bottom: 2rem;
    & p {
      font-size: 1.8rem;
      text-align: justify;
      @include media-breakpoint-down(md) {
        font-size: 1.3rem;
        text-align: justify;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }
  }

  .bulletWrap {
    display: flex;
    flex-wrap: wrap;
    .inner {
      display: flex;
      align-items: baseline;
      flex-direction: row;
      flex: 40%;
      min-width: 280px;
      margin: 1rem;
      & span {
        height: 15px;
        max-width: 15px;
        background-color: #000;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1rem;
        flex: 1;
        @include media-breakpoint-down(sm) {
          height: 10px;
          max-width: 10px;
        }
      }
      & p {
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
        flex: 3;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }
  }
  .separator-2 {
    padding-top: 2rem;
  }
  @include media-breakpoint-down(sm) {
    .calculate-gross {
      .form-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .right {
      .rdtPicker {
        right: 0 !important;
      }
    }
  }
}

.not-a-club-note {
  background-color: #003f5a;
  background-image: url(../assets/images/woodgrain-lines.svg);
  background-size: cover;
  background-position: 50% 80%;

  .row {
    min-height: 400px;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 150px;
      width: 100%;
    }

    & h2 {
      width: fit-content;
      margin: 0;
      padding: 0;
      @include media-breakpoint-down(sm) {
        & br {
          display: none;
        }
      }
      & span {
        text-decoration: underline;
      }
    }
    @include media-breakpoint-down(sm) {
      & [class*="col-"] {
        text-align: center;
      }
    }
  }
}

.steps-container {
  .row .row {
    background-color: rgba($color: #003f5a, $alpha: 1);
    background-image: url(../assets/images/woodgrain-lines.svg);
    background-size: contain;
    background-position: right;
  }
  .separator-2 {
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
  .controls-image {
    min-height: 500px;
    padding: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/Rye-Cask.jpg");
      background-position: center bottom;
      background-size: cover;
      background-color: rgba($color: #000000, $alpha: 0.4);
      background-blend-mode: multiply;
    }
    .text-wrap {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      height: 100%;
      width: 100%;
      @include media-breakpoint-down(sm) {
        align-items: center;
      }
      & h3 {
        max-width: 500px;
        padding: 2.4rem 2rem 0 0;
        text-align: right;
        color: #ece6dd;
        line-height: 1;
        margin-left: auto;
        & span {
          text-decoration: underline;
          text-decoration-color: #e76852;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
          min-height: fit-content;
          margin: 0 auto;
          padding: 2rem;
        }
      }
    }
  }
  .slide-area {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .controls-area {
      display: flex;
      margin-top: 1rem;
      .spacer {
        width: 2rem;
      }
      & i {
        color: #ece6dd;
      }
    }
    .slide-wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      .steps {
        max-width: 530px;
        & h2 {
          color: #ece6dd;
        }
        & p {
          height: 100%;
          font-size: 1.3rem;
          padding: 1px;
          text-align: justify;
          font-weight: 400;
        }
      }
    }
  }
}

.single-cask-whisky {
  background-image: url("../assets/images/sell-your-cask-man.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 550px;
  height: 40vh;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    background-position: 30% center;
  }

  .woodlines {
    position: absolute;
    top: -10%;
    right: 0;
    height: 130%;
    width: 55%;
    background-image: url("../assets/images/woodgrain-lines.svg");
    background-size: cover;
    background-position: 10% 50%;
  }

  .row {
    justify-content: flex-end;
  }

  .democratising {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    min-height: 500px;
    height: 25vh;
    & h2 {
      margin: 0;
      padding: 0;
      width: min-content;
      color: #94ccce;
      font-size: 5.5rem;
      padding-bottom: 1rem;
      position: relative;
    }

    & img {
      position: absolute;
      bottom: -20px;
      right: 20px;
      max-width: 150px;
      @include media-breakpoint-down(md) {
        bottom: -50px;
        right: -10px;
      }
      @include media-breakpoint-down(sm) {
        bottom: -50px;
        right: 0px;
        max-width: 100px;
      }
    }
    @include media-breakpoint-down(md) {
      justify-content: center;
      align-items: center;
      & h2 {
        font-size: 4rem;
      }
    }
    @include media-breakpoint-down(sm) {
      justify-content: center;
      align-items: flex-start;
      padding-top: 50px;
      & h2 {
        font-size: 2rem;
      }
    }
  }
}

.progressOne {
  .CircularProgressbar-path {
    stroke: #003f5a !important;
  }
  .CircularProgressbar-trail {
    stroke: #003f5a82 !important;
  }
  .CircularProgressbar-text {
    fill: #003f5a !important;
  }
}

.progressTwo {
  .CircularProgressbar-path {
    stroke: #e76852 !important;
  }
  .CircularProgressbar-trail {
    stroke: #e768527d !important;
  }
  .CircularProgressbar-text {
    fill: #e76852 !important;
  }
}
