.account-top-row {
  position: relative;

  .barrelbankWrap {
    position: absolute;
    top: -1rem;
    right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    max-width: 150px;
    @include media-breakpoint-down(xs) {
      justify-content: flex-start;
      position: unset;
      margin-top: 1rem;
      order: 2;
    }
    & img {
      max-width: 45px;
      opacity: 0.5;
    }
    .cta {
      & a {
        font-size: 0.9rem;
        font-weight: 400;
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1.5px;
        white-space: nowrap;
      }
    }
  }
}
.account-side-menu {
  position: sticky;
  top: 130px;

  & > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .scroll-button {
    display: none;
  }
  & h4 {
    position: relative;
    .show-beacon {
      position: absolute;
      top: 0;
      right: -2rem;

      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #e76852;
      animation: fadeIn ease-in-out 1s infinite;
      &:after {
        position: absolute;
        content: "";
        top: -5px;
        left: -5px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #e76852;
        opacity: 0.4;
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0.2;
      scale: 1;
    }
    25% {
      opacity: 0.5;
      scale: 1.05;
    }
    50% {
      opacity: 0.75;
      scale: 1.1;
    }
    75% {
      opacity: 0.5;
      scale: 1.15;
    }
    100% {
      opacity: 0.2;
      scale: 1.2;
    }
  }
}

.account-collection-wrapper {
  .bottle-img-wrap {
    position: relative;
    height: 140px;
    & > div {
      max-height: 140px;
      height: 100%;
      & > div {
        background-color: white;
      }
    }
  }
  .number-of-shares-blob {
    width: auto;
    height: 25px !important;
    border-radius: 25px;
    text-align: center;
    position: absolute;
    padding: 0 0.5rem;
    top: -5px;
    right: 10px;
    background: #e76852;
    color: white;
  }
}

.account-orders-wrapper {
  .bottle-img-wrap {
    position: relative;
    height: 140px;
    & > div {
      max-height: 140px;
      height: 100%;
      & > div {
        background-color: white;
      }
    }
  }
  .number-of-shares-blob {
    width: auto;
    height: 25px !important;
    border-radius: 25px;
    text-align: center;
    position: absolute;
    padding: 0 0.5rem;
    top: -5px;
    right: 20px;
    background: #e76852;
    color: white;
  }
}

@include media-breakpoint-down(md) {
  .account-side-menu {
    position: relative;
    top: 0;

    & > div {
      padding-top: unset;
      padding-bottom: unset;
    }
  }
}

@include media-breakpoint-down(sm) {
  .account-side-menu {
    & > div {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.underline-links {
  a {
    text-decoration: underline;
  }
}

// Navigation on an account page, tablet and smaller screen sizes.

@include media-breakpoint-down(md) {
  .account-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 0px;
      padding: 0;
    }
    & h3 {
      flex: 30%;
      max-width: 30%;
      padding-bottom: 0 !important;
      margin: 0 !important;

      @include media-breakpoint-down(md) {
        flex: 100%;
        max-width: 100%;
      }
    }

    .account-side-menu {
      flex: 70%;
      max-width: 70%;
      height: 100%;
      align-items: flex-end !important;
      padding-bottom: 10px;
      position: relative;
      @include media-breakpoint-down(md) {
        flex: 100%;
        max-width: 100%;
        margin: 0 auto;
      }
      .need-help {
        display: none;
      }

      .account-links {
        width: 100% !important;
        scroll-behavior: smooth;
        &[class*="col-"] {
          flex: 100%;
          max-width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 0;
          @include media-breakpoint-down(md) {
            margin-top: 10px;
            overflow-x: scroll;
            overflow-y: hidden;
            &::-webkit-scrollbar {
              display: none;
            }
          }

          & a {
            width: 100px;
            border-radius: 50px;
            border: #000 solid 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            margin: 0 5px;
            text-decoration: none;
            width: fit-content;

            &.active {
              background-color: #000;
              & h6 {
                color: #fff !important;
              }
            }

            & h6 {
              margin: 0;
              padding: 10px 20px;
              font-size: 0.9rem;
              font-weight: 500;
              white-space: nowrap;
              @include media-breakpoint-down(sm) {
                font-size: 0.75rem;
              }
            }
            @include media-breakpoint-down(md) {
              min-height: 25px;
            }
          }

          & a > .row > div {
            display: none;
          }
        }
      }
      .scroll-button {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        .scroll-button {
          position: absolute;
          top: 3.5rem;
          min-width: 40px;
          background-color: transparent;
          color: black;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 999;
          padding: 0;
          margin: 0;
          font-size: 1.6rem;
          z-index: 2;
          &:hover,
          &:focus {
            outline: none;
          }
          & * {
            padding: 0.2rem 0;
          }
        }
        .scroll-left {
          left: -1rem;
          background-color: #fff;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0rem;
            height: 3rem;
            width: 3rem;
            z-index: -1;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0) 100%
            );
          }
        }
        .scroll-right {
          right: -1rem;
          background-color: #fff;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -2rem;
            height: 3rem;
            width: 3rem;
            z-index: -1;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }
        }
      }
    }
  }
  .separator-2 {
    padding-top: 2rem !important;
  }
}

@include media-breakpoint-down(sm) {
  .separator-2 {
    padding-top: 1rem !important;
  }
}

.account-wrapper-content {
  position: relative;
  margin: 2.5rem 0;
  @include media-breakpoint-down(sm) {
    margin-top: 2rem;
    order: 3;
    padding: 0;
  }
  .signature-stamp-wrapper {
    display: none;
  }
  .product-image {
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.need-help-mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: flex;
    position: relative;
    padding-top: 35px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 5px;
      border-top: solid #000;
      margin-top: 10px;
    }
    // .need-help {
    //   padding: 0;
    // }
  }
}

.exclusive-product-card {
  @include media-breakpoint-down(sm) {
    padding-right: 0;
  }
  &-wrapper {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 700px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      padding-top: 25px;
      padding-right: 0;
    }
    & > .col-lg-6:first-child {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      height: auto;
      @include media-breakpoint-down(md) {
        & > div > img {
          object-fit: cover;
          width: 85% !important;
        }
      }
    }
    & > .col-lg-6:last-child {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0.5rem;
      height: auto;
      .cask-title {
        min-height: 55px;
      }
      .cask-info-text {
        font-size: 0.8rem;
      }
      .cask-price {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        padding: 0;
        .price-wrap {
          flex: 1;
        }
        .button-wrap {
          flex: 1;
        }
      }
      @include media-breakpoint-down(md) {
        margin-top: 20px;
        & > .row {
          padding: 0 15px;
        }
      }
    }
  }
  .signature-stamp-wrapper {
    display: none;
  }
  .early-bird-banner {
    display: none;
  }
}
