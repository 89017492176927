.resetPassword {
  @import "../styles/Global.scss";

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 0rem 0.5rem;

  & svg {
    color: $orange;
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.15rem;
  }

  & span {
    flex: 1;
    color: $orange;
    font-weight: bold;
    max-width: 560px;
  }
}
