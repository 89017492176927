body {
  font-family: GT-Pressura-Extended-Text, Arial, sans-serif;
  // font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: black;
}

h1 {
  font-size: 3.7rem;
  text-transform: uppercase;
}

h2 {
  font-size: 3.2rem;
  text-transform: uppercase;
}

h3 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

h4 {
  font-size: 2.2rem;
  text-transform: uppercase;
}

h5 {
  font-size: 1.25rem;
  text-transform: uppercase;
}

h6 {
  font-size: 0.9rem;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: bold;
  // font-family: "Bebas Neue";
  font-family: GT-Pressura-Extended-Medium, Arial, sans-serif;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2.1rem;
  }
  h4 {
    font-size: 1.8rem;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.85rem;
  }
}

p {
  &.small-title {
    margin: 0;
    letter-spacing: 0.36px;
    font-weight: lighter;
    font-size: 0.75rem;
  }
}
