.checkoutIcon {
  @import "../../../styles/Global.scss";

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.8rem 0;
  padding-left: 1.5rem;
  gap: 0.25rem;
  font-size: 0.7rem;

  & span {
    background-color: white;
    border-radius: 50%;
    padding: 0.1rem 0;
    height: 20px;
    width: 20px;
    text-align: center;
  }
  & svg {
    min-height: 16px !important;
    max-height: 24px !important;
    min-width: 16px !important;
    max-width: 24px !important;
    width: unset;
    height: unset;

    & * {
      opacity: 1 !important;
      stroke: rgba($black, 0.2) !important;
      fill: transparent !important;
      transition: all ease-in 0.15s;
    }
  }

  &:hover {
    & * {
      fill: transparent !important;
      stroke: $orange !important;
    }
  }
}

.mobile {
  padding: 0.1rem;
}
