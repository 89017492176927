@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.outerLoginWrap {
  @import "../../../styles/Global.scss";
  min-height: calc(100vh);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;

  .innerLoginWrap {
    min-height: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;

    .logoWrapper {
      margin: 3rem auto;
      .logo {
        max-width: 300px;
        & svg {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: contain;
          & * {
            fill: $black !important;
          }
        }
      }
    }

    .loginWrapper {
      padding-top: 2rem;
      display: flex;
      align-items: stretch;
      width: 100%;
      min-height: 580px;
      .joinWrap {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        .header {
          flex: 1;
          display: flex;
          & h3 {
            font-size: 2.8rem;
          }
        }
        .chosenSub {
          position: relative;
          flex: 3;
          padding-top: 0rem;
          display: flex;
          flex-direction: column;
          z-index: 2;
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }
      .formWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1rem;
        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2rem;
          flex-wrap: wrap;
          & h5 {
            font-weight: bolder;
          }
          & a {
            text-decoration: underline;
            text-underline-offset: 0.35rem;
            font-weight: 300;
            white-space: nowrap;
          }
        }
        .formRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          & label {
            font-size: 0.9rem;
          }
          & input,
          & select {
            border-radius: 0;
            background-color: white !important;
            font-size: 1rem;
          }
          .formGroup {
            width: 100%;
            margin-bottom: 2rem;
          }
        }
        .resetPassword {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          margin-bottom: 2rem;

          & svg {
            color: $orange;
            font-size: 1.2rem;
            margin-right: 0.5rem;
            margin-top: 0.15rem;
          }

          & span {
            flex: 1;
            color: $orange;
            font-weight: bold;
            max-width: 560px;
          }
        }
      }
    }
  }

  /*------------------------------------------------------------------------------------------------*/

  .chosenSub {
    display: none;
  }

  // Media Queries
  /*------------------------------------------------------------------------------------------------*/

  @include media-breakpoint-down(md) {
    .innerLoginWrap {
      margin-bottom: 3rem;
      .logoWrapper {
        margin: 3rem auto;
        .logo {
          max-width: 200px;
        }
      }
      .loginWrapper {
        min-height: unset;
        .joinWrap {
          .header {
            & h3 {
              font-size: 2rem;
            }
          }
          .chosenSub {
            display: none;
          }
          .noPad {
            padding: 0;
          }
        }
        .formWrap {
          margin-top: 2rem;
          .header {
            flex-direction: column;
            justify-content: unset;
            align-items: flex-start;
            & h5 {
              margin-bottom: 1rem;
            }
          }
          .formRow {
            .formGroup {
              .underlineLinks {
                &:last-child {
                  margin-top: 1rem;
                }
              }
            }
            .selectInput {
              .formGroup,
              & .formRow {
                & select {
                  width: 100%;
                }
              }
            }
          }
          .freeAccount {
            flex-direction: column;
            align-items: flex-start;
            justify-content: unset;
            margin-top: 2rem;

            & p {
              margin-left: 0;
              margin-top: 1rem !important;
            }
          }
        }
      }
    }
    .chosenSub {
      max-height: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba($color: $black, $alpha: 0.1);
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      & small {
        color: rgba($color: $black, $alpha: 0.6);
        font-size: 0.8rem;
        margin-bottom: 1.5rem;
      }
      & h5 {
        // font-family: $helvetica;
        font-size: inherit;
        margin-bottom: 1rem;
      }
      & p {
        font-weight: 300;
        font-size: 1.75rem;
      }
      & span {
        font-size: 0.7rem;
        color: rgba($color: $black, $alpha: 0.6);
      }
    }
  }

  /*------------------------------------------------------------------------------------------------*/

  @include media-breakpoint-down(sm) {
    .innerLoginWrap {
      .logoWrapper {
        margin: 2rem auto;
      }
      .loginWrapper {
        .formWrap {
          .header {
            & a,
            & h5 {
              margin-top: 0.5rem;
            }
          }
          .formRow {
            .formGroup {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
