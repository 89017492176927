@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

// green: #003f5a;
// beige: #ece6dd;
// orange: #e76852;

.noReservation {
  @import "../../styles/Global.scss";

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;

  & h5 {
    min-width: 200px;
  }
}

.claimButtonWrap {
  justify-content: space-between;
  @import "../../styles/Global.scss";
  & div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}

.payShippingButton {
  @import "../../styles/Global.scss";
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  .btnLight {
    border-color: transparent;
    padding: 0%;
    font-weight: normal !important;
    & svg {
      margin-right: 0.5rem;
      color: rgba($black, 0.8);
    }
    &:hover {
      border-color: transparent !important;
      background: transparent !important;
    }
    &:disabled {
      color: rgba($black, 0.4) !important;
      & *:hover {
        color: rgba($black, 0.4) !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 0.5rem;
  }
}

.orderWrap {
  text-decoration: none;
  min-height: 125px;
}

.orderChild {
  .imgWrap {
    padding-bottom: 1rem !important;
  }
  @include media-breakpoint-down(xs) {
    margin: 1rem 0;
    padding: 1rem 0;
  }
}

.barrelBankRow {
  height: 100%;
  justify-content: center;
  // & [class*="col-"] {
  //   padding: 0;
  // }
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

// Customer Totals
.balanceWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 3;
  width: 100%;
  align-items: flex-end;
  justify-content: space-evenly;
  padding-top: 0.5rem;
  margin: 0;
  margin-top: 2rem;
  .innerWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0.2rem;
    min-width: fit-content;
    max-width: 200px;
    .iconTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .pound {
        color: #e76852;
      }
      & svg {
        font-size: 1.5rem;
        color: #003f5a;
        margin-right: 0.4rem;
      }
      & p {
        font-size: 1rem;
        line-height: 1;
      }
      @include media-breakpoint-down(sm) {
        & p {
          display: none;
        }
      }
    }
  }
  .circleBar {
    min-width: 75px;
  }
  .balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 2px solid #666;
    border-radius: 20px;
    margin: 0.5rem;
    flex: 30%;

    & h5 {
      padding-top: 1rem;
      text-align: center;
    }
    & p {
      font-size: 2rem;
      line-height: 1;
    }
  }
}

// Join The Collective
.joinCollective {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: fit-content;
  margin-top: 5rem;

  & h3 {
    line-height: 1.1;
    .exclusive {
      color: #e76852;
      text-decoration: underline;
    }
  }

  .innerWrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    .textWrap {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 5rem;
      margin-bottom: 3rem;
      position: relative;
      & h5 {
        margin-bottom: 2rem;
        & a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -0%;
        right: 10%;
        max-height: 300px;
        max-width: 300px;
        height: 100%;
        width: 100%;
        background: url("../../assets/images/caskshare-stamp-not-a-club-letters-black.svg")
          no-repeat center;
        background-color: #e76852;
        background-size: cover;
        border-radius: 50%;
        @include media-breakpoint-down(lg) {
          right: -5%;
        }
        @include media-breakpoint-down(md) {
          max-width: 200px;
          max-height: 200px;
        }
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      .buttonWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 2rem;
        & button {
          color: white;
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      .infoBtn {
        margin-top: 1rem;
      }
      @include media-breakpoint-down(md) {
        margin-top: 3rem;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 3rem;
    text-align: justify;
  }
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }
}

// Success Page
.successWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 50vh;
  margin-bottom: 2rem;
  text-align: center;
  & h4 {
    text-align: center;
    line-height: 1.1;
    & span {
      text-decoration: underline;
    }
  }
  & p {
    margin: 1rem 0;
  }
}

// Comments Modal
.commentsWrapper {
  .authorDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    & * {
      white-space: nowrap;
    }

    & p {
      font-size: 0.8rem;
      line-height: 1;
    }
  }
  .body {
    margin-top: 1rem;
  }

  @include media-breakpoint-down(md) {
    .list {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

// BarrelBank
.barrelBank {
  .rangeSlider {
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 1rem;
    position: relative;
    max-width: 900px;
    & h5 {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: 3rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
    }
  }
}

.filterWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 1rem;
  padding: 0;
  & li {
    margin: 0 0.5rem;
    cursor: pointer;
    padding: 0.4rem 1rem;
    border-bottom: 2px solid transparent;
    transition: all 0.1s ease-in;
    font-weight: bolder;
    &::first-letter {
      text-transform: uppercase !important;
    }
    @include media-breakpoint-down(sm) {
      padding: 0.4rem;
    }
  }
  .active {
    border-bottom: 2px solid #000;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.theCollective {
  max-width: 100vw;
  overflow: hidden;
  .personalWelcome {
    .message {
      margin: 4rem 0;
      & h3 {
        text-align: center;
        & span {
          font-family: "Exmouth";
          font-size: 5rem;
          letter-spacing: 0;
          font-weight: 100;
          margin-left: 1rem;
        }
      }
      & p {
        margin: 4rem 0;
        text-align: justify;
      }
      @include media-breakpoint-down(sm) {
        margin: 0;
        margin-bottom: 2rem;
      }
    }
  }

  // TASKING EVENT
  .tastingEvent {
    .cardWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .card {
        background-color: #e76852;
        padding: 1rem 1.5rem;
        margin: 0.5rem;
        min-width: 250px;
        flex: 1 1 30%;
        border-radius: 5px;
        .title {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 2rem;
          & img {
            height: 50px;
            width: 50px;
            background-color: #ece6dd;
            border-radius: 50%;
            padding: 0.2rem;
          }
          & p {
            font-size: 1rem;
            padding-left: 0.5rem;
            letter-spacing: 1px;
            margin: 0;
            color: #ece6dd;
            // text-transform: uppercase;
          }
        }
        .innerWrap {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 0.75rem;
          & img {
            height: 20px;
            width: 20px;
            filter: invert(1);
            opacity: 0.7;
          }
          & p {
            font-size: 1rem;
            padding-left: 0.5rem;
            margin: 0;
            color: rgba(255, 255, 255, 0.88);
            letter-spacing: 1px;
          }
        }
        .progressBar {
          margin: 1rem 0;
          & p {
            text-align: right;
            margin-bottom: 0.3rem;
            color: rgba(255, 255, 255, 0.6);
          }
          .progress {
            position: relative;
            width: 100%;
            height: 5px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 25px;
            & > div {
              background-color: #fff !important;
            }
          }
        }
      }
    }
  }

  // TASTING PACK
  .tastingPack {
    margin-top: 4rem;
    .cardWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      // Pack
      .cardPack {
        background-color: #ece6dd;
        padding: 2rem;
        margin: 1rem 0.5rem;
        min-width: 250px;
        flex: 1 1 47%;
        border-radius: 5px;

        .img {
          max-width: 200px;
          height: 200px;
          margin: 1.5rem auto;
          // border: 2px solid rgba(255, 255, 255, 0.6);
          border: 4px solid #e76852;
          border-radius: 50%;
          padding: 0.5rem;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .title {
          margin: 1rem;
          text-align: center;
          color: #003f5a;
        }
        .content {
          margin: 1rem;
          text-align: center;
          color: rgba(0, 0, 0, 0.5);
        }
        .date {
          margin: 1rem;
          text-align: center;
          font-weight: bolder;
        }
      }

      // History
      .cardHistory {
        background-color: #ece6dd;
        padding: 2rem;
        margin: 1rem 0.5rem;
        min-width: 250px;
        flex: 1 1 47%;
        border-radius: 5px;
        height: 100%;

        .title {
          // border-bottom: 2px solid rgba(255, 255, 255, 0.6);
          border-bottom: 4px solid #e76852;
          margin-bottom: 3rem;
          & > h4 {
            padding-bottom: 0.3rem;
            color: #003f5a;
            margin: 0;
          }
        }
        .inner {
          margin: 0.5rem 0;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          background-color: #003f5a;
          max-width: 100%;
          min-height: 25%;
          border-radius: 10px;
          .img {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.4rem;
            background-color: #ece6dd;
            border-radius: 50%;
            padding: 0.5rem;
            max-height: 80px;
            max-width: 80px;
            & img {
              height: auto;
              width: 100%;
            }
          }
          .info {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            padding: 0.3rem;
            & p {
              padding: 0;
              margin: 0;
              color: #ece6dd;
            }
          }
        }
      }
    }
  }

  // EXCLUSIVE CASKS
  .exclusiveCasks {
    margin: 4rem 0 0 0;
    .cardWrapper {
      margin-top: 2rem;
      & > div {
        padding: 0rem;
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 2rem 0 0 0;
      padding: 0;
      & > h5 {
        padding: 0 15px;
      }
    }
  }

  // EXCLUSIVE BENEFITS
  .exclusiveBenefits {
    margin-top: 4rem;
    .benefitsWrapper {
      & > p {
        margin: 2rem 0.5rem;
        width: 100%;
        flex: 0 0 100%;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: stretch;
      align-items: center;
      .cardWrapper {
        min-width: 250px;
        flex: 1 1 47%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #ece6dd;
        margin: 0.5rem;
        border-radius: 5px;
        .inner {
          margin: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .img {
            padding: 2rem;
            height: 150px;
            & img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              filter: invert(62%) sepia(23%) saturate(333%) hue-rotate(135deg)
                brightness(85%) contrast(86%);
              // filter: invert(55%) sepia(84%) saturate(2278%) hue-rotate(327deg)
              //   brightness(95%) contrast(89%);
            }
            opacity: 0.7;
          }
          .content {
            margin: 1rem;
            text-align: center;
            .title {
              text-transform: uppercase;
              font-size: 1.3rem;
              font-weight: bolder;
              letter-spacing: 1px;
              color: #003f5a;
              border-bottom: 4px solid #e76852;
              padding: 0 0.5rem 0.5rem 0.5rem;
              white-space: nowrap;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
      }
    }
  }
}

// COMMUNITY RESOURCES
.communityResources {
  margin-top: 4rem;
  padding: 5rem 1rem;
  background-color: #e76852;

  .cardWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    & h2 {
      color: #ece6dd;
    }

    & p {
      margin: 1rem 0;
      font-size: 1.3rem;
      letter-spacing: 1px;
      color: #ece6dd;
    }

    & img {
      margin-top: 1rem;
      background-color: #ece6dd;
      border-radius: 50px;
      padding: 0.5rem 2rem;
      width: 300px;
    }
    @include media-breakpoint-down(md) {
      & img {
        width: 200px;
      }
    }
  }
}

.shippingAdressesWrap {
  margin-top: 1rem;
  max-height: 430px;
  overflow: scroll;
}
.hide {
  display: none;
}

.addressHeaderWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @import "../../styles/Global.scss";
}
.formWrap {
  @import "../../styles/Global.scss";
}
.billingMar {
  margin-top: 3rem;
}

// ---------------------------------------Refer a Friend------------------------------------------
// Refer a Friend Modal

.referModal {
  @import "../../styles/Global.scss";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000000, 0.5);

  & > .inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: white;
    max-width: 450px;
    z-index: 3;
    box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);

    .content {
      text-align: center;
      & p {
        & span {
          font-weight: bold;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;

      & button {
        width: fit-content;
      }
    }
  }
}
.referFriendWrap {
  @import "../../styles/Global.scss";
  min-height: 500px;

  .inner {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    .benefits {
      & h5 {
        margin-bottom: 2rem;
        & span {
          text-decoration: underline;
          text-underline-offset: 2px;
          line-height: 1.2;
          color: $blue;
          white-space: nowrap;
        }
      }
      & p {
      }
    }
    .form {
      margin-top: 2.2rem;
      .formRow {
        display: flex;
        width: 100%;
        .formInput {
          width: 100%;
          & textarea {
            max-height: 100px;
          }
        }
        & button {
          margin-top: 1.5rem;
          & svg {
            transform: translateX(0);
            transition: all 0.3s ease-in;
          }
          &:hover {
            & svg {
              transform: translateX(5px);
              transition: all 0.3s ease-in;
            }
          }
        }
      }
      .buttonWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem 2rem;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

.confirmationModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.8);
  animation: fadeIn ease 0.5s;
  z-index: 1111;

  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    background-color: white;
    padding: 2rem;
    animation: slideIn ease 0.5s;

    & p {
      text-align: center;
      margin: 0;
      &:first-of-type {
        margin: 1.5rem;
      }
    }

    .btnPrimary {
      margin-top: 1.5rem;
    }
  }
}

// green: #003f5a;
// beige: #ece6dd;
// orange: #e76852;
