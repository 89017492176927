@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.addOnModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.95);
  z-index: 2;
  @include media-breakpoint-down(sm) {
    background-color: white;
  }
}

.loading {
  background: white !important;
  height: 100vh;
  width: 100vw;
}

.collectivePageWrap {
  @import "../../../styles/Global.scss";

  max-width: 100vw;
  visibility: hidden;
  opacity: 0;
  background-color: $white;

  .blackBG {
    background-color: $black;
    display: flex;
    z-index: 3;
  }
  .beigeBG {
    background-color: $beige;
    display: flex;
    z-index: 2;
  }
  .whiteBG {
    background-color: $white;
    display: flex;
    z-index: 1;
  }

  // Desktop
  .caskDetailSection {
    margin-top: -100px !important;
  }

  // Featured section
  .featuredComp {
    @import "../../components/app/FeaturedBottlesComp.module.scss";
    & [class*="featuredSection"] {
      padding-top: 125px !important;
      padding-bottom: 100px !important;
      @include media-breakpoint-down(sm) {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
    }
  }

  .spacer {
    height: 5rem;
    @include media-breakpoint-down(md) {
      height: 3.5rem;
    }
    @include media-breakpoint-down(sm) {
      height: 2.5rem;
    }
  }

  .heroWrap {
    width: 100vw;
    min-height: 500px;
    flex-direction: column;
    margin: 0;

    .heroImgWrapper {
      height: 100%;
      width: 100%;
      padding: 0;

      .inner {
        position: relative;
        width: 100%;
        height: calc(100vh - 110px);
        max-height: 680px;
        overflow: hidden;
        padding: 0;
        margin-bottom: -0.5rem;

        &::before {
          content: "";
          position: absolute;
          top: 0%;
          left: 0;
          background-color: rgba($color: $black, $alpha: 0.45);
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .innerWrap {
          height: 100%;
          .imgWrap {
            height: 100%;
            width: 100%;

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .content {
            position: absolute;
            bottom: 100px;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, 0%);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0 1rem;

            .head {
              & h2 {
                color: $black;
                background-color: $white;
                padding: 1rem 2rem;
                width: min-content;
                white-space: nowrap;
                & span {
                  font-family: $domaineHeading;
                }
                &:nth-child(2) {
                  margin-top: -1rem;
                }
              }
            }

            .buttonWrap {
              .newButton {
                background-color: $white;
                border-color: $white;
                padding: 5px 30px;
                &:hover,
                &:disabled,
                &:focus,
                &:active {
                  color: black !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .sectionWrap {
    padding: 0 2rem;
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }

    .joinBanner {
      .wrap {
        .inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2rem;
          & h3 {
          }
          & p {
            max-width: 635px;
          }
          .btnBlackOutline {
          }
        }
      }
    }

    .promoBanner {
      min-height: 460px;
      .wrap {
        position: relative;
        display: flex;
        flex-direction: row;

        .imgWrap {
          width: calc(50% - 1rem);
          height: 100%;
          max-height: 560px;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .contentWrap {
          position: absolute;
          top: 50%;
          right: calc(0% + 1rem);
          width: 60%;
          background: $black;
          padding: 3rem;
          transform: translateY(-50%);

          display: flex;
          flex-direction: column;
          gap: 2rem;

          & p {
            color: $beige;
            max-width: 400px;
          }
        }
      }
    }

    .benefitsWrap {
      padding-bottom: 3rem;
      .wrap {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-right: 0;

        .title {
          margin-bottom: 2rem;
          text-align: center;
          & h3 {
            & span {
              font-family: $domaineHeading;
            }
          }
        }

        .cardsWrap {
          width: 100%;
          .card {
            display: block !important;
            position: relative;
            max-height: 500px;
            min-height: 400px;
            min-width: 280px;
            padding-right: 1rem;

            &:nth-col(2) {
              display: none !important;
            }

            flex: 1;
            .img {
              height: 85%;
              & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .content {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 40%;
              width: calc(100% - 3rem);
              background: $black;
              padding: 2rem;

              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 1rem;

              & * {
                color: $beige;
              }
            }
          }
        }
        .btnBlackOutline {
          width: fit-content;
          margin: 0 auto;
        }
      }
    }

    .collectiveSection {
      min-height: 750px;
      .wrap {
        display: grid;
        grid-template-columns: 55% 45%;

        .left {
          display: flex;
          flex-direction: column;

          & > div * {
            color: $beige;
          }

          .title {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding-bottom: 3rem;
            & h3 {
              & span {
                font-family: $domaineHeading;
              }
            }
            & * {
              color: $beige;
              max-width: 600px;
            }
          }
          .benefits {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
            .inner {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              max-width: 230px;
              & h5 {
                & span {
                  font-family: $domaineHeading;
                }
              }
              & p {
                position: relative;
                margin-top: 1rem;
                color: $lightCoral;

                &::after {
                  position: absolute;
                  top: -0.75rem;
                  left: 0;
                  width: 1rem;
                  height: 2px;
                  background-color: $lightCoral;
                  content: "";
                }
              }
            }
          }
          .btnWhiteOutline {
            margin-top: 2rem;
            margin-bottom: 3rem;
            width: fit-content;
          }
        }

        .right {
          display: flex;

          .imgTop {
            position: absolute;
            top: 0;
            right: -5rem;
            max-width: 35%;
            height: 100%;
            width: 100%;
            max-height: 55%;
            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .imgBottom {
            position: absolute;
            bottom: -3rem;
            right: -3rem;
            max-width: 50%;
            height: 100%;
            width: 100%;
            max-height: 60%;
            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
          }
        }
      }
    }

    .testimonial {
      min-height: 600px;
      .wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .contentWrap {
          min-width: 60%;
          display: flex;
          justify-content: center;
          height: 100%;
          flex: 1;
          .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            gap: 1rem;

            & h3 {
              margin-bottom: 1rem;
              & span {
                font-family: $domaineHeading;
              }
            }

            & p {
              max-width: 500px;
            }

            & small {
              color: rgba($color: $black, $alpha: 0.45);
            }
          }
        }
        .imgWrap {
          position: relative;
          min-width: 40%;
          flex: 1;

          .bottleImg {
            position: absolute;
            height: 100%;
            max-height: 800px;
            top: -2rem;
            padding-bottom: 1rem;
            right: 0;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .barrelbank {
      padding: 3rem 0;
      gap: 3rem;
      .wrap {
        min-width: calc(50% - 1.5rem);
        flex: 1;

        .inner {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          max-width: 450px;
          margin: 0 auto;
          .contentWrap {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            & * {
              text-align: center;
            }
          }
          .imgWrap {
            width: 100%;
            height: 100%;

            & img {
              width: 100%;
              height: 100%;
              min-height: 350px;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
      .buttonWrap {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // Media Queries ------ Tablet
  @include media-breakpoint-down(md) {
    .sectionWrap {
      .promoBanner {
        .wrap {
          // .imgWrap {}
          .contentWrap {
            & p {
              color: $beige;
            }
          }
        }
      }

      .benefitsWrap {
        .wrap {
          gap: 3rem;

          .title {
            margin-bottom: 0rem;
          }

          .cardsWrap {
            .card {
              flex: 1;
            }
          }
          .btnBlackOutline {
            margin-bottom: 0;
          }
        }
      }

      .collectiveSection {
        .wrap {
          grid-template-columns: 60% 40%;
          .right {
            .imgTop {
              max-width: 40%;
            }
            .imgBottom {
              max-width: 45%;
            }
          }
        }
      }
      .barrelbank {
        padding: 2rem 0;
        gap: 2rem 0rem;
        .wrap {
          .inner {
            padding: 0rem;
          }
        }
      }
    }
  }
  // Media Queries ------ Mobile
  @include media-breakpoint-down(sm) {
    .heroWrap {
      .heroImgWrapper {
        .inner {
          &::before {
          }

          .innerWrap {
            .imgWrap {
              & img {
              }
            }
            .content {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 1rem;
              .head {
                width: 100%;
                & h2 {
                  width: 100%;
                  & span {
                  }
                  &:nth-child(2) {
                    margin-top: -2rem;
                  }
                }
              }

              .buttonWrap {
                .newButton {
                  &:hover {
                  }
                }
              }
            }
          }
        }
      }
    }

    .sectionWrap {
      .joinBanner {
        .wrap {
          .inner {
            & h3 {
            }
            & p {
            }
            .btnBlackOutline {
            }
          }
        }
      }

      .promoBanner {
        .wrap {
          flex-direction: column;
          .imgWrap {
            position: absolute;
            max-height: 265px;
            left: -2rem;
            width: 85%;
            & img {
              object-position: center;
            }
          }
          .contentWrap {
            position: initial;
            transform: unset;
            margin-top: 10rem;
            padding-top: 9rem;
            width: 100%;
            & p {
              color: $beige;
            }
          }
        }
      }

      .benefitsWrap {
        .wrap {
          gap: 3rem;
          padding-right: 1rem;
          .title {
            & h3 {
              & span {
              }
            }
          }

          .cardsWrap {
            .card {
              flex: 1;
              .img {
                & img {
                }
              }
              .content {
                & * {
                }
              }
            }
          }
          .btnBlackOutline {
          }
        }
      }

      .collectiveSection {
        .wrap {
          grid-template-columns: repeat(1, 1fr);
          .spacer {
            display: none;
          }
          .left {
            &:nth-last-child(3) {
              padding-top: 3rem;
            }

            & > div * {
            }

            .title {
              & h3 {
                & span {
                }
              }
              & * {
              }
              padding-bottom: 2rem;
            }
            .benefits {
              .inner {
                & h5 {
                  & span {
                  }
                }
                & p {
                  &::after {
                  }
                }
              }
            }
            .btnWhiteOutline {
              margin-bottom: 0;
            }
          }

          .right {
            position: relative;
            min-height: 400px;
            .imgTop {
              max-width: 85%;
              max-height: 100%;
              right: -2rem;
              & img {
              }
            }
            .imgBottom {
              max-width: 85%;
              max-height: 100%;
              right: unset;
              left: -2rem;
              bottom: -3rem;

              & img {
              }
            }
          }
        }
      }

      .testimonial {
        min-height: fit-content;
        padding-top: 6rem;
        padding-bottom: 3rem;
        .wrap {
          .contentWrap {
            .inner {
              & h3 {
                & span {
                }
              }

              & p {
              }
            }
          }
          .imgWrap {
            display: none;
            .bottleImg {
            }

            & img {
            }
          }
        }
      }

      .barrelbank {
        .wrap {
          min-width: 300px;
          .inner {
            .contentWrap {
            }
            .imgWrap {
              & img {
              }
            }
          }
        }
        .buttonWrap {
          .btnBlackOutline {
          }
        }
      }
    }
  }

  // Media Queries ------ Mobile
  @include media-breakpoint-down(xs) {
    .heroWrap {
      .heroImgWrapper {
        .inner {
          &::before {
          }

          .innerWrap {
            .imgWrap {
              & img {
              }
            }
            .content {
              .head {
                & h2 {
                  & span {
                  }
                  &:nth-child(2) {
                  }
                }
              }

              .buttonWrap {
                .newButton {
                  &:hover {
                  }
                }
              }
            }
          }
        }
      }
    }

    .sectionWrap {
      .joinBanner {
        .wrap {
          .inner {
            & h3 {
            }
            & p {
            }
            .btnBlackOutline {
            }
          }
        }
      }

      .promoBanner {
        .wrap {
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & p {
              color: $beige;
            }
          }
        }
      }

      .benefitsWrap {
        .wrap {
          gap: 3rem;
          padding-right: 1rem;
          .title {
            & h3 {
              & span {
              }
            }
          }

          .cardsWrap {
            .card {
              flex: 1;
              .img {
                & img {
                }
              }
              .content {
                & * {
                }
              }
            }
          }
          .btnBlackOutline {
          }
        }
      }

      .collectiveSection {
        .wrap {
          .left {
            & > div * {
            }

            .title {
              & h3 {
                & span {
                }
              }
              & * {
              }
            }
            .benefits {
              .inner {
                & h5 {
                  & span {
                  }
                }
                & p {
                  &::after {
                  }
                }
              }
            }
            .btnWhiteOutline {
            }
          }

          .right {
            .imgTop {
              & img {
              }
            }
            .imgBottom {
              & img {
              }
            }
          }
        }
      }

      .testimonial {
        .wrap {
          .contentWrap {
            .inner {
              & h3 {
                & span {
                }
              }

              & p {
              }
            }
          }
          .imgWrap {
            .bottleImg {
            }

            & img {
            }
          }
        }
      }

      .barrelbank {
        .wrap {
          .inner {
            .contentWrap {
            }
            .imgWrap {
              & img {
              }
            }
          }
        }
        .btnBlackOutline {
        }
      }
    }
  }
}
