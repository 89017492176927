@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.spirit-type-wrap {
  padding-bottom: 50px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  transform-origin: left;
  transition: 1s;
  font-weight: bold;
  cursor: pointer;
  & a {
    &:hover {
      text-decoration: none;
    }
  }
  .explainer-header {
    color: rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    &.active {
      color: rgba(0, 0, 0, 1);
      font-size: 160%;
    }
    .indicator-bar {
      height: 4px;
      width: 0%;
      position: relative;
      left: -20px;
      top: 10px;
      background-color: black;
      transition: 1s;
      &.active {
        width: 180px;
        left: -200px;
      }
    }
  }
}
/* filtered shop products */
.sort-dropdown {
  &:first-letter {
    text-transform: uppercase;
  }
}
.search-filter {
  cursor: pointer;
  position: relative;
  &.active {
    font-weight: bold;
    &::after {
      content: "X";
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &::first-letter {
    text-transform: uppercase;
  }
}
.filter-shop {
  padding: 50px 0px 20px 0px;
  align-items: flex-end;
  & h4,
  p {
    margin: 0;
  }
  p {
    padding-bottom: 3px;
  }
  @include media-breakpoint-down(lg) {
    & h4 {
      font-size: 1.875rem;
    }
  }
}
/* price slider filter */
.input-range__label-container {
  display: none !important;
}
.input-range__track--active {
  background: rgba(0, 0, 0, 0.8);
}
.input-range__slider {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin-top: -0.6rem;
}
.input-range__track {
  height: 1px;
}
/* Filtering criteria */
.load-more {
  color: rgb(238, 94, 77);
  text-decoration: underline;
  cursor: pointer;
}
/* shop products */
.product-row {
  width: 100%;
  margin: 0;
  justify-content: flex-end;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  .dataContainer {
    width: 100%;
    margin: 0;
    .whisky-card {
      margin-bottom: 40px;
      border-right: 2px solid rgba(0, 0, 0, 0.1);
      &:nth-child(3n) {
        border-right: 0;
      }
      // &:nth-child(9) {
      //   border-right: 0 !important;
      // }
      @include media-breakpoint-down(lg) {
        &:nth-child(3n) {
          border-right: 2px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-child(2n) {
          border-right: 0;
        }
      }
      @include media-breakpoint-down(sm) {
        border-right: none !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
      }
      .product-img-wrap {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;

        & span {
          position: absolute;
          bottom: 0;
          font-size: 1.2rem;
          padding: 0.2rem;
          background-color: rgba(245, 245, 245, 0.8);
          width: 100%;
          text-align: center;
        }
      }
      .qty-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        color: rgb(238, 94, 77);
        padding: 5px;
        border: 1px solid rgb(238, 94, 77);

        margin: 0 auto;

        & h5 {
          line-height: 1rem;
          font-size: 0.9rem;
          margin: 0;
          text-align: center;
        }
      }
      & h4 {
        text-align: left;
      }
      .title-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120px;
        padding-top: 20px;
        & h5 {
          line-clamp: 3;
        }
      }
      .price-wrap {
        // font-weight: bolder;
        // font-size: 1rem;
        // padding-top: 10px;
        // padding-bottom: 10px;
        // padding-right: 10px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        & p {
          margin: 0;
          font-size: 1.5rem;
        }
        & button {
          padding: 0.7rem 1.25rem;
          font-weight: normal;
          font-size: 0.9rem;
        }
        .collective-price {
          color: #e76852;
        }
      }
      .collective-note-wrap {
        margin-top: 1rem;
        & p {
          background-color: rgba(0, 0, 0, 0.1);
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
          text-align: center;
          font-weight: 500;
          margin: 0;
        }
        .collective-note {
          background-color: #e76852;
          color: white;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      overflow: hidden;
    }
  }
  .dataContainer > div:nth-of-type(1) {
    order: 1;
  }
  .dataContainer > div:nth-of-type(2) {
    order: 2;
  }
  .dataContainer > div:nth-of-type(3) {
    order: 3;
  }
  .dataContainer > div:nth-of-type(4) {
    order: 4;
  }
  .dataContainer > div:nth-of-type(5) {
    order: 5;
  }
  .dataContainer > div:nth-of-type(6) {
    order: 7;
    border-right: 2px solid rgba(0, 0, 0, 0.1) !important;
    @include media-breakpoint-down(lg) {
      border-right: 0 !important;
    }
  }
  .dataContainer > div:nth-of-type(7) {
    order: 8;
  }
  .dataContainer > div:nth-of-type(8) {
    order: 9;
    border-right: none !important;
  }
  .dataContainer > div:nth-of-type(9) {
    order: 6;
  }

  .extra-card {
    display: none;
    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: center;
    }
  }
  .shop-extra-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding-left: 15px;
    @include media-breakpoint-down(lg) {
      max-height: 320px;
    }
    .text-wrap {
      padding-top: 30px;
      position: relative;
      @include media-breakpoint-down(lg) {
        & h4 {
          font-size: 1.875rem;
          letter-spacing: 3px;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 4px;
        background-color: black;
      }
    }
    .img-wrap {
      position: relative;
      width: 120%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .shop-btn {
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        padding: 15px 15px 11px 15px;
        & a {
          margin: 0;
          padding: 0;
        }
      }
    }
    .overlay-img {
      position: absolute;
      top: 0;
      right: -110px;
      z-index: -1;
      height: 85%;
      @media (max-width: 1300px) {
        width: 95px;
        right: -60px;
      }
      @include media-breakpoint-down(lg) {
        width: 85px;
        right: -47px;
      }
      & img {
        height: 100%;
        width: 100%;
      }
    }
  }
  /* pagination */
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    .paginationItem {
      background: #fff;
      border: 2px solid #666;
      padding: 10px 15px;
      height: 35px;
      width: 35px;
      position: relative;
      margin: 0 5px;
      &:focus,
      &:active,
      &:visited {
        border-color: transparent;
        outline: none;
      }
    }
    .paginationItem span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .prev,
    .next {
      background: transparent;
      border: none;
      padding: 10px;
      margin: 0 10px;
      cursor: pointer;
      &:focus,
      &:active,
      &:visited {
        border-color: transparent;
        outline: none;
      }
    }
    .paginationItem.active {
      border: 1px solid #888;
      color: #888;
      pointer-events: none;
    }
    .prev.disabled,
    .next.disabled {
      pointer-events: none;
      box-shadow: none;
      color: #999;
    }
  }
}
//-------------------------------------------------------------------------------------------------------------------------//
/* Product Details Page */
.relative {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  @include media-breakpoint-up(sm) {
    padding-top: 70px;
  }
}
.delivery-info-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .delivery-info-wrap {
    background-color: #fff;
    min-width: 500px;
    max-height: 100vh;
    width: 50vw;
    padding: 1rem 2rem;
    position: relative;
    overflow: scroll;
    & p {
      padding-top: 1rem;
      text-align: left;
      & a {
        text-decoration: underline;
      }
      &:last-of-type {
        padding-bottom: 1rem;
      }
    }
    .fixed {
      position: sticky;
      top: 0;
      left: 0;
      padding: 1rem 0;
      padding-top: 1rem;
      background: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      & span {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
        & i {
          font-size: 20px;
          transform: translateY(4px);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      min-width: 100vw;
      height: 100vh;
      padding: 0px 15px;
      & h4 {
        font-size: 2rem;
        margin: 0;
      }
      & p {
        padding-top: 0.7rem;
        &:last-of-type {
          padding-bottom: 0.7rem;
        }
      }
    }
  }
}
.product-detail-wrap {
  margin-top: 50px;
  padding-bottom: 50px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  @include media-breakpoint-up(lg) {
    gap: 1rem;
  }
  .imgCol {
    display: flex;
    justify-content: center;
    align-items: center;
    .image-wrap {
      max-height: 250px;
      height: 100%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  & > .col-lg-5 {
    @include media-breakpoint-down(xs) {
      margin-top: 0.5rem;
    }
    & p {
      text-align: justify;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 15px;
    & h4 {
      font-size: 1.875rem;
    }
    & [class*="col-"] {
      display: flex;
      flex-direction: column;
      & h4 {
        font-size: 1.5rem;
      }
    }
  }
  .qty-wrap {
    background-color: #fff;
    color: #ee5e4d;
    padding: 5px;
    margin-bottom: 2rem;
    border: 1px solid #ee5e4d;
    width: fit-content !important;
    margin: 1rem 0;
    @include media-breakpoint-down(xs) {
      margin: 1rem auto;
    }
    & h5 {
      line-height: 1rem;
      font-size: 0.9rem;
      // margin: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    & h4 {
      text-align: center;
    }
  }

  .product-desc {
    @include media-breakpoint-down(xs) {
      & p {
        text-align: center;
      }
    }
  }

  .info-row-wrap {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    padding-left: 15px;
    height: 100%;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
    }

    .rmv-padding {
      & > div {
        padding: 0;
      }
    }

    @include media-breakpoint-down(md) {
      padding: unset;
      padding-top: 50px;
      border-left: 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 20px;
        left: 50%;
        width: 15%;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        transform: translateX(-50%);
      }
    }
    .basket-wrap {
      align-self: flex-start;
      .to-basket-wrap {
        width: 100%;
        padding-left: 15px;
        align-items: baseline;
        @include media-breakpoint-down(md) {
          padding-top: 0;
        }
        .price-info {
          padding-left: 0;
          padding-right: 0;
          text-align: right;
          & p {
            font-size: 1.2rem;
            font-weight: bolder;
            @include media-breakpoint-down(lg) {
              font-size: 1rem;
            }
            @include media-breakpoint-down(md) {
              font-size: 1.3rem;
            }
          }
          @include media-breakpoint-up(md) {
            font-size: 2rem;
          }
          @include media-breakpoint-down(sm) {
            margin-top: 20px;
            order: 1;
            text-align: left;
            & p {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
.recommended-product {
  text-align: center;
  .you-might-also-like {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 80px;
    margin-bottom: 50px;
    & h4 {
      margin: 0 auto;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      margin-bottom: 10px;
      & h4 {
        font-size: 2rem;
        margin: 0 auto;
      }
    }
    .browse-all {
      position: absolute;
      right: 0;
      bottom: 0;
      text-transform: uppercase;
      font-weight: 600;
      font-family: "Bebas Neue";
      letter-spacing: 0.1rem;
      & a:hover {
        color: #000;
      }
      & i {
        color: #e76852;
        margin-left: 10px;
      }
      @include media-breakpoint-down(sm) {
        position: unset;
        text-align: center;
        width: 100%;
        margin: 0.5rem 0;
      }
    }
  }
  .recommended-slider {
    padding-bottom: 1rem;
    @include media-breakpoint-down(md) {
      padding: unset;
    }
    & .col {
      @include media-breakpoint-down(sm) {
        padding: unset;
      }
      .you-might-like-card {
        padding: 1rem 1rem 1rem 2rem;
        @include media-breakpoint-up(md) {
          padding: 2rem;
        }
        &-wrapper {
          justify-content: center;

          & > div {
            flex: 0 0 100%;
            height: auto;
            & .row {
              min-height: 3rem;
              text-align: left;

              .cask-title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 37px;
              }

              .price-wrap {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                & p {
                  margin: 0;
                }
                .title {
                  margin-top: 0.5rem;
                  font-weight: lighter;
                  font-size: 0.9rem;
                }
                .cask-price {
                  font-size: 1.1rem;
                }
              }
              .collective {
                & p {
                  font-weight: bolder !important;
                }
              }
            }
            .price-row {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 0.5rem;
              .price-wrap {
                flex: 1;
                min-width: 110px;
                & small {
                  font-weight: 300;
                  font-size: 0.8rem;
                }
                .collectivePrice {
                  font-weight: 300;
                  font-size: 1.5rem;
                  color: #e76852;
                  background-color: transparent !important;
                }
                .nonCollective {
                  font-weight: 300;
                  font-size: 1.5rem;
                  color: black;
                  background-color: transparent !important;
                }
              }
              .button-wrap {
                margin-top: 0.5rem;
                min-width: 110px;
              }
            }
            .banner-wrap {
              margin-top: 0.5rem;
              min-height: unset;
              .banner {
                width: 100%;
                padding: 0.2rem;
                text-align: center;
                & p,
                & a {
                  font-size: 0.8rem;
                  font-weight: 500;
                  padding: 0.2rem;
                  margin: 0 auto;
                }
              }
              .collectivePrice {
                background-color: rgba($color: #e76852, $alpha: 1);
                & p,
                & a {
                  color: white;
                  padding: 0.2rem;
                  margin: 0 auto;
                  text-align: center;
                }
              }
              .nonCollective {
                background-color: rgba($color: #003f5a, $alpha: 0.2);
                & p,
                & a {
                  color: black;
                  padding: 0.2rem;
                  margin: 0 auto;
                  text-align: center;
                }
              }
            }
            .product-image {
              padding: 0;
              margin-bottom: 1rem;
              height: clamp(200px, 250px, 300px);
              & img {
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}
//-------------------------------------------------------------------------------------------------------------------------//
/* Basket Page */
.you-might-like-slider-mobile {
  @include media-breakpoint-down(sm) {
    padding: 0;
    max-width: 100vw;
    overflow: hidden;
  }
}
.you-might-like-area {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  position: relative;
  @include media-breakpoint-down(md) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    border-top: 5px solid #000;
  }
  .slick-dots {
    bottom: -50px;
  }
  .you-might-like-card {
    &-wrapper {
      padding: 1rem;
      .card-img-wrap {
        max-height: 200px;
        & > div {
          max-height: 200px;
          height: 100%;
        }
      }
      & > div {
        flex: 0 0 100%;
        height: auto;
        &:first-child {
          margin-bottom: 1rem;
        }
        .row {
          min-height: 3rem;
          text-align: left;
          .cask-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 37px;
          }
          .price-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & p {
              margin: 0;
            }
            .title {
              margin-top: 0.5rem;
              font-weight: lighter;
              font-size: 0.9rem;
            }
            .cask-price {
              font-size: 1.1rem;
            }
          }
          .collective {
            & p {
              font-weight: bolder !important;
            }
          }
        }
        .price-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.5rem;
          .price-wrap {
            flex: 1;
            min-width: 110px;
            & small {
              font-weight: 300;
              font-size: 0.8rem;
            }
            .collectivePrice {
              font-weight: 300;
              font-size: 1.5rem;
              color: #e76852;
              background-color: transparent !important;
            }
            .nonCollective {
              font-weight: 300;
              font-size: 1.5rem;
              color: black;
              background-color: transparent !important;
            }
          }
          .button-wrap {
            margin-top: 0.5rem;
            min-width: 110px;
          }
        }
        .banner-wrap {
          margin-top: 0.5rem;
          min-height: unset;
          .banner {
            width: 100%;
            padding: 0.2rem;
            text-align: center;
            & p,
            & a {
              font-size: 0.8rem;
              font-weight: 500;
              padding: 0.2rem;
              margin: 0 auto;
            }
          }
          .collectivePrice {
            background-color: rgba($color: #e76852, $alpha: 1);
            & p,
            & a {
              color: white;
              padding: 0.2rem;
              margin: 0 auto;
              text-align: center;
            }
          }
          .nonCollective {
            background-color: rgba($color: #003f5a, $alpha: 0.2);
            & p,
            & a {
              color: black;
              padding: 0.2rem;
              margin: 0 auto;
              text-align: center;
            }
          }
        }
        .cask-image-component,
        .product-image {
          margin-bottom: 0.5rem;
        }
      }
    }
    .signature-stamp-wrapper {
      display: none;
    }
    .early-bird-banner {
      display: none;
    }
  }
}
