@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.generalSliderComponent {
  @import "../../styles/Global.scss";
  .indicatorCol {
    display: flex;
    justify-content: space-between;
    .indicatorItem {
      font-size: 1.25rem;
      padding: 1rem 0;
      color: rgba(black, $alpha: 0.15);
      transition: all 0.25s ease-in;
      cursor: pointer;
      .indicatorBar {
        width: 60px;
        height: 4px;
        background-color: transparent;
        margin-bottom: 1rem;
        transition: all 0.25s ease-in;
      }
    }
    .active {
      color: black;
      .indicatorBar {
        width: 60px;
        height: 4px;
        background-color: black;
        margin-bottom: 1rem;
      }
    }
  }

  .slideCol {
    height: 100%;
    .slide {
      & p {
        height: 100%;
        padding: 1px;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
        &:last-child {
          margin-top: 1rem;
        }
      }
    }
    .buttonWrap {
      padding-top: 2rem;
    }
  }

  .titleCol {
    & h2 {
      font-size: 2rem;
      max-width: 250px;
      & span {
        letter-spacing: 0px;
      }
    }
  }

  .slideCol {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .indicatorArea {
    .indicatorItem {
      transform-origin: left;
      line-height: 1.2;
      font-weight: bold;
      cursor: pointer;
      transform: scale(1);
      color: #e1e1e1;
      font-size: 2rem;
      transition: all ease-in-out 0.5s !important;
      letter-spacing: 0px !important;
    }
    .indicatorBar {
      height: 6px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: all ease-in-out 0.5s !important;
    }
    .active {
      color: black;
      width: 180px !important;
      left: -200px !important;
    }
  }
  .slideArea {
    height: 100%;
    .slide {
      min-height: 180px;
      & p {
        height: 100%;
        padding: 1px;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .indicatorCol {
      .indicatorItem {
        font-size: 1.1rem;
        .indicatorBar {
          width: 40px;
        }
      }
      .active {
        color: black;
        .indicatorBar {
          width: 40px;
        }
      }
    }
  }
}
//
.collectiveClass {
  padding: 0rem;
  padding-bottom: 0;
  .indicatorArea {
    .indicatorItem {
      font-size: 1.25rem;
      white-space: nowrap;
      letter-spacing: 0px !important;
    }
    .indicatorBar {
      height: 3px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: all ease-in-out 0.5s !important;
    }
    .active {
      color: black;
      width: 20px !important;
      left: -40px !important;
      font-size: 1.875rem;
    }
  }
  .slideArea {
    .slide {
      & p {
        font-size: 1rem;
        max-width: 320px;
        text-align: left;
      }
    }
  }
}
