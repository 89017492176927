@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../styles/Global.scss";

.priceWrap {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  gap: 0rem;
  .infoRow {
    margin-top: 0.25rem;
  }
  .infoRow,
  .shippingInfo {
    margin-bottom: 1rem;
    & p,
    & a {
      color: $orange;
      font-size: 0.9rem;
      letter-spacing: 0;
    }
    & a {
      cursor: pointer;
      white-space: nowrap;
      & svg {
        transition: transform ease 0.2s;
        transform: translateX(0rem);
      }
      &:hover {
        & svg {
          transform: translateX(0.5rem);
          transition: transform ease 0.2s;
        }
      }
    }
    .priceNotice {
      margin-top: 0.25rem;
    }
  }
  .shippingInfo {
    color: $black;
    margin-bottom: 0.5rem;
    & p,
    & a {
      color: $black;
      & span {
        font-weight: bold;
      }
    }
  }
  .innerWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    gap: 0.25rem;
    .price {
      font-weight: bold;
    }
    .originalPrice {
      font-size: 1.2rem;
      text-decoration: line-through;
      color: darkslategray;
    }
  }
  .buttonRow {
    padding-top: 0.5rem;
    .btnCoralOutline {
      width: 100%;
    }
  }
}

// -------------------------  Media Queries  -------------------------
// Mobile
@include media-breakpoint-down(sm) {
  .priceWrap {
    order: 2;
    align-items: center;
    background-color: $orange;
    max-width: 100%;
    width: 100%;
    padding: 1.5rem 1rem;
    & * {
      color: $beige !important;
    }
    .btnCoralOutline {
      width: 100%;
      border-color: $beige;
      max-width: 300px;
    }
  }
}
