@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.casksharePageWrap {
  @import "../../../styles/Global.scss";

  .blackBG {
    background-color: $black;
    display: flex;
    z-index: 3;
  }
  .beigeBG {
    background-color: $beige;
    display: flex;
    z-index: 2;
  }
  .whiteBG {
    background-color: $white;
    display: flex;
    z-index: 1;
  }

  // Desktop
  .caskDetailSection {
    margin-top: -100px !important;
  }
  .sectionWrap {
    padding: 0 2rem;
    //Active Step
    .activeStepRow {
      min-height: 286px;
      .activeStep {
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 5rem;
        gap: 2rem;

        & h3 {
          min-width: 200px;
          color: $beige;
        }
        .btnWhite {
          white-space: nowrap;
          margin-left: auto;
        }
      }
    }
    // Cask Details
    .caskDetailRow {
      padding-top: 6rem;
      .imgWrap {
        max-height: 530px;
        // cursor: pointer;
        position: relative;
        // .badge {
        //   position: absolute;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   z-index: 30;
        //   height: 40px;
        //   padding: 0 1rem;
        //   background-color: $orange;

        //   & p {
        //     font-size: 0.9rem;
        //     color: $beige;
        //   }
        // }
        & > div {
          height: 100%;
        }
        @include media-breakpoint-down(lg) {
          max-height: 400px;
        }
      }

      .contentWrap {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .titleWrap {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .distillery {
            border-bottom: 2px black solid;
            width: min-content;
            & p {
              font-weight: bold;
              white-space: nowrap;
              padding-bottom: 0.5rem;
            }
          }

          & h3 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .descriptionWrap {
            .description {
              max-width: 470px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .fullDescription {
              overflow: visible;
              height: auto;
              display: block;
            }
            .more {
              color: $orange;
              text-decoration: underline;
              margin-top: -0.25rem;
              cursor: pointer;
            }
          }
        }
        .detailWrap {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .caskInfoWrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem 0;
            .inner {
              & p {
                font-size: 0.8rem;
              }
              .info {
                color: rgba($black, 0.75);
              }
            }
          }
        }

        .priceWrap {
          display: flex;
          flex-direction: column;
          max-width: 350px;
          gap: 0rem;
          .infoRow {
            margin-top: .25rem;
          }
          .infoRow,
          .shippingInfo {
            margin-bottom: 1rem;
            & p,
            & a {
              color: $orange;
              font-size: 0.9rem;
              letter-spacing: 0;
            }
            & a {
              cursor: pointer;
              white-space: nowrap;
              & svg {
                transition: transform ease 0.2s;
                transform: translateX(0rem);
              }
              &:hover {
                & svg {
                  transform: translateX(0.5rem);
                  transition: transform ease 0.2s;
                }
              }
            }
            .priceNotice {
              margin-top: 0.25rem;
              // text-align: right;
            }
          }
          .shippingInfo {
            color: $black;
            margin-bottom: 0.5rem;
            & p,
            & a {
              color: $black;
              & span {
                // text-decoration: underline;
                font-weight: bold;
              }
            }
          }
          .innerWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
            gap: 0.25rem;
            .price {
              font-weight: bold;
            }
          }
          .buttonRow {
            .btnCoralOutline {
              width: 100%;
            }
          }
        }
      }

      .flavourWrap {
        background-color: transparent;
      }
    }
    // How it works
    .howItWorksRow {
      padding-top: 5rem;
      min-height: 300px;
      .inner {
        & h3 {
          text-align: center;
          font-weight: 600;
        }
      }
    }
    // How it works cards
    .stepCardsRow {
      margin-top: -7.5rem;
      margin-bottom: 7.5rem;
      padding-bottom: 2.5rem;
      height: 100%;
      gap: 1rem;
      justify-content: space-between;
      .stepCard {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0;
        @include media-breakpoint-up(md) {
          flex: 0 0 48.9%;
          max-width: 48.9%;
        }
        .imgWrap {
          max-height: 400px;
          flex: 1 1 60%;
          overflow: hidden;
          height: auto;
          & img {
            height: 100%;
            width: 100.5%;
            object-fit: cover;
            object-position: center;
          }
        }
        .contentWrap {
          flex: 1 1 40%;
          height: 100%;
          background-color: $black;
          padding: 4rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          & * {
            color: $beige;
          }
        }
      }
    }
    .noMargin {
      margin-bottom: -4rem !important;
    }

    // Distillery Section
    .distilleryRow {
      gap: 2rem;
      justify-content: space-between;
      min-height: 700px;

      .imgsWrap {
        position: relative;
        padding: 0;
        @include media-breakpoint-up(md) {
          flex: 0 0 47.5%;
          max-width: 47.5%;
        }
        .topImg {
          max-height: 515px;
          max-width: 470px;
          height: 100%;
          width: 100%;
          margin-top: -3rem;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
        .bottomImg {
          max-height: 510px;
          max-width: 615px;
          position: absolute;
          top: 6rem;
          right: 30%;
          height: 100%;
          width: max-content;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
      }
      .contentWrap {
        padding: 0;
        @include media-breakpoint-up(md) {
          flex: 0 0 47.5%;
          max-width: 47.5%;
        }
        display: flex;
        align-items: center;
        .inner {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .description {
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .showShadow {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 40px;
              background: $beige;
              background: linear-gradient(
                0deg,
                rgba($beige, 1) 0%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }

          .btnBlackOutline {
            width: fit-content;
          }
        }
      }
    }
    // Artist section
    .artistRow {
      min-height: 800px;
      justify-content: space-between;
      .contentWrap {
        display: flex;
        align-items: center;
        padding: 0;
        @include media-breakpoint-up(md) {
          flex: 0 0 48.9%;
          max-width: 48.9%;
        }
        .inner {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .description {
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .showShadow {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 40px;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }

          .btnBlackOutline {
            width: fit-content;
          }
        }
      }
      .imgsWrap {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 0;
        @include media-breakpoint-up(md) {
          flex: 0 0 48.9%;
          max-width: 48.9%;
        }
        .artistImg {
          max-height: 540px;
          max-width: 450px;
          height: 100%;
          width: 100%;
          margin-right: -6rem;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: cover;
          }
        }
        .bottleImg {
          max-height: 500px;
          max-width: 200px;
          height: 100%;
          position: absolute;
          left: 17%;
          bottom: 10%;
          & img {
            height: 100%;
            width: 100%;
            object-position: center;
            object-fit: contain;
          }
          @include media-breakpoint-down(lg) {
            left: 2%;
          }
        }
      }
    }
  }

  // Featured section
  .featuredComp {
    @import "../../components/app/FeaturedBottlesComp.module.scss";
    & [class*="featuredSection"] {
      padding-top: 125px !important;
      padding-bottom: 100px !important;
      @include media-breakpoint-down(sm) {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
      }
    }
  }

  // -------------------------  Media Queries  -------------------------
  // Tablet
  @include media-breakpoint-down(md) {
    .caskDetailSection {
      margin-top: 0px !important;
    }
    .sectionWrap {
      padding: 0 2rem;
      //Active Step
      .activeStepRow {
        min-height: 0px;
        .activeStep {
          justify-content: center;
          align-items: center;
          margin: 0;
          & h3 {
            padding: 2rem;
          }
          .btnWhite {
            display: none;
          }
        }
      }
      // Cask Details
      .caskDetailRow {
        margin-top: 1rem;
        .imgWrap {
          margin-top: 4rem;
          & > div {
          }
        }

        .contentWrap {
          .titleWrap {
            .distillery {
              & p {
              }
            }
            & h3 {
            }
            .descriptionWrap {
              .description {
              }
              .fullDescription {
              }
              .more {
              }
            }
          }
          .detailWrap {
            .caskInfoWrap {
              .inner {
                & p {
                }
                .info {
                }
              }
            }
          }

          .priceWrap {
            .innerWrap {
              .price {
              }
            }
            .colDisc {
            }
          }
        }

        .flavourWrap {
        }
      }
      // How it works
      .howItWorksRow {
        padding-top: 3rem;
        min-height: 250px;

        .inner {
          & h3 {
          }
        }
      }
      // How it works cards
      .stepCardsRow {
        padding-bottom: 0;
        .stepCard {
          .imgWrap {
            max-height: 300px;
            & img {
            }
          }
          .contentWrap {
            padding: 2rem;
            gap: 1.5rem;
            & * {
            }
          }
        }
      }
      .noMargin {
        margin-bottom: 0 !important;
      }

      // Distillery Section
      .distilleryRow {
        min-height: 500px;
        .imgsWrap {
          .topImg {
            max-height: 400px;
            max-width: 400px;
            & img {
            }
          }
          .bottomImg {
            max-height: 350px;
            max-width: 400px;
            right: unset;
            left: -30%;
            & img {
            }
          }
        }
        .contentWrap {
          .inner {
            .btnBlackOutline {
            }
          }
        }
      }
      // Artist section
      .artistRow {
        min-height: 700px;
        padding-bottom: 2rem;
        .contentWrap {
          .inner {
            .btnBlackOutline {
            }
          }
        }
        .imgsWrap {
          .artistImg {
            & img {
            }
          }
          .bottleImg {
            max-height: 400px;
            max-width: 200px;
            left: 2%;
            bottom: 0%;
            & img {
            }
          }
        }
      }
      // Featured section
      .featuredSection {
        padding-top: 175px;
        padding-bottom: 125px;
      }
    }
  }
  // Mobile
  @include media-breakpoint-down(sm) {
    .caskDetailSection {
    }
    .sectionWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //Active Step
      .activeStepRow {
        .activeStep {
          & h3 {
          }
          .btnWhite {
          }
        }
      }
      // Cask Details
      .caskDetailRow {
        padding-top: 0;
        gap: 1.5rem;

        .imgWrap {
          margin-top: 1.75rem;
          max-height: 250px;
          & > div {
          }
        }

        .contentWrap {
          align-items: center;
          padding: 0;
          & * {
            text-align: center;
          }
          .titleWrap {
            order: 1;
            align-items: center;
            padding: 0 1rem;
            .distillery {
              & p {
              }
            }
            & h3 {
            }
            .descriptionWrap {
              .description {
              }
              .fullDescription {
              }
              .more {
              }
            }
          }
          .detailWrap {
            width: 100%;
            order: 3;
            .caskInfoWrap {
              grid-template-columns: repeat(2, 1fr);
              .inner {
                & p {
                }
                .info {
                }
              }
            }
          }

          .priceWrap {
            order: 2;
            background-color: $orange;
            max-width: 100%;
            width: 100%;
            padding: 1.5rem 1rem;
            & * {
              color: $beige !important;
            }
            align-items: center;
            .innerWrap {
              .price {
              }
            }
            .btnCoralOutline {
              width: 100%;
              border-color: $beige;
              max-width: 300px;
            }
          }
        }

        .flavourWrap {
        }
      }
      // How it works
      .howItWorksRow {
        min-height: unset;
        padding: 2rem 0;
        .inner {
          & h3 {
          }
        }
      }
      // How it works cards
      .stepCardsRow {
        gap: 2rem;
        margin-top: 0;
        margin-bottom: 2rem;
        .stepCard {
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & * {
            }
          }
        }
      }

      // Distillery Section
      .distilleryRow {
        gap: 0;
        padding-bottom: 8rem;
        .imgsWrap {
          order: 2;
          .topImg {
            margin-top: 0;
            margin-left: auto;
            max-height: 270px;
            margin-right: -1.1rem;
            max-width: 80%;

            & img {
            }
          }
          .bottomImg {
            left: -1.1rem;
            max-width: 90%;
            max-height: 260px;
            & img {
            }
          }
        }
        .contentWrap {
          padding: 2rem 0rem;
          order: 1;
          .inner {
            align-items: center;
            text-align: center;
            .btnBlackOutline {
            }
          }
        }
      }
      // Artist section
      .artistRow {
        gap: 3rem;
        padding: 3rem 0;
        min-height: unset;
        .contentWrap {
          justify-content: center;
          .inner {
            align-items: center;
            text-align: center;
            .description {
              -webkit-line-clamp: 4;
            }
            .btnBlackOutline {
            }
          }
        }
        .imgsWrap {
          margin-bottom: 1rem;
          .artistImg {
            max-width: 80%;
            max-height: 450px;
            margin-right: auto;
            & img {
            }
          }
          .bottleImg {
            left: unset;
            right: 5%;
            bottom: -3rem;
            & img {
            }
          }

          @media (max-width: 690px) {
            .artistImg {
              max-width: 80%;
              max-height: 350px;
              margin-right: auto;
              & img {
              }
            }
            .bottleImg {
              max-width: 100%;
              max-height: 90%;
              right: 5%;
              bottom: -2rem;
              & img {
              }
            }
          }
          @media (max-width: 450px) {
            .artistImg {
              max-width: 85%;
              max-height: 325px;
              margin-right: auto;
              & img {
              }
            }
            .bottleImg {
              max-width: 100%;
              max-height: 80%;
              min-height: 200px;
              right: 0%;
              bottom: -2rem;
              & img {
              }
            }
          }
        }
      }
      .btnWhite {
        margin: 2rem auto;
        align-self: center;
      }
    }
  }
}
