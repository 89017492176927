$theme-colors: (
  "primary": #e76852,
  "secondary": #003f5a,
  "light": #ffffff,
);

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/variables";
@import "./assets/fonts/stylesheet";
/*@import "../node_modules/react-responsive-carousel/lib/styles/carousel";*/
@import "./styles/carousel";
@import "../node_modules/react-input-range/lib/css/index.css";

@import "./styles/Types";
@import "./styles/General.scss";
@import "./styles/Countdown.scss";

@import "./styles/TopNav";
@import "./styles/Checkout.scss";
@import "./styles/Account.scss";

@import "./styles/Caskshare.scss";
@import "./styles/CaskImage.scss";

@import "./styles/CaskAgeSlider.scss";
@import "./styles/Gifting.scss";

@import "./styles/TrustPages.scss";
@import "./styles/Shop.scss";
@import "./styles/SignatureSeries.scss";
@import "./styles/SellYourCask.scss";

@import "./styles/.v2/Layout.scss";
@import "./styles/.v2/PaymentMollie.scss";

@function get-breakpoints($key: "md") {
  @return map-get($container-max-widths, $key);
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari {
      overflow: hidden;
      & img {
        height: 100%;
      }
    }

    .feature-ad {
      filter: unset !important;
      @include media-breakpoint-down(sm) {
        display: unset !important;
      }
    }
  }
}

a {
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &.inline-text {
    text-decoration: underline;
  }
}

.container {
  padding-bottom: 15px;
  @include media-breakpoint-up(lg) {
    max-width: 990px !important;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1200px !important;
  }
  @include media-breakpoint-down(md) {
    max-width: unset !important;
  }
}

.breadcrumb {
  background: none;
  padding: 0.75rem 0rem;
}

.breadcrumb-item {
  font-family: "Bebas Neue";
  font-weight: bold;
}

.spacer-100 {
  display: block;
  height: 100px;
}

.progress {
  border-radius: 1rem;
  height: 0.65rem;
}

.progress-bar {
  background-color: #003f5a;
}

.side-nav-header {
  color: rgb(197, 197, 197);

  .account-menu-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    .inner {
      padding-left: 1rem;
      width: 80px;
      align-self: center;

      & > div {
        width: 0%;
        height: 2px;
        background: transparent;
        transition: all 0.2s ease-in-out;
      }
      @include media-breakpoint-down(md) {
        & img {
          display: none;
        }
      }
    }

    & img {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
      opacity: 0.5;
    }

    & h6 {
      margin: 0;
      padding-bottom: 0rem;
      white-space: nowrap;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 0rem;
      & img {
        display: none;
      }
    }
  }
  &.active {
    color: black;
    .inner {
      & > div {
        width: 45%;
        height: 2px;
        background: #000;
      }
    }
    & img {
      opacity: 1;
    }
  }
}

.carousel .slide {
  background: white;
  text-align: left;
}

.slick-arrow {
  display: none !important;
}

.dropdown-selector {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  background-position: center right;
  background-repeat: no-repeat;
  text-transform: uppercase;
  padding: 0.65em 2.5em 0.55em 0.75em;
  margin: 0;
  overflow: hidden;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.nav-bar .dropdown-selector {
  font-size: inherit;
  padding: 0;
}

.btn {
  border-radius: 0;
  padding: 0.375rem 1.25rem;
  font-family: GT-Pressura-Extended-Medium, Arial, sans-serif;
  &-light {
    border: 1px solid black;
  }
  &-primary {
    color: black;
  }

  &.big {
    padding: 20px;
  }
}

.no-padding {
  padding: 0;
}

.break-out {
  position: relative;
  left: calc(-1 * (100vw - 100%) / 2);
  width: 100vw;
}

.outside-margin {
  display: inline-block;
  @include media-breakpoint-down(xs) {
    width: calc((100vw - 0px) / 2);
  }
  @include media-breakpoint-down(sm) {
    $container-width: map-get($container-max-widths, "sm");
    width: calc((100vw - #{$container-width}) / 2);
  }
  @include media-breakpoint-down(md) {
    $container-width: map-get($container-max-widths, "md");
    width: calc((100vw - #{$container-width}) / 2);
  }
  @include media-breakpoint-down(lg) {
    $container-width: map-get($container-max-widths, "lg");
    width: calc((100vw - #{$container-width}) / 2);
  }
  @include media-breakpoint-down(xl) {
    $container-width: map-get($container-max-widths, "xl");
    width: calc((100vw - #{$container-width}) / 2);
  }
}

.preserve-new-lines {
  white-space: pre-wrap;
  line-height: 1.4;
  & a {
    text-decoration: underline;
  }
  & span {
    font-weight: bold;
  }
}

.pseudo-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
