@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.shippingRow {
  @import "../../../../styles/Global.scss";
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 580px;

  .contentWrap {
    overflow: hidden;
    height: auto;
    opacity: 1;
    flex: 1;
    transition: all 0.2s ease-out;
    padding: 0.25rem;

    .changeLink {
      flex: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      & p {
        color: $blue;
        font-weight: bolder;
        cursor: pointer;
      }
    }

    .defaultShipping {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .defaultAddress {
        flex: 90%;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .firstLine,
        .secondLine {
          text-transform: uppercase;
        }
      }
      .btnSecondary {
        margin-top: 1rem;
      }
    }
    .errorMessage {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      & p,
      & svg {
        color: $orange;
        font-weight: 400;
      }
      & p {
        margin-left: 0.5rem;
      }
    }
    .selectShipping {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;

      .addressCards {
        max-height: 400px;
        overflow-x: scroll;
      }

      .buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
      }

      .addressTabWrap {
        &:first-child {
          margin-top: 1rem;
        }
        .content {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          padding: 1rem;
          & span {
            font-weight: bolder;
            white-space: nowrap;
            margin-right: 0.5rem;
          }
          & p {
            cursor: pointer;
          }
        }
      }
      .newAddress {
        .content {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          padding: 1rem;
          & p {
            margin-left: 0.5rem;
            cursor: pointer;
          }
        }
      }
      .buttonWrap {
        padding: 1rem;
      }
      .activeTab {
        background-color: #f4f0ec;
      }
    }
    .formWrapper {
      width: 95%;
      margin: auto;
    }

    .defaultBilling {
      margin-top: 2rem;
      & h5 {
        margin-bottom: 1rem;
      }
    }
  }
  .collapse {
    flex: 0;
    opacity: 0;
    transition: all 0.2s ease-in;
  }
}
.noHeight {
  height: unset;
}
