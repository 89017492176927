@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.selectWrap {
  @import "../../../../styles/Global.scss";

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    height: 200px;
    margin: 1rem 0;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .title {
    margin: 0.5rem 0;
    max-width: 450px;
    text-align: center;
  }
  .children {
    & > p {
      margin-top: 0.5rem;
      max-width: 450px;
      text-align: center;
    }
    .qtyPicker {
      display: flex;
      flex-direction: row;
      // gap: 0.5rem;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .btnPrimary {
        display: flex;
        align-items: center;
        border: 0px solid black !important;
        gap: 0.3rem;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        & * {
          border: 0 !important;
          padding: 0rem !important;
        }
      }

      & * {
        border: 1px solid black;
        padding: 0.5rem 1rem;
        background-color: transparent;
        font-weight: bolder;

        &:disabled {
          opacity: 0.3;
        }

        &:focus {
          outline: transparent;
        }
      }

      & button {
        &:first-child {
          border-right: 0px;
        }
        &:last-child {
          border-left: 0px;
        }
      }
    }
    .message {
      text-align: center;
    }
  }
}
