.benefits-overlay {
  background-color: white;

  ul {
    list-style: none;

    li:before {
      content: "\2022";
      color: #e76852;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    li {
      font-family: "Bebas Neue";
      letter-spacing: 0.1rem;
      font-weight: bold;
      font-size: 1.25rem;
    }
  }
}

.text-light {
  & * {
    color: white;
  }
}

.progressing-img-loading {
  filter: blur(10px);
  clip-path: inset(0);
  padding: 1rem;
}
.progressive-img-loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.collective-slider-control {
  .slick-track {
    margin-left: 0;
  }
  .slick-arrow {
    display: initial !important;
    height: 40px;
    width: 40px;
    z-index: 2;
    &::before {
      font-size: 40px;
      opacity: 0.5;
    }
  }
  .slick-next {
    right: -35px;
  }
  .slick-prev {
    left: -45px;
  }
  .slick-disabled {
    display: none !important;
  }
  .slick-dots {
    bottom: -2.25rem;
    & li {
      & button {
        &::before {
          color: white !important;
        }
      }
    }
  }
}

.my-collection-slider-control {
  .slick-dots {
    bottom: -2rem;
    & li {
      & button {
        &::before {
          // color: white !important;
        }
      }
    }
  }
}

.hero-carousel-slider-control {
  .slick-dots {
    bottom: 2rem;
    & li {
      & button {
        &::before {
          color: white !important;
        }
      }
    }
  }
}
.my-collection-slider-control {
  .slick-dots {
    bottom: -2rem;
    & li {
      & button {
        &::before {
          // color: white !important;
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.form-check-label {
  padding-left: 7px;
}

.overflow {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
