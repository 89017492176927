@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.myCollectionSlider {
  @import "../../../../styles/Global.scss";
  width: 100%;
  .myCollectionCard {
    padding-right: 1.5rem;
    padding-top: 0.8rem;
    .imgWrap {
      display: flex;
      & > div {
        max-height: 200px;
      }
    }
    .contentWrap {
      .distillery {
        margin: 1rem 0;
        font-weight: 400;
      }
      .title {
        min-height: 40px;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        & h5 {
          // max-width: 270px;
        }
      }
      .cta {
        .btnPrimary {
          width: 100%;
        }
      }
    }
  }
}
