.modalWrapper {
  @import "../../../../styles/Global.scss";
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: url("../../../../assets/home-page/.v2/hero-carousel/shipping-now.jpg")
    no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    height: min-content !important;
    padding: 2rem;
    border: solid 2px $beige;
    background: $beige;
    .logo {
      & svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .headline {
      margin-top: 2rem;
      margin-bottom: 1rem;
      text-align: center;
    }
    .paragraph {
      margin-bottom: 1rem;
      text-align: center;
    }
    .buttonsWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5rem;
      & button {
        padding: 0.4rem 0.8rem !important;
        &:first-child {
          margin-right: 1rem;
        }
      }
    }
    .error {
      display: none;
      padding-top: 1rem;
      & p {
        text-align: center;
        padding: 0 1rem;
        color: red;
        font-weight: 400;
      }
    }
  }
}
