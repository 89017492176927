@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.generalModalWrap {
  @import "../../../styles/Global.scss";

  .inner {
    position: relative;
    background: white;
    // border: 1px solid black;
    // padding: 2rem;
    margin: 1rem;
    border-radius: 0.5rem;
    font-family: $helvetica;

    .formWrap {
      padding-top: 0.5rem;
      .stepProgress {
        position: absolute;
        font-size: 0.8rem;
        right: -0.5rem;
        top: -0.5rem;
      }
      .closeModal {
        position: absolute;
        cursor: pointer;
        left: 0rem;
        top: -0.5rem;
      }
      .buttonWrap {
        margin-top: 1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
      }
    }
  }
}
