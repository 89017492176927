@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.productCardComponent {
  @import "../../../styles/Global.scss";
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0.5rem;

  .imageWrapper {
    cursor: pointer;
    padding: 1rem;
    max-height: 200px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    height: 100%;

    .bottleTitle {
      cursor: pointer;
      min-height: 55px;
      margin: 1rem 0;
      & h5 {
        // font-family: $helvetica;
        line-height: 1.3;
        @include media-breakpoint-down(sm) {
          font-size: 1.1rem;
        }
      }
    }
    .priceWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;

      & p {
        flex: 1;
        font-size: 1.5rem;
      }
      .collectivePrice {
        color: $orange;
      }
    }

    .collectiveNote {
      display: flex;
      align-items: flex-end;
      margin-top: 0.5rem;
      .innerWrap {
        width: 100%;
        background-color: rgba($color: $black, $alpha: 0.1);
        text-align: center;
        & p {
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
      .collectivePrice {
        color: $white;
        background-color: rgba($color: $orange, $alpha: 1);
        padding: 0.2rem;
        & a {
          color: $white;
        }
      }
      .nonCollective {
        padding: 0.2rem;
      }
    }
  }
}
