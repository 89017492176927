@import "~bootstrap/scss/mixins/_breakpoints";

/*------------------------------------------------------------------------------------------------*/

/* helpful for layout debug */

/*@if 1 {*/
@if null {
  .container {
    border: 1px dotted orange;
  }
  .row {
    border: 1px dotted blue;
  }
  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-8,
  .col-10,
  .col-11,
  .col-12 {
    border: 1px dotted green;
    /*overflow: hidden;*/
  }
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    border: 1px dotted yellow;
  }

  body {
    background-color: #ee8888;
  }
}

body {
  #text {
    position: absolute;
    visibility: hidden;
    display: none;
    height: 0;
    width: 0;
  }
}

.FIXME {
  color: orange;
  font-size: 33px;
  text-align: center;
  width: auto;
  padding: 1rem;
  margin: 1rem;
  border: 2px solid red;
}

/*------------------------------------------------------------------------------------------------*/
/* styles used across the site */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 0.9;
}

p {
  line-height: 1.5;
}

.separator {
  width: 6%;
  height: 6px;
  background-color: black;
  margin: 15px 0;
}

/*------------------------------------------------------------------------------------------------*/
/* Nav */

$max-page-z-index: 15;
$sticky-top-nav-z-index: 20;
$hide-sticky-top-nav-z-index: 25;
$above-hide-sticky-top-nav-z-index: 30;

#root {
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
}

.top-nav-container {
  background-color: transparent;
  width: 100%;
  overflow: visible;
  &.sticky {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: $sticky-top-nav-z-index;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-30px);
    margin-bottom: -30px;
  }
}

.nav-bar {
  z-index: $above-hide-sticky-top-nav-z-index;
  font-size: 1.25rem;
  width: 100%;
  overflow: visible;
  background-color: transparent;
  color: #ece6dd;
  margin-top: 0.5rem;

  padding-top: 9px;
  padding-bottom: 9px;

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }

  a {
    color: inherit;
    &.active {
      text-decoration: underline;
    }
  }
  &.dark a {
    color: #111111;
  }
  &.dark .shop-menu-head {
    color: #111111;
  }
  .caskshare-logo {
    cursor: pointer;
    text-align: center;
    padding: 0;
    margin-top: -3px;
    max-width: 100%;
    @include media-breakpoint-down(xs) {
      max-width: 170px;
    }
  }
  .menu-link {
    font-weight: bold;
    font-size: 1.25rem;
  }
  .small-menu-area {
    display: flex;
    padding: 0.5rem 0 !important;
  }
  .main-menu-area {
    padding-top: 0;
    padding-bottom: 0;
    .socials-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .menu-area {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    .account-and-basket-area {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .checkoutIcon {
        padding: 0.8rem 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }
}
.small-menu-component {
  display: flex;
  &.container {
    padding: 0;
  }
  .shopping-cart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    .checkoutIcon {
      padding: 0.8rem 0.5rem;
    }
  }
}

.showStoreOptions {
  opacity: 1 !important;
  height: 55px !important;
  transform: translateY(30px) !important;
  @include media-breakpoint-down(md) {
    transform: unset;
  }
}
.store-options-component {
  opacity: 0;
  height: 30px;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
  &.container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .store-options {
    color: black;
    z-index: $above-hide-sticky-top-nav-z-index + 1;
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    &.light {
      color: #ece6dd;
    }
    &.in-main-page {
      justify-content: flex-end;
    }
    &.in-mobile-menu {
      color: #ece6dd;
      display: flex !important;
      justify-content: center;
      margin-top: 3rem;
      @include media-breakpoint-down(sm) {
        margin-top: 1.5rem;
      }
    }
    .btn {
      font-size: 0.8rem;
    }
    .btn.btn-secondary {
      color: inherit;
    }
  }
}

.socialWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

/*------------------------------------------------------------------------------------------------*/
/* head */
.breakout-head {
  z-index: $hide-sticky-top-nav-z-index;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  max-width: 100vw;
  min-height: 600px;
  // padding-top: 20vh;
  background-repeat: no-repeat;

  @include media-breakpoint-down(md) {
    min-height: 400px;
    background-position-y: -60px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0.1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .reserve-mature-enjoy {
    position: relative;
    & * {
      color: $white;
    }
    .head-content {
      position: absolute;
      bottom: -5rem;
      left: 0;
      padding: 2rem;
      z-index: 4;
      & h4,
      & p {
        max-width: 260px;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
          max-width: 220px;
        }
      }
      @include media-breakpoint-down(md) {
        bottom: -1rem;
      }
    }
  }
}

.full-height {
  min-height: 101vh;
  padding-top: unset;
}

/*------------------------------------------------------------------------------------------------*/
/* home page */

.home-page-head {
  color: #ece6dd;

  .container {
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }
  }
  .hero-container {
    position: relative;
  }
  .hero-index-wrap {
    margin-bottom: 4rem;
    margin-top: 10rem;

    @include media-breakpoint-down(sm) {
      padding-bottom: 2rem;
    }

    @media (max-width: 340px) {
      margin-top: 6rem;
      margin-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .heading {
    @include media-breakpoint-up(xs) {
      text-align: center;
      font-size: 2rem;
    }
    @include media-breakpoint-up(sm) {
      text-align: left;
      font-size: 3rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 5rem;
    }
    .conditional-break {
      @include media-breakpoint-up(xs) {
        display: inline;
      }
      // @include media-breakpoint-up(xl) {
      //   display: none;
      // }
    }
  }
  .introduction {
    margin-top: 3rem;
    font-size: 1.25rem;
    @include media-breakpoint-up(xs) {
      text-align: center;
      margin-top: 1.5rem;
      font-size: 1rem;
    }
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }
  .hero-buttons-wrap {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & [class*="col-"] {
      padding-left: 0;
      padding-right: 0;
      &:nth-child(2) {
        margin-left: 0.75rem;
      }
    }
    @include media-breakpoint-down(md) {
      margin: 1rem;
    }
  }
  .start-exploring {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 10vh;
    & h5 {
      margin: 0;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 0rem;
      margin-bottom: 1rem;
      .big {
        padding: 20px 5px;
      }
      & a {
        font-size: 1rem;
      }
      .col-7 {
        padding: 0 5px;
      }
    }
  }

  .feature-ad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100vw;
    filter: drop-shadow(0 0 2rem #000);
    position: absolute;
    bottom: 0;

    @include media-breakpoint-down(sm) {
      position: unset;
      .collective {
        order: -1;
      }
    }

    .silver-award-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 200px;
      max-height: 200px;
      height: 100%;
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      z-index: 2;
      // background-color: #ece6dd;
      transition: all 0.3s ease-in-out;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("../assets/home-page/raffle-ticket.png");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 2;
      }
      // @include media-breakpoint-down(md) {
      //   right: -400px;
      // }
      // @media (max-width: 769px) {
      //   right: -300px;
      // }
      // @media (max-width: 420px) {
      //   right: -270px;
      // }
      // @media (max-width: 375px) {
      //   right: -225px;
      // }
      // @media (max-width: 340px) {
      //   right: -180px;
      // }
      .img-wrap {
        max-height: 200px;
        max-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.75rem;
        z-index: 3;
        & img {
          height: 90px;
          width: auto;
        }
      }
      .text-wrap {
        max-width: 300px;
        & p {
          color: black;
          margin: 0 10px 0 5px;
          font-size: 0.8rem;
          & span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .active {
      right: 100px !important;
      .arrow {
        display: none;
      }
    }

    .card {
      position: relative;
      width: 100%;
      max-height: 280px;
      height: 100%;
      border: 0;
      border-radius: 0;
      overflow: hidden;
      cursor: pointer;
      background-color: rgba($color: #000000, $alpha: 1);
      @include media-breakpoint-down(md) {
        max-height: 240px;
      }
      .img {
        height: 100%;
        width: 100%;
        position: relative;
        transition: 0.3s all ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center bottom;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-blend-mode: overlay;
          background-color: rgba($color: #000000, $alpha: 0.6);
          box-shadow: inset 0px 0px 3rem 0px rgba(0, 0, 0, 0.5);
          height: 100%;
          width: 100%;
        }
      }
      .shop {
        & img {
          object-position: center top !important;
        }
      }
      .collective {
        & img {
          object-position: 50% 25% !important;
        }
      }
      .text {
        position: absolute;
        top: 30%;
        left: 5%;

        & i {
          margin-left: 0.5rem;
          color: #e76852;
        }

        & h3 {
          margin-bottom: 1rem;

          @include media-breakpoint-down(md) {
            font-size: 2rem;
          }
        }

        & h5 {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
      .card {
        max-height: 230px;
        min-height: 230px;
        .text {
          top: 35%;
          & h5 {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .promo-banner {
    position: absolute;
    bottom: 100px;
    right: -340px;
    transition: all 0.3s ease-in-out;
    max-height: 150px;
    max-width: 450px;
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    .inner-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 3;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../assets/home-page/raffle-ticket.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .img-wrap {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0;
        z-index: 3;

        & h5 {
          margin: 0;
        }
      }
      .text-wrap {
        flex: 3;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: center;
        z-index: 3;
        cursor: pointer !important;

        & h5 {
          margin-bottom: 0.5rem;
        }

        & a,
        h5 {
          font-weight: bold;
          cursor: pointer !important;
        }

        & * {
          margin: 0;
          padding: 0;
          color: #ece6dd;
        }

        & p,
        h5 {
          margin-left: 0.5rem;
        }
        @media (max-width: 420px) {
          & h5 {
            margin-bottom: 0;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      position: fixed;
      bottom: 100px;
    }
    @include media-breakpoint-down(sm) {
      right: -340px;
      .mobile {
        display: none;
      }
      .inner-wrap {
        .img-wrap {
          justify-content: center;
          & h5 {
            & span {
              display: none;
            }
          }
        }
        .text-wrap {
          flex: 3;
        }
      }
    }
    @media (max-width: 420px) {
      right: -320px;
    }
    @media (max-width: 385px) {
      right: -305px;
      bottom: 5px;
      .inner-wrap {
        .img-wrap {
          & h5 {
            margin-left: -1rem;
          }
        }
      }
    }
    @media (max-width: 360px) {
      right: -290px;
    }
    @media (max-width: 340px) {
      right: -280px;
    }
  }
  .promo-active {
    right: 0 !important;
  }
  .hide-charity-banner {
    opacity: 0;
    animation: hide 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
  }

  @keyframes hide {
    0% {
      opacity: 0.7;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0;
      right: -100vw;
    }
  }
}

// FEATURED CASKS
.featured-casks {
  // padding-top: 3rem;
  position: relative;
  min-height: 180px;
  @include media-breakpoint-down(sm) {
    min-height: 380px;
    .cask-text {
      font-size: 1rem !important;
      top: calc(50% - 0.75rem) !important;
    }
  }

  .left-mask {
    position: absolute;
    top: 0;
    right: 99%;
    bottom: -1px;
    left: -1000px;
    background-color: white;
    border-bottom: 1px solid #dedede;
  }

  .title-area {
    background-color: white;
    /*margin-bottom: -1px;*/

    display: flex;
    flex-direction: column;
    /*align-content: flex-end;*/
    justify-content: center;

    @include media-breakpoint-up(xs) {
      align-items: center;
      padding-top: 2rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: unset;
      border-bottom: 1px solid #dedede;
      align-items: flex-start;
    }

    .title {
      color: rgba(0, 0, 0, 0.1);
      margin: 0;
      @include media-breakpoint-up(xs) {
        font-size: 2rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
  }
  .carousel-area {
    border-bottom: 1px solid #dedede;
    padding-bottom: 1rem;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(xs) {
      margin-top: -1px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0px;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    .carousel {
      .cask-image-component {
        max-width: 9.3rem;
      }
      .cask-info-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          margin: 0;
        }
        .distillery-name {
          text-transform: uppercase;
          font-size: 0.75rem;
        }
        .cask-name {
          margin-top: 0.3rem;
          font-size: 1.125rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .reserve-button-area {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(xs) {
          margin-top: 1rem;
        }
        @include media-breakpoint-up(md) {
          margin-top: 0;
          padding: 0;
        }
        @include media-breakpoint-up(lg) {
          padding: 15px;
        }
        .btn {
          @include media-breakpoint-up(md) {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
          @include media-breakpoint-up(lg) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
          }
        }
      }

      .slider-wrapper {
        @include media-breakpoint-up(xs) {
          padding-bottom: 2rem;
        }
        @include media-breakpoint-up(md) {
          padding-bottom: 0.5rem;
        }
        @include media-breakpoint-up(lg) {
          padding-bottom: 0.3rem;
        }
        .signature-stamp-wrapper {
          display: none;
        }
      }
      .control-dots {
        width: unset;
        right: 0;
        text-align: left;
        @include media-breakpoint-up(xs) {
          left: 42.5%;
          bottom: -1rem;
        }
        @include media-breakpoint-up(sm) {
          /*bottom: 0rem;*/
        }
        @include media-breakpoint-up(md) {
          bottom: -0.5rem;
        }
        @include media-breakpoint-up(lg) {
          left: 34%;
          bottom: -0.6rem;
        }
        .dot {
          box-shadow: none;
          border: 1px solid #aaa;
          &.selected {
            background-color: black;
            border-color: black;
          }
        }
      }
    }
  }
  .browse-caskshares-area {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #003f5a;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .signature-stamp-wrapper {
    display: none;
  }
}

// GIFTING HOME
.gifting {
  margin-top: 70px;
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
  .left {
    display: flex;
    padding-right: 0;
    background-color: #111111;
    .title-area {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      & h5 {
        padding: 0 10px;
        text-align: center;
        color: #896e4b;
        margin: 0;
        font-size: 1.9rem;

        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }

        & a:hover {
          color: #896e4b;
          text-decoration: none;
        }
      }
    }
    .photo-area {
      padding: 0;
      & img {
        max-width: 100%;
      }
    }
  }
  .right {
    display: flex;
    padding: 0;
    background-color: #003f5a;
    background-image: url("../assets/images/woodgrain-lines.svg");
    @include media-breakpoint-down(md) {
      min-height: 200px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 100px;
    }
    .title-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      & h4 {
        padding: 0 25px;
        text-align: center;
        margin: 0;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }
    .see-gifts {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background-color: #f5f2ee;
      @include media-breakpoint-down(sm) {
        & h5 {
          font-size: 1rem;
        }
      }

      .btn {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// Proposition 65 Warning
.us-shipping-warning {
  padding-top: 1rem;
}

// GIFTING INFO
.gifting-info-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 0;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.gifting-wider {
  width: 90vw !important;
  max-width: 1200px;
}

// FEATURED SELLER
.featured-sig {
  // padding-top: 3rem;
  position: relative;

  .left-mask {
    position: absolute;
    top: 0;
    right: 99%;
    bottom: -1px;
    left: -1000px;
    background-color: white;
    border-bottom: 1px solid #dedede;
  }

  .title-area {
    background-color: white;
    /*margin-bottom: -1px;*/

    display: flex;
    flex-direction: column;
    /*align-content: flex-end;*/
    justify-content: center;

    @include media-breakpoint-up(xs) {
      align-items: center;
      padding-top: 2rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: unset;
      border-bottom: 1px solid #dedede;
      align-items: flex-start;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .title {
      color: rgba(0, 0, 0, 0.1);
      margin: 0;
      @include media-breakpoint-up(xs) {
        font-size: 2rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
  }
  .signature-area {
    border-bottom: 1px solid #dedede;
    padding-bottom: 1rem;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;

    & img {
      max-width: 100%;
      max-height: 150px;
      height: 100%;
      border-radius: 50%;
    }

    & h4 {
      margin: 0;
      padding: 0 calc((100% - 85%) / 2);
      @include media-breakpoint-down(md) {
        font-size: 1.55rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
        padding: calc((100% - 85%) / 2);
        text-align: center;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 0px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    @include media-breakpoint-up(xs) {
      margin-top: -1px;
      padding-top: 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  .browse-caskshares-area {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #003f5a;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.unique-timeless-accessible {
  padding-top: 0;

  .image-area-1 {
    order: 0;
    min-height: 100vw;

    @include media-breakpoint-up(sm) {
      min-height: 90vw;
    }

    @include media-breakpoint-up(md) {
      min-height: unset;
      order: 10;
    }

    .pouring-a-glass-div-image {
      position: absolute;

      top: 0;
      left: 0;
      min-height: 100%;
      background-image: none; /* change this */
      background-size: cover;
      background-position: right 15%;
      background-repeat: no-repeat;
      @include media-breakpoint-up(md) {
        height: 120%;
      }

      @include media-breakpoint-up(xs) {
        width: 100%;
      }
      @include media-breakpoint-up(md) {
        width: unset;
        $container-width: map-get($container-max-widths, "md");
        right: calc((100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(lg) {
        $container-width: map-get($container-max-widths, "lg");
        right: calc((100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(xl) {
        $container-width: map-get($container-max-widths, "xl");
        right: calc((100vw - #{$container-width}) * -0.5);
      }
    }
  }

  .explainer-slider-area {
    @include media-breakpoint-up(xs) {
      order: 10;
      padding-top: 4rem;
      padding-bottom: 1rem;
    }
    @include media-breakpoint-up(sm) {
      padding: 0;
      padding-top: 3rem;
      padding-right: 0;
      padding-bottom: 1rem;
      padding-left: 0;
    }
    @include media-breakpoint-up(md) {
      order: 0;
      padding-top: 3rem;
      padding-bottom: 0rem;
      padding-right: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 5rem;
      padding-right: 3rem;
    }
  }

  .image-area-2 {
    order: 20;
    min-height: 200px;
    pointer-events: none;

    .cask-stack-div-image {
      position: absolute;
      width: 70%;
      top: 0;
      right: 0%;
      bottom: 0%;
      min-height: 100%;
      background-image: none; /* change this */
      background-size: cover;
      background-position: left center;
      background-repeat: no-repeat;
      @include media-breakpoint-up(sm) {
        width: 65%;
        right: 0%;
        bottom: -10%;
      }
      @include media-breakpoint-up(md) {
        width: 50%;
        right: 0%;
        bottom: -20%;
      }
      @include media-breakpoint-up(lg) {
        width: 40%;
        right: 0%;
        bottom: -40%;
      }
    }
    .caskshare-stamp-div-image-home {
      position: absolute;
      background: #e76852;
      border-radius: 50%;
      object-fit: cover;
      @include media-breakpoint-up(xs) {
        width: 12.5rem;
        height: 12.5rem;
        /*right: 57%;*/
        top: 5rem;
        left: -1rem;
      }
      @include media-breakpoint-up(sm) {
        width: 14rem;
        height: 14rem;
        top: 4rem;
        $container-width: map-get($container-max-widths, "sm");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(md) {
        width: 16rem;
        height: 16rem;
        top: 3rem;
        $container-width: map-get($container-max-widths, "md");
        left: calc(-2rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(lg) {
        width: 19rem;
        height: 19rem;
        top: 2rem;
        $container-width: map-get($container-max-widths, "lg");
        left: calc(-2.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(xl) {
        width: 23rem;
        height: 23rem;
        top: 1rem;
        $container-width: map-get($container-max-widths, "xl");
        left: calc(-3rem + (100vw - #{$container-width}) * -0.5);
      }
    }
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.separator-1 {
  padding-top: 4rem;
  @include media-breakpoint-up(md) {
    padding-top: 9rem;
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.our-latest-caskshares {
  padding-top: 4rem;

  .heading-area {
    position: relative;

    h2 {
      text-align: center;
      margin: auto;
      @include media-breakpoint-up(xs) {
        width: 100%;
      }
      @include media-breakpoint-up(sm) {
        width: 80%;
      }
    }
    h5 {
      /* margin-top: 1rem;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
        display: inline;
      }*/
      position: absolute;
      right: 0;
      @include media-breakpoint-up(xs) {
        top: 100%;
      }
      @include media-breakpoint-up(sm) {
        top: unset;
        bottom: -3px;
      }
      @include media-breakpoint-up(md) {
        bottom: 3px;
      }
      @include media-breakpoint-down(sm) {
        position: unset;
        margin: 0.5rem auto;
        text-align: center;
      }
    }
  }
  .slider-area {
    margin-top: 1.5rem;
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.separator-2 {
  padding-top: 4rem;
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.this-weeks-choice-backdrop {
  margin-top: 3rem;
  color: #ece6dd;
  position: relative;
  overflow: hidden;

  .this-weeks-choice-images {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include media-breakpoint-up(xs) {
      bottom: 44%;
    }
    @include media-breakpoint-up(md) {
      right: 30%;
    }
    @include media-breakpoint-up(lg) {
      right: 40%;
      bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      right: 36%;
    }

    .this-weeks-choice-div-image {
      position: absolute;
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: none; /* change this */
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include media-breakpoint-up(xs) {
        min-height: 520px;
      }
      @include media-breakpoint-up(sm) {
        min-height: 550px;
      }
    }
  }

  .this-weeks-choice {
    .text-area {
      @include media-breakpoint-up(xs) {
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
      @include media-breakpoint-up(sm) {
        padding-top: 5rem;
        padding-bottom: 4rem;
      }
      @include media-breakpoint-up(md) {
        padding-top: 5rem;
        padding-bottom: 4rem;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 7rem;
        padding-bottom: 6.6rem;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 8rem;
        padding-bottom: 7.1rem;
      }
      h4 {
        font-size: 4rem;
      }
      .dont-miss-out {
        margin-top: 2rem;
      }
    }
    .sign-up-for-new-form-area {
      margin-top: 1rem;
      .form-control {
        background-color: transparent;
        border-color: #ece6dd;
      }
      .input-group-append {
        .btn {
          color: #ece6dd;
          border-color: #ece6dd;
        }
      }
    }
  }
  .featured-cask-product {
    /*border: 2px solid green;*/
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0;
    .featured-cask-product-area {
      position: relative;
      @include media-breakpoint-up(xs) {
        top: 0rem;
        max-width: 25rem;
      }
      @include media-breakpoint-up(sm) {
        top: 0rem;
        max-width: 30rem;
      }
      @include media-breakpoint-up(md) {
        top: 0rem;
      }
      @include media-breakpoint-up(lg) {
        top: 2rem;
      }
      @include media-breakpoint-up(xl) {
        top: 3rem;
      }
    }
  }
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.how-does-caskshare-work-backdrop {
  background-color: #003f5a;
  position: relative;

  .wood-grain-div-image {
    position: absolute;
    background-size: 800px;
    background-position: center center;
    background-repeat: repeat;
    background-image: none; /* change this */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .head-text {
    background-color: white;
    text-align: center;
    padding: 2.5rem 0;
    margin-bottom: 0;
  }

  .how-does-caskshare-work {
    padding-top: 0;
  }

  .images-area-1 {
    padding: 0;
    min-height: 100vmin;
    @include media-breakpoint-up(sm) {
      min-height: 100%;
    }
    @include media-breakpoint-up(lg) {
      order: 10;
      background-color: transparent;
    }

    .caskshare-bottle-div-image {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include media-breakpoint-up(xs) {
        left: 0;
      }
      @include media-breakpoint-up(sm) {
        $container-width: map-get($container-max-widths, "sm");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(md) {
        $container-width: map-get($container-max-widths, "md");
        left: calc(-2rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        $container-width: map-get($container-max-widths, "lg");
        right: calc((100vw - #{$container-width}) * -0.5);
        height: 130%;
      }
      @include media-breakpoint-up(xl) {
        $container-width: map-get($container-max-widths, "xl");
        right: calc((100vw - #{$container-width}) * -0.5);
      }

      .background,
      .foreground {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        background-image: none; /* change this */
      }
    }
  }

  .how-it-works-slider-area {
    background-color: white;
    padding: 2rem 0;
    @include media-breakpoint-up(xs) {
      padding-right: 1rem;
    }
    @include media-breakpoint-up(sm) {
      padding-right: 2rem;
    }
    @include media-breakpoint-up(lg) {
      order: 0;
      padding-right: 4rem;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 5rem;
    }
  }

  .images-area-2 {
    padding: 0;
    background-color: white;
    min-height: 50vmin;
    @include media-breakpoint-up(sm) {
      padding-top: 2rem;
      min-height: 20rem;
    }
    @include media-breakpoint-up(lg) {
      order: -10;
      background-color: transparent;
      padding-top: 0rem;
    }

    .cask-shelf-div-image {
      position: absolute;
      top: 0;
      right: 0;
      /*bottom: 0;*/
      left: 0;
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: none; /* change this */
      min-height: 100%;
      @include media-breakpoint-up(lg) {
        min-height: 80%;
      }
      @include media-breakpoint-up(xs) {
        left: 0;
      }
      @include media-breakpoint-up(sm) {
        $container-width: map-get($container-max-widths, "sm");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(md) {
        $container-width: map-get($container-max-widths, "md");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(lg) {
        $container-width: map-get($container-max-widths, "lg");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
      @include media-breakpoint-up(xl) {
        $container-width: map-get($container-max-widths, "xl");
        left: calc(-1.5rem + (100vw - #{$container-width}) * -0.5);
      }
    }
    .explore-div-image {
      z-index: 1;
      position: absolute;
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: none; /* change this */
      @include media-breakpoint-up(xs) {
        width: 50%;
        height: 90%;
        right: -23%;
        bottom: -20%;
      }
      @include media-breakpoint-up(sm) {
        width: 50%;
        height: 90%;
        right: -23%;
        bottom: -20%;
      }
      @include media-breakpoint-up(md) {
        width: 50%;
        height: 90%;
        right: -23%;
        bottom: -20%;
      }
      @include media-breakpoint-up(lg) {
        width: 76%;
        height: 62%;
        right: -23%;
        bottom: -12%;
      }
      @include media-breakpoint-up(xl) {
        width: 70%;
        height: 70%;
        right: -15%;
        bottom: -20%;
      }
    }
  }
  .right-hand-mask {
    background-color: white;
    @include media-breakpoint-up(lg) {
      order: 20;
    }
  }
}

.separator-3 {
  padding-left: 15px;
  @include media-breakpoint-up(xs) {
    margin-top: 2em;
  }
  @include media-breakpoint-up(sm) {
    padding-left: 0;
    margin-top: 3rem;
  }
  @include media-breakpoint-up(md) {
    margin-top: 4rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 7rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 7rem;
  }
}

.the-worlds-finest-distilleries-home {
  margin-top: 3rem;
  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }

  .images-area {
    margin-top: 2rem;
    height: 14rem;
    z-index: 2;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
    .king-car-cask-end-div-image {
      position: absolute;
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: none; /* change this */
      top: 0;
      right: 50%;
      bottom: 0;
      left: 0;
      height: 12rem;
      @include media-breakpoint-up(sm) {
        height: 18rem;
      }
    }
    .raasay-cask-end-div-image {
      position: absolute;
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      background-image: none; /* change this */
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      height: 12rem;
      @include media-breakpoint-up(sm) {
        height: 18rem;
      }
    }
  }
  .text-area {
    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
  }
  .explore-distilleries-area {
    @include media-breakpoint-up(xs) {
      padding-top: 2rem;
      /*padding-left: 0rem;*/
    }
    @include media-breakpoint-up(sm) {
      padding-top: 0rem;
      padding-left: 3rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 8rem;
      padding-left: 4rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 8rem;
      padding-left: 5rem;
    }
    .btn {
      background: transparent;
    }
    .distillery-list-head {
      margin-top: 2.5rem;
      font-size: 0.75rem;
      text-transform: uppercase;
    }
    .distillery-list-body {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        padding: 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        &:nth-child(3) {
          border-width: 0px;
        }
        p {
          margin: 0px;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.an-unmissable-experience {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #896e4b;
  background: rgb(17, 17, 17);
  flex-direction: column;
  text-align: center;
  padding-bottom: 6rem;
  position: relative;
  overflow: hidden;

  h2 {
    font-size: 2.5rem;
    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
    }
  }

  .separator-4 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      margin-top: 6.5rem;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 9rem;
      margin-bottom: 4rem;
    }

    .separator {
      background-color: #896e4b;
    }
  }
  .show-me-the-caskshares-text {
    margin-bottom: 3rem;
  }
}

.monogram-stamp {
  content: "";
  position: absolute;
  top: 0px;
  right: -150px;
  bottom: -350px;
  left: 0px;
  opacity: 0.05;
  background: rgb(17, 17, 17)
    url("../assets/images/caskshare-monogram-white.svg") bottom right/700px
    no-repeat;
  pointer-events: none;
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* home page bits */
.latest-casks-slider-component {
  padding: 0;
  .slick-list {
    padding: 0 20% 0 0;
  }
  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      border-left: 1px solid rgba(0, 0, 0, 0.2);

      &:first-child {
        border-left-color: white;
      }

      @include media-breakpoint-down(xs) {
        border-left-color: white;
      }

      & > div {
        margin-left: 15px;
        margin-right: 15px;

        @include media-breakpoint-down(sm) {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
      .cask-card-component {
        max-width: 20rem;

        .cask-image-component {
          max-width: 16rem;
        }
      }
      > div {
        height: 100%;
      }

      @include media-breakpoint-down(lg) {
        border: none;
      }
    }
  }
  .slick-dots {
    bottom: -40px;
  }
}

.explainer-slider-component {
  margin: 0;

  .indicator-area {
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: left;

    @include media-breakpoint-up(xs) {
      padding-left: 2rem;
    }
    @include media-breakpoint-up(sm) {
      padding-left: 15px;
    }

    .indicator-item {
      transform-origin: left;
      transition: 1s;
      line-height: 30px;
      cursor: pointer;
      transform: scale(1);
      color: rgb(225, 225, 225);
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.2rem;
      }

      &.active {
        font-size: calc(250%);
        color: black;
      }
    }

    .indicator-bar {
      height: 6px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: 1s;

      &.active {
        /*width: 180px;*/
        /*left: -200px;*/
        width: 1080px;
        left: -1100px;
      }
    }
  }
  .slide-area {
    position: relative;
    .slide {
      /*border: 2px solid yellow;*/
      @include media-breakpoint-up(xs) {
        padding-left: 1rem;
        min-height: calc(410px - 40vw);
      }
      @include media-breakpoint-up(sm) {
        padding-left: 0rem;
        min-height: 250px;
      }
      @include media-breakpoint-up(md) {
        min-height: 260px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 200px;
      }
      @include media-breakpoint-up(xl) {
        min-height: 180px;
      }
    }

    .how-does-caskshare-work-link {
      position: absolute;
      @include media-breakpoint-up(xs) {
        left: 2rem;
        top: 90%;
      }
      @include media-breakpoint-up(sm) {
        left: -13rem;
      }
      @include media-breakpoint-up(md) {
        left: 1rem;
        top: 100%;
      }
      @include media-breakpoint-up(lg) {
        left: 1rem;
        top: 140%;
      }
      @include media-breakpoint-up(xl) {
        left: 1rem;
        top: 144%;
      }

      .down-arrow {
        color: #e76852;
        display: block;
        margin-top: 1.5rem;
      }
      &:hover,
      &:visited {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.how-it-works-slider-component {
  .controls-area {
    .prev-button,
    .next-button {
      cursor: pointer;
      text-align: center;
      i {
        width: 30px;
      }
    }
    .spacer {
      height: 30px;
    }
  }
  .slide-area {
    .slide {
      /*border: 2px solid yellow;*/
      @include media-breakpoint-up(xs) {
        min-height: calc(700px - 75vw);
      }
      @include media-breakpoint-up(sm) {
        min-height: 480px;
      }
      @include media-breakpoint-up(md) {
        min-height: 360px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 410px;
      }
      h2 {
        color: #e76852;
      }
    }
    .steps {
      min-height: 230px;
      h2 {
        color: #e76852;
      }
      & p {
        height: 100%;
        font-size: 1.3rem;
        padding: 1px;
        text-align: justify;
      }
    }
  }
}

.cask-sm-progress-dots {
  bottom: -100px !important;
}

/*------------------------------------------------------------------------------------------------*/
/* caskshares page */

.caskshares-page-head {
  .reserve-mature-enjoy {
    color: #ece6dd;
    h4 {
      // font-size: 4.5rem;
      // line-height: 60px;
      // font-weight: 400;
      text-transform: uppercase;
    }
  }
  .head-search-row {
    @include media-breakpoint-up(xs) {
      padding-top: 0.5rem;
    }
    @include media-breakpoint-up(sm) {
      padding-top: 1rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
    .head-search-area {
      background-color: white;
      padding-top: 2rem;
      padding-left: 2rem;
      z-index: 4;
      input {
        border-radius: 0;
      }
      .input-group > .input-group-append > .input-group-text {
        border-radius: 0;
      }
      @include media-breakpoint-down(md) {
        padding-left: 1rem;
      }
    }
    .switchesWrap {
      background-color: white;
      z-index: 4;
      .switches {
        width: 100%;
        height: 100%;
        & form {
          width: 100%;
          height: 100%;
          margin-top: 0.5rem;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          z-index: 2;
          .custom-control-input {
            z-index: 2;
          }
          & > div:nth-child(2) {
            margin-left: 2rem;
          }
        }
      }
    }
    .head-search-area-whiteout-area {
      position: relative;
      .whiteout {
        position: absolute;
        top: 0;
        right: -100000px;
        bottom: 0;
        left: 0;
        background-color: white;
        width: 3000px;
      }
    }
  }
}

// clear filter button
.filter-area-clear-wrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .cask-count {
    margin-left: 0.5rem;
  }

  & h4 {
    margin-bottom: 0;
  }

  & p {
    margin: 0;
    text-decoration: underline;
    padding-left: 15px;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding-left: 0px;
    }

    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 20px;
      right: 30px;
      padding-left: 15px;
    }
  }
}

.the-casks {
  .the-casks-wrap {
    .mobile-filter-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      // border-top: solid 1px rgba($color: #000000, $alpha: 0.6);
      padding-top: 0.5rem;
      .cask-counter-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        & h4 {
          margin: 0;
        }
        .cask-count {
          margin-left: 0.5rem;
        }
      }
      .btn {
        margin-bottom: 0.5rem;
        border-radius: 25px;
        border-color: rgba($color: #000000, $alpha: 0.3);

        & svg {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .filter-area-sliding,
  .filter-area-fixed {
    transition: opacity 0.2s ease-in;
    opacity: 1;
    width: 100%;
    h4 {
      font-size: 1.875rem;
    }
    @include media-breakpoint-up(xs) {
      padding-top: 0.5rem;
    }
    @include media-breakpoint-up(sm) {
      padding-top: 1rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2.5rem;
    }
  }

  .casks-area {
    background: white;
    overflow: hidden;
    transition: opacity 0.2s ease-in;

    .search-area {
      align-items: center;
    }

    .search-cask-card-item {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 15px;

      @include media-breakpoint-between(xs, sm) {
        border-left: unset;
        &:first-child {
          border-top-color: white;
        }
      }
      @include media-breakpoint-between(sm, md) {
        &:nth-child(odd) {
          border-left-color: white;
        }
        &:nth-child(-n + 2) {
          border-top-color: white;
        }
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(3n + 1) {
          border-left-color: white;
        }
        &:nth-child(-n + 3) {
          border-top-color: white;
        }
      }
    }
    .no-filter {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 15px;

      @include media-breakpoint-up(lg) {
        &:nth-child(3n + 1) {
          border-left-color: rgba(0, 0, 0, 0.2);
        }
        &:nth-child(-n + 3) {
          border-top-color: rgba(0, 0, 0, 0.2);
        }
      }

      @include media-breakpoint-up(lg) {
        &:nth-child(4n + 1) {
          border-left-color: white;
        }
        &:nth-child(-n + 4) {
          border-top-color: white;
        }
      }
    }
  }

  .hide-filters {
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in;
  }

  // .show-all-casks {
  //   opacity: 1;
  //   transition: opacity 0.2s ease-in;
  // }

  @include media-breakpoint-up(sm) {
    padding-top: 1rem;
  }
}

/* styles for SlidingPane which contains the filter, search & sorter */
.casks-page-filter-sliding-pane {
  .search-area {
    margin-top: 4rem;
  }
  .sort-area {
    @include media-breakpoint-up(sm) {
      margin-top: 2rem;
    }
  }
  .one-share-eq-one-bottle-area {
    margin-top: 2rem;
  }
}

.casks-page-filter-sliding-pane-overlay {
  z-index: 30;
}

.slide-pane {
  transition: all 0.2s ease-in-out !important;

  &__content {
    .filter-area-sliding {
      padding-bottom: 60px;
    }
    .sort-wrap {
      .sort-area {
        & div,
        & form {
          display: flex;
          flex-direction: column;
          & span {
            white-space: nowrap;
          }
          .dropdown {
            & button {
              padding-left: 0;
            }
          }
          .custom-switch {
            padding-top: 0.375rem;
            & input {
              z-index: 2;
            }
          }
          @include media-breakpoint-up(sm) {
            flex-direction: row;
            .dropdown {
              & button {
                padding: 0 0.375rem;
              }
            }
            .custom-switch {
              padding: 0 0.375rem;
              margin-left: 2.5rem;
            }
          }
        }
        &:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .mobile-filter-buttons-wrap {
      position: fixed;
      bottom: 0%;
      left: 0;
      background-color: white;
      width: 100%;
      margin: 0;
      .apply-button-area {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 1rem 0;
        & button {
          color: rgba($color: #343a40, $alpha: 0.8);
          border-color: rgba($color: #343a40, $alpha: 0.8);
          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
          }
        }
      }
    }
  }
}

.casks-page-filter-sliding-pane.slide-pane .slide-pane__title-wrapper {
  margin-top: 10px;
}

.casks-page-filter-sliding-pane.slide-pane .slide-pane__title {
  font-size: 2.2rem;
  font-weight: bold;
  padding-top: 2px;
}

.zeroCasksWrap {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @include media-breakpoint-down(xs) {
    margin-top: 1rem;
    margin-bottom: -2rem;
    & h4 {
      font-size: 1.75rem;
    }
  }
}

.caskshare-filter-component {
  cursor: pointer;
  &.active {
    font-weight: bold;
  }
  margin: 0;

  .filter-section {
    margin-top: 1.5rem;
  }

  .filter-title {
    margin: 0 0 0.3rem 0;
    font-weight: lighter;
    font-size: 0.75rem;
  }
  .location-area,
  .distillery-area {
    line-height: 1.56rem;
  }
  .bottling-age-area {
  }

  .location-filter-item,
  .distillery-filter-item,
  .production-method-filter-item,
  .spirit-style-filter-item {
    font-size: 0.875rem;
    margin: 0;
    line-height: 1.575rem;

    &.active {
      font-weight: bold;
    }

    &.zeroCasks {
      opacity: 0.5;
    }

    &.collectiveInactive {
      opacity: 0.5;
    }
  }
}

.age-filter-list {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    font-size: 0.875rem;
    padding: 0.2rem;

    &.active {
      background: black;
      color: white;
    }
  }
}

/* these are to prevent CasksharesSorter looking too much like a button
    when it is clicked */
.btn-secondary {
  color: black;
  background-color: transparent;
  border: none;
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active {
    color: black;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .show > &.dropdown-toggle {
    color: black;
    background-color: transparent;
    border-color: transparent;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* caskshare page */

.caskshare-page {
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include media-breakpoint-down(sm) {
    max-width: 100vw;
  }
  .back-to-browse-caskshares {
    padding-top: 1rem;
    overflow: hidden;
    max-width: 100vw;
    @include media-breakpoint-up(sm) {
      padding-top: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }
    font-size: 1rem;
    & h5 {
      font-size: 1rem;
    }
  }

  .cask-info-area {
    padding-top: 0.5rem;
    @include media-breakpoint-up(sm) {
      padding-top: 0.75rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 1rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 1rem;
    }
    h4 {
      font-size: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 2.5rem;
      }
    }
    .image-area {
      @include media-breakpoint-up(sm) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 20px;
      }
      .cask-image-component {
        max-width: 20rem;
        margin: auto;

        .owned-cask-badge {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .cask-text {
          @include media-breakpoint-down(sm) {
            font-size: 1.8rem;
            top: calc(50% - 1rem);
          }
        }
      }
    }
    .text-area {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      @include media-breakpoint-up(xs) {
        padding-top: 3rem;
        padding-bottom: 0.5rem;
      }
      @include media-breakpoint-up(sm) {
        padding-top: 0rem;
      }
      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-up(lg) {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
      .code-outturn-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & > p {
          flex: 50%;
        }
      }
      .cask-info-title {
        text-transform: none;
        font-weight: 300;
        font-size: 0.75rem;
        color: #111111;
      }
    }
    .flavour-notes-area {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      @include media-breakpoint-up(xs) {
        padding-top: 1rem;
      }
      @include media-breakpoint-up(sm) {
      }
      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-up(lg) {
        padding-top: 0rem;
        padding-left: 3rem;
      }

      .flavour-notes-title {
        text-transform: none;
        font-weight: 300;
        font-size: 0.75rem;
        color: #111111;
      }
      .flavour-notes-text {
      }
      .flavour-map-graphic-area {
        max-width: 18rem;
        padding: 2rem;
        margin: auto;
      }
      .sig-series-profile-link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        & img {
          padding: 2rem;
        }
      }
    }
  }

  .flavour-map-graphic-component {
    position: relative;
    .center-marker {
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background-color: #aaa;
      border-radius: 100%;
      border: 1px solid red;
    }
    .ring-marker {
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background-color: transparent;
      border-radius: 100%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .blob {
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 10px;
      background-color: #aaa;
      border-radius: 100%;
      /*transition-duration: 1s;*/

      &.fruitiness {
        background-color: #e76852;
      }
      &.sweetness {
        background-color: #ece6dd;
      }
      &.spice {
        background-color: #896e4b;
      }
      &.smokiness {
        background-color: #aaaaaa;
      }
      &.complexity {
        background-color: #003f5a;
      }
    }
  }
  .timeline-etc {
    margin-top: 2rem;
    align-items: center;
    .timelineWrap {
      position: relative;
      & sup {
        font-size: 75%;
        position: absolute;
        top: 10px;
        right: 30px;
        cursor: pointer;
      }
      & .custom-tooltip {
        min-width: 500px;
        width: 100%;
        flex-direction: column;
        bottom: unset !important;
        top: -200px;
        right: 0;
        & span {
          display: none !important;
        }
      }
    }
  }

  .price-and-purchase {
    .price-info {
      text-align: center;
      @include media-breakpoint-up(xs) {
        padding-top: 1.5rem;
      }
      @include media-breakpoint-up(sm) {
        padding-top: 0rem;
      }
      @include media-breakpoint-up(md) {
        text-align: left;
        padding-left: 1.2rem;
      }

      .bold {
        font-weight: bold !important;
      }

      .price-per-bottle-title {
        margin: 0;
        font-weight: lighter;
        font-size: 0.75rem;
      }
      .price-per-bottle-text {
        margin-top: 1rem;
        font-size: 1.8rem;
        font-weight: lighter;
      }
      .scored-out-price {
        color: #e76852;
        text-decoration: line-through;
        font-size: 1.2rem;
      }
    }

    .reserve-button-area {
      @include media-breakpoint-up(xs) {
        padding-top: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 0rem;
      }
    }
    .btn-primary {
      background-color: #003f5a;
      border-color: #003f5a;
      color: white;
      font-size: 1.15rem;

      &:active {
        background-color: #e76852;
      }
    }
  }

  .timeline-slider-component {
    padding-top: 1rem;
    .indicator-area {
      padding-left: 3rem;
      position: relative;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-align: left;

      .indicator-item {
        transform-origin: left;
        transition: 1s;
        /*line-height: 38px;*/
        font-weight: bold;
        cursor: pointer;
        transform: scale(1);
        color: rgb(225, 225, 225);
        font-size: 1rem;

        @include media-breakpoint-up(md) {
          font-size: 1.1rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
        }

        &.active {
          transform: scale(1.1);
          color: black;
        }
      }

      .indicator-bar {
        height: 4px;
        width: 0%;
        position: relative;
        left: -1.8rem;
        top: 0.55em;
        background-color: black;
        transition: 1s;
        &.active {
          right: 0;
          width: 20px;
        }
      }
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1rem;
        .indicator-item {
          &:last-child {
            margin-left: 2.5rem;
          }
        }
      }
    }
    .slide {
      /*border: 2px solid yellow;*/
      @include media-breakpoint-up(xs) {
        min-height: 100px;
      }
      @include media-breakpoint-up(sm) {
      }
      @include media-breakpoint-up(md) {
      }
      @include media-breakpoint-up(lg) {
      }
      @include media-breakpoint-up(xl) {
      }
    }
  }
}

.is-member-price-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0;

  & > div {
    width: 50%;
  }

  .price {
    flex: 1;
  }

  .m-price {
    flex: 2;

    & p {
      font-weight: 400;
    }
  }
}

.bold {
  font-weight: bolder !important;
}

.timeline-component {
  width: 100%;
  height: 44px;
  background-size: 88% 100%;
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  font-weight: lighter;

  .distilled,
  .filled,
  .bottled,
  .bottling {
    text-align: left;

    img {
      height: 100%;
      margin-left: -10px;
      fill: chocolate;
    }

    & svg {
      margin-left: -10px;
      width: 40px;
      height: 40px;
      object-fit: contain;
      & path,
      & rect {
        fill: #e76852 !important;
      }
    }

    .title {
      display: block;
      color: #e76852;
      font-weight: 300;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .date {
      font-weight: normal;
    }
  }
  .bottled svg,
  .bottling svg {
    margin-left: -15px;
  }
  .filled {
    min-width: 44px;
  }
  .bottling {
    & svg {
      margin-left: -10px;
      & path,
      & rect {
        fill: black !important;
      }
    }
    .title {
      color: black;
    }
  }
}

.distillery-info {
  margin: 0;
  margin-top: 3.5rem;
  background-color: #111111;
  background-image: url("../assets/images/woodgrain-lines.svg");
  background-size: cover;
  color: white;

  & * {
    color: white;
  }

  .images-area {
    @include media-breakpoint-up(xs) {
      min-height: 280px;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-up(sm) {
      height: 300px;
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(md) {
      height: 320px;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
      height: 350px;
      margin-bottom: 4rem;
    }
    @include media-breakpoint-up(xl) {
      min-height: 400px;
      margin-bottom: 5rem;
    }

    .environ-div-image {
      position: absolute;
      background-image: none; /* change this */
      background-size: cover;
      background-position: right center;
      background-repeat: no-repeat;
      align-self: flex-start;
      right: 0;
      @include media-breakpoint-up(xs) {
        min-height: 280px;
        left: 0;
      }
      @include media-breakpoint-up(sm) {
        height: 300px;
      }
      @include media-breakpoint-up(md) {
        height: 320px;
      }
      @include media-breakpoint-up(lg) {
        height: 350px;
        $container-width: map-get($container-max-widths, "lg");
        left: calc((100vw - #{$container-width}) * -0.2);
      }
      @include media-breakpoint-up(xl) {
        min-height: 400px;
        $container-width: map-get($container-max-widths, "xl");
        left: calc((100vw - #{$container-width}) * -0.2);
      }
    }
    .premises-div-image {
      position: absolute;
      background-image: none; /* change this */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include media-breakpoint-up(xs) {
        width: 40%;
        left: 0;
        bottom: -2rem;
        min-height: 190px;
      }
      @include media-breakpoint-up(sm) {
        width: 35%;
        $container-width: map-get($container-max-widths, "sm");
        left: calc((100vw - #{$container-width}) * -0.02);
        bottom: -2.5rem;
        min-height: 200px;
      }
      @include media-breakpoint-up(md) {
        width: 27%;
        $container-width: map-get($container-max-widths, "md");
        left: calc((100vw - #{$container-width}) * -0.02);
        bottom: -4rem;
        min-height: 190px;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        $container-width: map-get($container-max-widths, "lg");
        left: calc((100vw - #{$container-width}) * -0.02);
        bottom: -5rem;
        min-height: 220px;
      }
      @include media-breakpoint-up(xl) {
        width: 45%;
        $container-width: map-get($container-max-widths, "xl");
        left: calc((100vw - #{$container-width}) * -0.02);
        bottom: -10rem;
        min-height: 270px;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  .distillery-info-area {
    padding-top: 3rem;
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
      padding-left: 2rem;
    }
    .distillery-name-title {
      text-transform: none;
      font-weight: 300;
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.7);
    }
    .distillery-name-text {
    }
    .distillery-description {
      margin-top: 2rem;
      font-size: 0.9rem;
    }
  }
  .more-casks-area {
    @include media-breakpoint-up(xs) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0rem;
    }
    .more-casks-heading {
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }
    .more-casks-title {
      font-size: 1rem;
      opacity: 0.5;
    }
    .more-casks-contents {
      padding-top: 2rem;
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }
    .more-casks-item {
      padding: 10px;
    }
  }
}

.what-happens-next {
  padding-top: 3rem;

  @include media-breakpoint-up(xl) {
    padding-bottom: 3rem;
  }

  .images-area {
    position: relative;
    padding: 0;

    @include media-breakpoint-up(xs) {
      height: 22rem;
    }
    @include media-breakpoint-up(sm) {
      height: 24rem;
    }
    @include media-breakpoint-up(md) {
      height: 26rem;
    }
    @include media-breakpoint-up(lg) {
      height: 24rem;
    }
    @include media-breakpoint-up(xl) {
      height: 25rem;
    }

    .bottom-of-cask-div-image {
      position: absolute;
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-up(xs) {
        left: 0;
        bottom: 0;
        width: 80%;
        max-width: 280px;
        height: 240px;
      }
      @include media-breakpoint-up(sm) {
        height: 260px;
        max-width: unset;
      }
      @include media-breakpoint-up(md) {
        height: 290px;
      }
      @include media-breakpoint-up(lg) {
        bottom: -4rem;
        height: 310px;
      }
      @include media-breakpoint-up(xl) {
        bottom: -5rem;
        height: 350px;
      }
    }
    .top-of-bottle-div-image {
      position: absolute;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(xs) {
        top: 2rem;
        right: 0px;
        width: 54%;
        height: 208px;
      }
      @include media-breakpoint-up(sm) {
        height: 220px;
      }
      @include media-breakpoint-up(md) {
        height: 240px;
      }
      @include media-breakpoint-up(lg) {
        height: 270px;
      }
      @include media-breakpoint-up(xl) {
        width: 58%;
        height: 300px;
      }
    }
    .caskshare-stamp-div-image-cask {
      position: absolute;
      // background: #003f5a;
      // border-radius: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      filter: invert(60%) sepia(82%) saturate(3985%) hue-rotate(333deg)
        brightness(103%) contrast(81%);
      @include media-breakpoint-up(xs) {
        top: 0%;
        right: 50%;
        width: 10rem;
        height: 10rem;
      }
      @include media-breakpoint-up(sm) {
        right: 47%;
        width: 10.5rem;
        height: 10.5rem;
      }
      @include media-breakpoint-up(md) {
        right: 43%;
        width: 12rem;
        height: 12rem;
      }
      @include media-breakpoint-up(lg) {
        right: 40%;
        width: 13rem;
        height: 13rem;
      }
      @include media-breakpoint-up(xl) {
        right: 38%;
        width: 13.5rem;
        height: 13.5rem;
      }
    }
  }
  .text-area {
    @include media-breakpoint-up(xs) {
      padding-top: 3rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 5rem;
      padding-left: 3rem;
    }
    h4 {
      line-height: 1;
      @include media-breakpoint-up(xs) {
        text-align: center;
        font-size: 2.5rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
        font-size: 3.75rem;
        width: 60%;
      }
    }
  }
  .slider-area {
    @include media-breakpoint-up(xs) {
      padding-top: 2rem;
    }
  }
}

.happens-next-slider-component {
  overflow: hidden;
  margin: 0;

  .indicator-area {
    padding-left: 3rem;
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: left;

    .indicator-item {
      transform-origin: left;
      transition: 1s;
      line-height: 30px;
      font-weight: bold;
      cursor: pointer;
      transform: scale(1);
      color: rgb(225, 225, 225);
      @include media-breakpoint-up(xs) {
        font-size: 1.1rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.3rem;
      }
      @include media-breakpoint-up(lg) {
        /*font-size: 2.2rem;*/
      }

      &.active {
        transform: scale(1.4);
        color: black;
      }
    }

    .indicator-bar {
      height: 4px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: 1s;

      &.active {
        right: 0;
        width: 20px;
      }
    }
  }
  .slide-area {
    .slide {
      /*border: 2px solid yellow;*/
      @include media-breakpoint-up(xs) {
        min-height: calc(310px - 40vw);
      }
      @include media-breakpoint-up(sm) {
        min-height: 190px;
      }
      @include media-breakpoint-up(md) {
        min-height: 140px;
      }
      @include media-breakpoint-up(lg) {
        min-height: 200px;
      }
      @include media-breakpoint-up(xl) {
        min-height: 180px;
      }
    }
  }
}

.other-casks {
  @include media-breakpoint-up(xs) {
    margin-top: 2rem;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 2.5rem;
  }
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 3.5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 4rem;
  }
  .section-title {
    text-align: center;
  }

  .other-cask-card-area {
    @include media-breakpoint-up(xs) {
      margin-top: 1rem;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 1rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 1.75rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 2rem;
    }
    .other-cask-card-item {
      border: 1px solid white;

      @include media-breakpoint-between(xs, lg) {
        &:nth-child(even) {
          border-left-color: rgba(0, 0, 0, 0.2);
        }
        &:nth-child(n + 3) {
          border-top-color: rgba(0, 0, 0, 0.2);
        }
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(n + 2) {
          border-left-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .recommended-slider {
    @include media-breakpoint-down(sm) {
      padding: unset;
    }
    & .col {
      @include media-breakpoint-down(sm) {
        padding: unset;
      }
      .you-might-like-card {
        padding: 1rem 1rem 1rem 2rem;
        @include media-breakpoint-up(md) {
          padding: 2rem;
        }
        &-wrapper {
          justify-content: center;
          .card-img-wrap {
            max-height: 200px;
            & > div {
              max-height: 200px;
              height: 100%;
            }
            // .product-image {
            //   height: clamp(200px, 100%, 350px);
            //   & img {
            //     height: 100%;
            //     object-fit: contain;
            //   }
            // }
          }
          & > div {
            flex: 0 0 100%;
            height: auto;
            .row {
              min-height: 3rem;
              text-align: left;
              .cask-title {
                margin-top: 0.5rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .price-wrap {
                & p {
                  margin: 0;
                }
                .title {
                  margin-top: 1rem;
                  font-weight: lighter;
                  font-size: 0.9rem;
                }
                .cask-price {
                  font-size: 1.1rem;
                }
              }
              .collective {
                & p {
                  font-weight: bolder !important;
                }
              }
            }
            .cask-image-component {
              margin-bottom: 0.5rem;
            }
            .price-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .price-wrap {
                padding: 0;
                & small {
                  font-size: 0.8rem;
                  font-weight: 300;
                }
                .collectivePrice {
                  font-weight: 300;
                  font-size: 1.5rem;
                  color: #e76852;
                  background-color: transparent !important;
                }
                .nonCollective {
                  font-weight: 300;
                  font-size: 1.5rem;
                  color: black;
                  background-color: transparent !important;
                }
              }
            }
            .banner-wrap {
              margin-top: 0.5rem;
              min-height: unset;
              .banner {
                width: 100%;
                padding: 0.2rem;
                text-align: center;
                & p,
                & a {
                  font-size: 0.8rem;
                  font-weight: 500;
                  padding: 0.2rem;
                  margin: 0 auto;
                }
              }
              .collectivePrice {
                background-color: rgba($color: #e76852, $alpha: 1);
                & p,
                & a {
                  color: white;
                  padding: 0.2rem;
                  margin: 0 auto;
                  text-align: center;
                }
              }
              .nonCollective {
                background-color: rgba($color: #003f5a, $alpha: 0.2);
                & p,
                & a {
                  color: black;
                  padding: 0.2rem;
                  margin: 0 auto;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
.sign-up-for-new-area {
  padding: 0;
  @include media-breakpoint-up(xs) {
    margin-top: 2rem;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 2.5rem;
  }
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 3.5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 4rem;
  }
  &.container {
    padding-bottom: 0;
  }
}

/*------------------------------------------------------------------------------------------------*/
.sign-up-for-new-component {
  padding-top: 0rem;
  align-items: flex-start;

  @include media-breakpoint-up(xs) {
    margin-top: 2rem;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 2.5rem;
  }
  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 3.5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 4rem;
  }

  .images-container {
    width: 100%;
    position: relative;
    min-height: 300px;

    @include media-breakpoint-up(xs) {
      display: block;
    }
    @include media-breakpoint-up(sm) {
      display: block;
      min-height: 370px;
    }

    .look-out-window-div-image {
      position: absolute;
      background-position: 0 15%;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(xs) {
        left: 0;
        bottom: 0rem;
        right: 25%;
        height: 270px;
        max-width: 330px;
      }
      @include media-breakpoint-up(sm) {
        $container-width: map-get($container-max-widths, "sm");
        left: calc(-15px - (100vw - #{$container-width}) * 0.5);
        right: 20%;
        height: 320px;
        max-width: 440px;
      }
      @include media-breakpoint-up(md) {
        $container-width: map-get($container-max-widths, "md");
        left: calc(-15px - (100vw - #{$container-width}) * 0.5);
        height: 340px;
      }
      @include media-breakpoint-up(lg) {
        $container-width: map-get($container-max-widths, "lg");
        left: calc(-15px - (100vw - #{$container-width}) * 0.5);
        height: 280px;
      }
      @include media-breakpoint-up(xl) {
        $container-width: map-get($container-max-widths, "xl");
        left: calc(-15px - (100vw - #{$container-width}) * 0.5);
        width: 30vw;
        height: 280px;
      }
    }
    .using-phone-div-image {
      position: absolute;
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(xs) {
        left: 50%;
        right: 0;
        top: 0;
        height: 210px;
      }
      @include media-breakpoint-up(sm) {
        $container-width: map-get($container-max-widths, "sm");
        right: calc((100vw - #{$container-width}) * -0.5);
        height: 250px;
      }
      @include media-breakpoint-up(md) {
        right: 0;
        height: 290px;
      }
      @include media-breakpoint-up(lg) {
        left: 45%;
        height: 230px;
      }
      @include media-breakpoint-up(xl) {
        left: 40%;
        height: 250px;
      }
    }
  }

  .signup-for-new-area {
    @include media-breakpoint-up(xs) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-right: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0rem;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 0rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .separator-area {
      display: flex;
      justify-content: center;
      @include media-breakpoint-up(md) {
        justify-content: flex-start;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 3rem;
      }
      .separator {
        @include media-breakpoint-up(md) {
          width: 2rem;
        }
      }
    }

    h3 {
      line-height: 1;
      @include media-breakpoint-up(xs) {
        margin-top: 0.75rem;
        text-align: center;
        font-size: 2.5rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: 0.75rem;
        text-align: left;
        font-size: 3rem;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
        font-size: 3.25rem;
      }
    }
    form {
      margin: 3rem 4px 0 0;
      width: calc(100% - 8px);
    }
  }

  .how-does-it-work-area {
    @include media-breakpoint-up(md) {
      padding-top: 8rem;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 10rem;
    }

    .image-area {
      position: relative;
      min-height: 20rem;

      @include media-breakpoint-up(xs) {
        margin-top: 2rem;
        width: 100%;
        left: 0%;
      }
      @include media-breakpoint-up(md) {
        margin-top: 0rem;
      }

      .pour-glass-div-image {
        position: absolute;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        /*right: 0;*/
        bottom: 0;
        left: 0;
        @include media-breakpoint-up(xs) {
          width: 100%;
        }
        @include media-breakpoint-up(md) {
          width: unset;
          $container-width: map-get($container-max-widths, "md");
          right: calc((100vw - #{$container-width}) * -0.5);
        }
        @include media-breakpoint-up(lg) {
          $container-width: map-get($container-max-widths, "lg");
          right: calc((100vw - #{$container-width}) * -0.5);
        }
        @include media-breakpoint-up(xl) {
          $container-width: map-get($container-max-widths, "xl");
          right: calc((100vw - #{$container-width}) * -0.5);
        }
      }

      .link-area {
        position: absolute;
        left: 13%;
        width: 85%;
        padding-top: 2.5rem;

        h4 {
          font-size: 1.8em;
          color: white;
        }
        .btn {
          margin-top: 1rem;
        }
      }
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
.sign-up-for-new-form-component {
  padding: 0;
  input {
    border-radius: 0;
    font-size: 1rem;
  }
  & button {
    font-size: 1rem;
    font-weight: bolder;
  }
}

/*------------------------------------------------------------------------------------------------*/
/* discover page */

.not-a-club {
  padding-top: 1rem;
  @include media-breakpoint-up(sm) {
    padding-top: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    padding-top: 2rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
  }

  .looking-out-of-window-area {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
    }
  }

  h3 {
    font-size: 2.7rem;
    @include media-breakpoint-up(sm) {
      font-size: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
  }

  .looking-out-of-window-div-image {
    background-image: none; /* change this */
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    align-self: flex-start;

    position: relative;
    left: -15px;
    width: 75%;
    min-height: 300px;

    @include media-breakpoint-up(md) {
      $container-width: map-get($container-max-widths, "md");
      left: calc(-15px - (100vw - #{$container-width}) * 0.5);
      width: 100%;
      min-height: 320px;
    }
    @include media-breakpoint-up(lg) {
      $container-width: map-get($container-max-widths, "lg");
      left: calc(-15px - (100vw - #{$container-width}) * 0.5);
      min-height: 340px;
    }
    @include media-breakpoint-up(xl) {
      $container-width: map-get($container-max-widths, "xl");
      left: calc(-15px - (100vw - #{$container-width}) * 0.5);
      width: 30vw;
      min-height: 390px;
    }
  }

  .rows-of-casks-div-image {
    background-image: none; /* change this */
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;

    right: 0;
    bottom: -2rem;
    width: 50%;
    min-height: 190px;

    @include media-breakpoint-up(sm) {
      right: -10%;
      bottom: -3rem;
      width: 60%;
      min-height: 220px;
    }
    @include media-breakpoint-up(md) {
      right: -10%;
      bottom: -3rem;
      width: 75%;
      min-height: 200px;
    }

    @include media-breakpoint-up(lg) {
      right: -25%;
      bottom: -5rem;
      width: 25vw;
      min-height: 20vw;
      background-size: cover;
    }
  }

  .not-a-club-a-movement-text-area {
    @include media-breakpoint-up(xs) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
  }

  .caskshare-stamp-div-image-discover {
    position: absolute;
    background: #e76852;
    border-radius: 50%;
    @include media-breakpoint-up(xs) {
      right: 5%;
      top: 5%;
      width: 25%;
    }
    @include media-breakpoint-up(md) {
      right: -10%;
      top: 2rem;
      width: 12rem;
    }
    @include media-breakpoint-up(lg) {
      right: -10%;
      top: 4rem;
      width: 16rem;
    }
  }
}

.born-from-a-love {
  .text-area {
    padding-top: 2rem;

    @include media-breakpoint-up(sm) {
      padding-top: 4rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 6rem;
    }
  }
  .cliffs-and-lighthouse-div-image {
    background-image: none; /* change this */
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    align-self: flex-start;
    min-height: 280px;
    @include media-breakpoint-up(sm) {
      min-height: 320px;
    }
    @include media-breakpoint-up(md) {
      min-height: 450px;
      background-position: right center;
    }
  }
}

.the-uncompromised-experience {
  margin-top: 2rem;
  background-color: #f5f2ee;

  .separator {
    background-color: #111111;
    width: 10%;
    height: 4px;
    flex-grow: 0;
    flex-shrink: 0;

    @include media-breakpoint-up(xs) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }
}

.an-investment-with-character {
  padding-top: 1.5rem;

  .dram-glass-area {
    min-height: 270px;
  }
  .dram-glass-div-image {
    position: absolute;
    width: 100%;
    left: 0;
    min-height: 270px;
    background-image: none; /* change this */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: -10%;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      bottom: -16%;
      background-size: cover;
    }
  }
  .heading-area {
    @include media-breakpoint-up(sm) {
      padding-left: 1.5rem;
      padding-top: 3rem;
      h4 {
        font-size: 1.7rem;
      }
    }
    @include media-breakpoint-up(md) {
      padding-left: 2rem;
      h4 {
        font-size: 2.5rem;
      }
    }
  }
  .text-area {
    @include media-breakpoint-up(sm) {
      padding-top: 3rem;
    }
  }
}

.partnering-with-the-finest-breakout {
  background-color: black;
  background-image: none; /* change this */
  position: relative;
  background-size: cover;
  background-position: center;
}

.partnering-with-the-finest {
  background-size: cover;
  color: white;
  @include media-breakpoint-up(md) {
    min-height: 15rem;
  }
  @include media-breakpoint-up(lg) {
    min-height: 30rem;
  }
  .gold-text-area {
    color: #896e4b;
    @include media-breakpoint-up(xs) {
      padding-top: 4.5rem;
      padding-left: 1rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 7.5rem;
      padding-left: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 8.5rem;
      padding-left: 4rem;
    }
    h4 {
      font-size: 2rem;
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
      }
    }
  }

  .para-text-area {
    @include media-breakpoint-up(xs) {
      padding-top: 4rem;
      padding-bottom: 1rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 7rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 8rem;
    }
    .explore-button {
      margin-top: 3rem;
    }
  }

  .view-over-loch-div-mask,
  .view-over-loch-div-image {
    position: absolute;
    left: 0;
    /*right: 0;*/
    top: 0%;
    background-image: none; /* change this */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md) {
      min-height: 420px;
      $container-width: map-get($container-max-widths, "md");
      right: calc((100vw - #{$container-width}) * -0.5);
    }
    @include media-breakpoint-up(lg) {
      min-height: 330px;
      $container-width: map-get($container-max-widths, "lg");
      right: calc((100vw - #{$container-width}) * -0.5);
    }
    @include media-breakpoint-up(xl) {
      min-height: 300px;
      $container-width: map-get($container-max-widths, "xl");
      right: calc((100vw - #{$container-width}) * -0.5);
    }
  }

  .view-over-loch-div-mask {
    background-color: #000000;
    opacity: 0.9;
  }
  .view-over-loch-div-image {
    opacity: 0.4;
  }
  .raasay-distillery-premises-div-image {
    position: absolute;
    background-color: #000000;
    background-image: none; /* change this */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;

    @include media-breakpoint-up(md) {
      width: 95%;
      left: -10%;
      bottom: -10%;
      min-height: 290px;
    }
    @include media-breakpoint-up(lg) {
      width: 75%;
      $container-width: map-get($container-max-widths, "lg");
      right: calc((100vw - #{$container-width}) * -0.5);
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
      $container-width: map-get($container-max-widths, "xl");
      right: calc((100vw - #{$container-width}) * -0.5);
    }
  }
}

.select-mature-enjoy-mature-component {
  margin-top: 2rem;
  width: 100%;
  background-size: 70% 32px;
  background-repeat: no-repeat;
  background-position: center 24px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: lighter;
  padding-bottom: 2rem;

  .icon-and-text-area {
    position: relative;
    text-align: center;
    max-width: 14rem;
    padding: 5px;
    min-width: 60px;

    .left-line-hide,
    .right-line-hide {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 40px;
      background-color: #f5f2ee;
    }

    .icon-image {
      height: 64px;
      width: 64px;
      display: inline-block;
      background-color: #f5f2ee;
      border-radius: 50%;
      padding: 10px;
    }
    .text-area {
      h5 {
        min-width: 55px;
        @include media-breakpoint-up(xs) {
          max-width: 65px;
        }
        @include media-breakpoint-up(md) {
          max-width: 100%;
        }
      }
      p {
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }
}
/*------------------------------------------------------------------------------------------------*/
/* distilleries page */

.the-worlds-finest-distilleries {
  padding-top: 4rem;
}

.a-common-language {
  margin-top: 5rem;

  .distilleries-piles-of-casks-image {
    background-image: none; /* change this */
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    align-self: flex-end;
    @include media-breakpoint-up(xs) {
      min-height: 200px;
    }
    @include media-breakpoint-up(sm) {
      min-height: 280px;
    }
    @include media-breakpoint-up(md) {
      min-height: 350px;
    }
  }
  .distilleries-stills-and-pipes-image {
    position: absolute;
    width: 70%;
    right: -40%;
    bottom: -16%;
    opacity: 1;
    z-index: 10;
  }
  h3 {
  }
}

.join-the-movement {
  color: white;
  background-color: #111111;
  position: relative;
  text-align: center;
  justify-content: center;
  overflow: hidden;

  @include media-breakpoint-up(xs) {
    padding-top: 1em;
    padding-bottom: 0.5em;
  }
  @include media-breakpoint-up(sm) {
    padding-top: 2.5em;
    padding-bottom: 2em;
  }
  @include media-breakpoint-up(md) {
    padding-top: 5em;
    padding-bottom: 3.5em;
  }

  .monogram-image {
    position: absolute;
    width: 50%;
    left: 50%;
    top: 5%;
    opacity: 0.1;
  }

  .separator {
    background-color: #896e4b;
    width: 10%;
    height: 4px;
    @include media-breakpoint-up(xs) {
      margin-bottom: 1rem;
    }
    @include media-breakpoint-up(sm) {
      margin-bottom: 2rem;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }

  .banner-text {
    color: #896e4b;
  }

  a.mail-to-link-looks-like-button {
    -webkit-user-select: none;
    background-color: transparent;
    border-radius: 0;
    border: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    padding: 20px;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    user-select: none;
    vertical-align: middle;
  }
}

.distillery-card {
  padding: 9rem 0 4rem 0;
  cursor: pointer;
  overflow: hidden;
  color: white;
  position: relative;
  background-size: cover;
  background-position: center;
  padding-left: 3rem;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.4);

  .border-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 12px solid white;
  }

  .separator {
    background-color: white;
    margin-left: 0;
    width: 15%;
    height: 4px;
  }
  .distillery-name {
    font-weight: normal;
    width: 80%;
    word-break: break-word;
    color: white;
  }
  .distillery-country {
    font-weight: lighter;
    font-size: normal;
    color: white;
  }
}

/*------------------------------------------------------------------------------------------------*/
/* distillery-page */
.distillery-page {
  &.distillery-page-head {
    padding-bottom: 2rem;
    &:after {
      display: none !important;
    }
    .head-image-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.4;
      background-color: black;
    }
    .distillery-name {
      color: white;
      margin-bottom: 2rem;
    }
    .distillery-strapline {
      /*font-size: 1.125rem;*/
      color: white;
      font-size: 1.05rem;
      line-height: 1.4;
    }
    .discover-link {
      color: white;
    }
  }

  .back-to-browse-distilleries {
    padding-top: 2rem;
    @include media-breakpoint-up(sm) {
      padding-top: 2.5rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 3rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
  }

  .distillery-info-area {
    padding-top: 1rem;
    @include media-breakpoint-up(sm) {
      padding-top: 1.5rem;
    }
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }

    .images-area {
      @include media-breakpoint-up(xs) {
        margin-top: 2rem;
        min-height: 260px;
        margin-bottom: 2rem;
      }
      @include media-breakpoint-up(sm) {
        min-height: 280px;
        margin-bottom: 3rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: 0rem;
        min-height: 350px;
        margin-bottom: 3.5rem;
      }
      @include media-breakpoint-up(lg) {
        min-height: 380px;
        margin-bottom: 4rem;
      }
      @include media-breakpoint-up(lg) {
        min-height: 400px;
        margin-bottom: 4.5rem;
      }

      .distillery-premises-div-image {
        position: absolute;
        background-image: none; /* change this */
        background-size: cover;
        background-position: right top;
        background-repeat: no-repeat;
        right: 0;
        width: 80%;
        height: 90%;
      }
      .distillery-interior-div-image {
        position: absolute;
        background-image: none; /* change this */
        background-size: cover;
        background-position: right top;
        background-repeat: no-repeat;

        @include media-breakpoint-up(xs) {
          width: 50%;
          height: 60%;
          left: 0%;
          bottom: -18%;
        }
        @include media-breakpoint-up(sm) {
          width: 50%;
          height: 60%;
          left: 0%;
          bottom: -18%;
        }
        @include media-breakpoint-up(md) {
          width: 75%;
          height: 50%;
        }
        @include media-breakpoint-up(lg) {
          width: 60%;
          height: 60%;
        }
      }
    }
  }

  .distillery-name-reprise-area {
    margin-top: 2rem;

    .separator {
      background-color: #111111;
      width: 10%;
      height: 4px;
      flex-grow: 0;
      flex-shrink: 0;

      @include media-breakpoint-up(xs) {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
      }
      @include media-breakpoint-up(sm) {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
      }
      @include media-breakpoint-up(md) {
        margin-top: 2.5rem;
        margin-bottom: 3rem;
      }
    }
  }

  .distillery-casks-area {
    max-width: 1156px;
    @include media-breakpoint-up(xs) {
      margin-top: 1rem;
      justify-content: center;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 2rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 3rem;
    }

    .distillery-cask-card {
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 15px;
      max-width: 290px;
      &:nth-child(-n + 3) {
        border-top-color: white;
      }
      &:nth-child(3n + 1) {
        border-left-color: white;
      }
      @include media-breakpoint-down(sm) {
        border: none;
        border-top-color: white;
        margin-bottom: 2rem;
        padding-bottom: 0;
        &:nth-child(3n + 1) {
          border-left-color: white;
        }
        &:nth-child(1n) {
          border-right: 2px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-child(2n) {
          border-right-color: white;
        }
      }
      @include media-breakpoint-down(xs) {
        border: none;
        margin-bottom: 1rem;
        padding-top: 0 !important;
        &:nth-child(1n) {
          border-right: none;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

.distillery-info-slider-component {
  margin: 0;

  .indicator-area {
    position: relative;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: left;

    @include media-breakpoint-up(xs) {
      padding-left: 2rem;
    }
    @include media-breakpoint-up(sm) {
      padding-left: 15px;
    }

    .indicator-item {
      transform-origin: left;
      transition: 1s;
      line-height: 38px;
      font-weight: bold;
      cursor: pointer;
      transform: scale(1);
      color: rgb(225, 225, 225);
      font-size: 2rem;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.9rem;
      }

      &.active {
        transform: scale(1.1);
        color: black;
      }
    }

    .indicator-bar {
      height: 6px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: 1s;

      &.active {
        /*width: 180px;*/
        /*left: -200px;*/
        width: 1080px;
        left: -1100px;
      }
    }
  }
  .slide-area {
    position: relative;
    .slide {
      /*border: 2px dashed green;*/

      @include media-breakpoint-up(xs) {
        padding-left: 1rem;
      }
      @include media-breakpoint-up(sm) {
        padding-left: 1px;
      }
      p {
        font-size: 1rem;
        line-height: 1.5;
      }
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* footer */

.footer-component {
  color: #ece6dd;
  font-weight: bold;
  font-size: 1.25rem;
  padding: 5rem;
  background-color: black;

  .upper-row {
    align-items: center;
    .logo-area {
      order: 1;
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
      }

      svg {
        max-width: 10rem;
      }
    }
    .socials-area {
      order: 3;
      margin-top: 2rem;
      @include media-breakpoint-up(sm) {
        order: 2;
        margin-top: 0rem;
      }
      .socials-component {
        justify-content: center;
        @include media-breakpoint-up(sm) {
          justify-content: flex-end;
        }
        @include media-breakpoint-up(lg) {
          justify-content: center;
        }
      }
    }
    .links-area {
      order: 2;
      @include media-breakpoint-up(sm) {
        order: 3;
      }
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 2rem;
      @include media-breakpoint-up(xs) {
        justify-content: space-evenly;
      }
      @include media-breakpoint-up(sm) {
        justify-content: space-between;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 0rem;
      }
      .footer-link {
        text-align: center;
        flex-shrink: 0;
        flex-basis: auto;
        @include media-breakpoint-up(xs) {
          margin: 0.25rem;
        }
        @include media-breakpoint-up(sm) {
          margin: 0;
        }
      }
    }
  }
  .lower-row {
    margin-top: 2rem;
  }
  .bottom-row {
    margin-top: 2rem;
    font-size: 10px;
    font-weight: 300;
    opacity: 0.8;
    .copyright {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
    .terms-and-conditions {
      margin-top: 2rem;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin-top: 0rem;
        text-align: center;
      }
    }
    .cookies-and-privacy {
      margin-top: 2rem;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin-top: 0rem;
        text-align: right;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer-component {
    padding: 1rem;
  }
}

.payment-logos-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

/*------------------------------------------------------------------------------------------------*/
/* CaskCard / CaskImage */
.cask-card-component {
  overflow: visible;
  color: black;
  background-color: white;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;

  @include media-breakpoint-up(xs) {
    font-size: 1.2rem;
    padding: 5px 0 15px 0;
  }
  @include media-breakpoint-up(sm) {
    /*font-size: 1.1rem;*/
    padding: 10px 0 20px 0;
  }
  @include media-breakpoint-up(md) {
    /*font-size: 1.2rem;*/
    padding: 15px 0 20px 0;
  }

  .content-wrapper {
    padding: 0 1rem;
    flex: 1;
  }

  .cask-title {
    font-size: 133%;
    margin: 0 0 0.4rem 0;
  }
  .second-row {
    margin-top: 0.8rem;
  }

  p {
    margin: 0;
  }

  .flavour-notes-title {
    font-size: 63%;
    margin-top: 1rem;
  }
  .flavour-notes-text {
    margin-top: 0.4rem;
    font-size: 73%;
    font-weight: normal;
  }

  .cask-info {
    margin-top: 1rem;
  }

  .cask-info-title {
    font-size: 63%;
    font-weight: lighter;
  }
  .cask-info-text {
    margin-top: 0.2rem;
    font-size: 73%;
    font-weight: normal;
  }

  .price-info {
    margin-top: 1rem;

    .title-area {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      /*.title {*/
      font-size: 63%;
      font-weight: lighter;
      white-space: nowrap;
      /*}*/
    }
    .value-area {
      margin-top: 0.4rem;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.show-compare-price {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
    .price {
      font-size: 105%;
    }
    .scored-out-price {
      color: #e76852;
      text-decoration: line-through;
      margin-right: 0.7rem;
    }
  }
  .reserve-button-area {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    .btn {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* DistilleryCaskCard */
.distillery-cask-card-component {
  /*border: 2px solid orange;*/

  .bottle-img-wrap {
    max-height: 100px;
    height: 100%;
    & > div {
      max-height: 100px;
      height: 100%;
    }
  }
  .info-area {
    padding-left: 0.3rem;
    .cask-title {
      font-size: 110%;
      padding-top: 0.5rem;
    }
    p {
      margin: 0;
    }
    .distillery-name {
      font-size: 70%;
      padding-top: 0;
    }
    .flavour-notes-text {
      padding-top: 0.3rem;
      font-size: 80%;
    }
  }
  .signature-stamp-wrapper {
    display: none;
  }
}

.dis-link {
  cursor: pointer !important;
  // min-height: 44px;
  &:hover {
    text-decoration: underline !important;
  }
}
/*------------------------------------------------------------------------------------------------*/
/* FeaturedCaskCard */
.featured-cask-card-component {
  /*border: 2px solid orange;*/
  color: #111111;
  background-color: rgb(245, 242, 238);
  display: flex;
  align-items: stretch;
  position: relative;

  @include media-breakpoint-up(xs) {
    width: calc(100% - 1rem);
    padding: 1.5rem;
    min-height: 28rem;
  }
  @include media-breakpoint-up(sm) {
    width: calc(100% - 2rem);
    padding: 2rem;
    min-height: 29rem;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    padding: 2.5rem;
    min-height: 30rem;
  }

  @include media-breakpoint-down(md) {
    & .signature-stamp-wrapper {
      display: none;
    }
  }

  & > .row {
    /*border: 2px solid purple;*/
    /*height: 100%;*/
  }

  .cask-title-area {
    .cask-title {
      @include media-breakpoint-up(xs) {
        font-size: 2.3rem;
      }
      @include media-breakpoint-up(sm) {
        font-size: 2.8rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 3.2rem;
      }
      @include media-breakpoint-up(lg) {
      }
      @include media-breakpoint-up(xl) {
      }
    }
    .cask-code {
      font-weight: bold;
    }
  }
  p {
    margin: 0;
    width: 100%;
    line-height: 1.1;
  }
  .body-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .left-area {
      /*border: 2px solid yellow;*/
      display: flex;
      flex-direction: column;
      align-content: space-between;
      flex-flow: row wrap;

      .distillery-name-text {
      }
      .distillery-area {
        .distillery-name-title {
          font-size: 0.75rem;
          font-weight: lighter;
        }
        .distillery-name-text {
          font-size: 1rem;
          font-weight: normal;
        }
      }
      .flavour-notes-area {
        margin-top: 0.5rem;
        .flavour-notes-title {
          font-size: 0.75rem;
          font-weight: lighter;
        }

        .flavour-notes-text {
          font-size: 1rem;
          font-weight: normal;
        }
      }
      .price-info {
        margin-top: 0.5rem;
        .title-area {
          font-size: 0.75rem;
          font-weight: lighter;
        }
        .value-area {
          font-size: 2rem;
          font-weight: normal;
        }
        .scored-out-price {
          color: #e76852;
          text-decoration: line-through;
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }
      }
      .reserve-button-area {
        margin-top: 1.5rem;
      }
    }
    .right-area {
      /*border: 2px solid green;*/
      width: 1px;
      height: auto;
      overflow: visible;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      .cask-image-area {
        position: absolute;
        bottom: 0;
        left: 0;
        @include media-breakpoint-up(xs) {
          right: -2.5rem;
        }
        @include media-breakpoint-up(sm) {
          right: -5rem;
        }
        @include media-breakpoint-up(md) {
          right: -4rem;
        }
        @include media-breakpoint-up(lg) {
          right: -6rem;
        }
        @include media-breakpoint-up(xl) {
          right: -8rem;
        }
      }
    }
  }
  .caskshare-monogram {
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
    width: 4.5rem;
    height: 4.5rem;
    background-image: none; /* change this */
    background-size: cover;
    background-position: right 15%;
    background-repeat: no-repeat;
  }
}

/*------------------------------------------------------------------------------------------------*/
/* Checkout etc */

.checkout-wrapper {
  .checkout-pay {
    justify-content: flex-end;
    align-items: flex-end;
    .checkout-total {
      display: flex;
      font-weight: 400;
      margin-right: 1.5rem;
      & p {
        margin: 0;
        padding: 0;
        line-height: 1;
      }
      & p:nth-child(2) {
        padding-left: 5px;
      }
    }
    & button {
      display: flex;
      align-items: center;
      & span {
        margin-right: 0.5rem;
        margin-bottom: 0.2rem;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    & [class*="col-md-7"] {
      order: 2;
      margin-top: 50px;
    }
  }
}
.checkout-basket-area {
  margin-top: 2rem;
  & h5 {
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
  .cask-image-component {
    .signature-stamp-wrapper {
      display: none;
    }
  }
}

.checkout-login-area {
  & button {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
  }
}

.caskshare-basket-items-list {
  .cask-image-component {
    .signature-stamp-wrapper {
      display: none;
    }
  }
  .oneGiftPerCheckout {
    position: relative;
    padding: 0.5rem;
    background: rgba($color: red, $alpha: 0.2);
    border: 2px solid rgba($color: red, $alpha: 0.3);
    & span {
      position: absolute;
      top: 0.35rem;
      right: 0.5rem;
    }
    & p {
      font-weight: 300;
      padding-right: 1rem;
    }
  }
}

.number-of-caskshares {
  color: rgb(231, 104, 82);
}

.checkout-total-area {
  padding-top: 2.5rem;
}

.checkout-total-subtext {
  color: $orange;
}

// .checkout-total-title {
//   font-size: 1.25rem;
//   @include media-breakpoint-between(sm, md) {
//     font-size: 1rem;
//     line-height: 2.1;
//   }
// }
.checkout-total-amount {
  // font-size: 2rem;
}

.checkout-totals-inner {
  position: relative;
  .to-basket-wrap {
    .delivery-info {
      padding-left: 0;
      padding-right: 0;
      padding-top: 1rem;
      flex: 2;
      cursor: pointer;
      & p {
        text-decoration: underline;
        margin: 0;
        & i {
          transform: scaleX(-1);
          color: #ee5e4d;
          margin-right: 5px;
        }
      }
      @include media-breakpoint-down(sm) {
        order: 2;
        flex: 2;
        text-align: end;
      }
    }
  }
  .tooltip-p {
    position: relative;
    & sup {
      position: absolute;
      top: 0;
      z-index: 2;
      cursor: pointer;
      font-size: 80%;
      @include media-breakpoint-down(md) {
        right: -1rem;
      }
      @include media-breakpoint-down(sm) {
        right: unset;
      }
    }
  }
}

.checkout-success-component {
  min-height: 70vh;

  .congratulations-area {
    text-align: center;
    padding: 40px;
    h4 {
      font-size: 2rem;
    }
    p {
      margin-top: 2rem;
    }
  }
  & [class*="Checkout_promoCodeArea"] {
    display: none !important;
  }
  .row {
    .checkout-basket-area {
      .checkout-totals {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;
        &-inner {
          min-width: fit-content;
          margin: 0 auto;

          & > .row {
            flex-direction: column;
            padding: 0;
            margin: 0;
            & > div {
              text-align: unset !important;
            }
          }
          .checkout-total-area {
            font-weight: 400;
            // & > div p {
            //   font-size: 1.1rem;
            //   line-height: 1.5;
            // }
          }
          .to-basket-wrap {
            display: none;
          }
        }
        .caskshares-shipping-info {
          display: none;
        }
      }
    }
  }

  .what-happens-next-area {
    background-color: black;
    padding: 1rem;
    height: fit-content;

    @include media-breakpoint-up(xs) {
      margin-top: 3rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 0rem;
    }

    & h4 {
      margin-bottom: 1rem;
      color: $white;
    }
    & p {
      margin-bottom: 1.5rem;
      color: rgba($color: white, $alpha: 0.9);
    }

    ul {
      display: inline;
      padding: 0;
    }

    .collective-banner {
      padding: 1rem;
      margin-top: 1rem;
    }
  }
}

/* CaskShareBasketItem */

.basketItem-member-price {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  padding-left: 1.5rem;

  .member-price {
    & div:nth-child(1) {
      color: red;
      margin-bottom: 0.4rem;
    }
    & p {
      line-height: 1;
      margin: 0;
    }
  }
}

.basket-item {
  .bottle-img-wrap {
    & > div {
      height: 140px;
      & > div {
        background-color: white;
      }
    }
  }
  .number-of-shares-blob {
    width: 25px;
    height: 25px !important;
    border-radius: 12px;
    text-align: center;
    position: absolute;
    top: -5px;
    right: 10px;
    background: #e76852;
    color: white;
  }
  .title-area {
    padding-top: 1rem;
    & h5 {
      margin-bottom: 0.25rem;
    }
    .gifting-checkbox {
      display: flex;
      margin-top: 0.4rem;
      & label {
        margin: 0;
        margin-left: 0.5rem;
      }
      & p {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .delete-from-checkout-button {
    cursor: pointer;
    color: rgb(159, 157, 155);
    width: 30px;
  }

  @include media-breakpoint-up(lg) {
    .mt-4 {
      margin-top: 0 !important;
    }
  }

  .stock-issues {
    padding-top: 15px;
    & p {
      padding: 0 calc((100% - 600px) / 2);
      text-align: center;
    }
  }
}

.quantity-picker-component {
  display: flex;
  flex-wrap: nowrap;

  .button-container {
    text-align: center;
  }
  .btn {
    background: white;
    color: black;
    border: 1px solid black;
    padding: 0;
    height: 44px;
    width: 50px;

    &[disabled] {
      border: 1px solid #bbb;
    }
    .spinner-border {
      display: inline;
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
      border: 2px solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
    }
  }
  .sub-button {
    border-width: 1px 0 1px 1px;
  }
  .add-button {
    border-width: 1px 1px 1px 0;
  }
  .quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    background: white;
    border: 1px solid black;
    border-width: 1px 1px 1px 1px;
    font-weight: bolder;
    height: 44px;
  }
}

/*------------------------------------------------------------------------------------------------*/
/* currency modal */
.currency-modal {
  .explantory-text {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    padding-bottom: 1rem;
  }
}

/*------------------------------------------------------------------------------------------------*/
.CookieConsent {
  background-color: black;
  align-items: baseline;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: unset;
  right: 0;
  bottom: 0px;
  left: 0px;

  .content-area {
    width: 100%;
    padding: 8px 0;
  }

  .cookie-consent-title {
    line-height: 1;
    font-weight: normal;
    @include media-breakpoint-up(xs) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
      padding-top: 0.5rem;
    }
  }
  .cookie-consent-text {
    line-height: 1.3;
    padding-right: 2rem;
    @include media-breakpoint-up(xs) {
      font-size: 0.8rem;
    }
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    & a {
      &:hover {
        color: white;
      }
    }
  }
  .accept-button-area {
    position: absolute;
    top: 8px;
    @include media-breakpoint-up(xs) {
      right: 15px;
    }
    // @include media-breakpoint-up(sm) {
    //   $container-width: map-get($container-max-widths, "sm");
    //   right: calc(15px + (#{$container-width} - 100vw) * -0.5);
    // }
    // @include media-breakpoint-up(md) {
    //   $container-width: map-get($container-max-widths, "md");
    //   right: calc(15px + (#{$container-width} - 100vw) * -0.5);
    // }
    // @include media-breakpoint-up(lg) {
    //   $container-width: map-get($container-max-widths, "lg");
    //   right: calc(15px + (#{$container-width} - 100vw) * -0.5);
    // }
    @include media-breakpoint-up(xl) {
      $container-width: map-get($container-max-widths, "xl");
      right: calc(15px + (#{$container-width} - 100vw) * -0.5);
    }

    .accept-button {
      color: white;
      border-color: white;
      border-width: 1px;
      border-style: solid;
      background-color: transparent;
      @include media-breakpoint-up(xs) {
        right: 1rem;
      }
      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
      }
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* account pages */
.update-your-details-form {
}

.goBack {
  margin-bottom: 1rem;
  & h6 {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.add-address-link {
  & a {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.update-your-password-form {
  margin-bottom: 4rem;

  .password {
    @include media-breakpoint-down(xs) {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      & input {
        margin-top: auto;
      }
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* register page */

.form-label {
  & small {
    @include media-breakpoint-up(md) {
      & br {
        display: none;
      }
    }
  }
}

.custom-tooltip {
  width: fit-content;
  min-width: 210px;
  max-width: 450px;
  position: absolute;
  bottom: 55px;
  left: 0px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  border: 1px solid #ced4da;
  display: flex;
  padding: 0.5rem;
  z-index: 2;

  @include media-breakpoint-down(md) {
    left: 50px;
  }

  @include media-breakpoint-down(sm) {
    bottom: 60px;
    left: 0px;
    max-width: 90vw;
  }

  .tooltip-close {
    padding: 10px 10px 10px 5px;
  }

  & p {
    padding: 10px;
    margin: 0 auto;
    font-weight: 300;
    font-size: 1rem;
  }

  .tooltip-arrow {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 100%;
    left: 20%;
    transform: translateX(-50%);
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      left: 25%;
    }

    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      left: 50%;
      box-shadow: 0 0 16px #0004;
      border: 1px solid #ced4da;
    }
  }
}

/*------------------------------------------------------------------------------------------------*/
/* Toggle Switch */
.toggleSwitch {
  display: flex;
  align-items: center;

  & p {
    margin: 0;
    padding: 0;
    font-weight: bolder;
    font-size: 1.2rem;
  }

  & i {
    margin-left: 1rem;
    cursor: pointer;
    font-size: 1.5rem;
  }

  & span {
    width: 50px;
    height: 25px;
    position: relative;
    border: 2px solid rgba(113, 146, 148, 1);
    border-radius: 50px;
    display: block;
    margin-left: 1rem;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      width: 50%;
      height: 100%;
      background-color: rgba(113, 146, 148, 1);
      border-radius: 50px 0 0 50px;
      transition: all 0.1s ease-in-out;
    }
  }
  .activeToggle {
    border: 2px solid rgba(231, 104, 82, 1);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 24px;
      width: 53%;
      height: 100%;
      background-color: rgba(231, 104, 82, 1);
      border-radius: 0 50px 50px 0;
      transition: all 0.1s ease-in-out;
    }
  }
}

// Adding asterisk to required form fields
// :is(.form-group, input[type="text"][required].form-control) {
//   position: relative;
//   &::after {
//     content: "*";
//     position: absolute;
//     top: 50%;
//     right: 0;
//     color: red;
//   }
// }

// Unable to Ship
.shippingMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  color: #e76852;
  // margin: 1rem 0;
  margin-bottom: 1rem;
  margin-top: -1rem;

  & svg {
    min-width: 1.2rem;
    min-height: 1.2rem;
    margin-top: 0.1rem;
  }

  & span {
    font-weight: bolder;
    margin-left: 0.3rem;
  }

  @include media-breakpoint-down(sm) {
    & svg {
      display: none;
    }
    & span {
      font-size: 0.9rem;
    }
  }
}
