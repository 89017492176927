@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.caskCardComponent {
  @import "../../../styles/Global.scss";
  display: flex;
  flex-direction: column;
  height: 100%;

  .caskImageWrapper {
    padding: 1rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    height: 100%;
    .distilleryName {
      margin-bottom: 1rem;
      font-weight: 400;
    }
    .caskTitle {
      min-height: 2.7rem;
      cursor: pointer;
      & h5 {
        letter-spacing: 0;
        @include media-breakpoint-down(sm) {
          font-size: 1.1rem;
        }
      }
    }
    .flavourNotes {
      margin: 1rem 0;
      min-height: 75px;
      & small {
        font-weight: 300;
      }
      & p {
        font-size: 0.9rem;
      }
    }
    .priceWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .price {
        flex: 1;
        & small {
          font-weight: 300;
        }
        & p {
          font-size: 1.5rem;
        }
      }
      .button {
        flex: 1;
        .btnLight {
          margin-left: auto;
        }
      }
      .collectivePrice {
        color: $orange;
      }
    }
    .collectiveNote {
      display: flex;
      align-items: flex-end;
      margin-top: 0.5rem;
      .innerWrap {
        width: 100%;
        background-color: rgba($color: $blue, $alpha: 0.2);
        text-align: center;
        & p {
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
      .collectivePrice {
        color: $white;
        background-color: rgba($color: $orange, $alpha: 1);
        padding: 0.2rem;
        & a {
          color: $white;
        }
      }
      .nonCollective {
        padding: 0.2rem;
      }
    }
  }
}
