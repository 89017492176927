.cask-image-component {
  max-height: min-content;

  .cask-bottom-area {
    position: relative;
    // width: 85%;
    max-width: 300px;
    height: 150px;
    width: 150px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 150px;

    .clipper {
      /*border: 1px solid purple;*/
      width: 100%;
      min-height: 20px;
      clip-path: circle(50% at 50% 50%);

      .signature-stamp-wrapper {
        display: none;
      }
    }

    .cask-bottom-image {
      width: 100%;
    }

    .cask-mask {
      /*border: 1px solid orange;*/
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .cask-shadow-mask {
      /*border: 1px solid green;*/
      width: 100%;
      position: absolute;
      left: 0;
      top: -1px;
    }
  }

  .cask-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: calc(50%);
    transform: translate(-0px, -5px);
    font-family: Stencilia-A Regular;
    font-size: 1.8rem;
    opacity: 0.9;
    color: #111111;

    @include media-breakpoint-down(sm) {
      font-size: 2.5rem;
      top: calc(50% - 1.5rem);
    }

    &.bottles-reserved {
      top: calc(50% - 32px);
      font-size: 46px;
    }
  }

  .badge-wrapper {
    position: absolute;
    top: -15px;
    left: -10px;
    width: 35%;
    height: 35%;
    z-index: 3;
    overflow: visible;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @include media-breakpoint-down(md) {
      top: -10px;
      left: 0px;
      width: 30%;
      height: 30%;
    }
    @include media-breakpoint-down(sm) {
      top: -10px;
      left: 10px;
      width: 25%;
      height: 25%;
    }
  }

  .distillery-stamp-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #111111;

    .circTxt {
      display: inline-block;
      margin-bottom: 5px;
      color: whitesmoke;
    }

    & path {
      fill: transparent;
    }

    & text {
      font-family: Stencilia-A Regular;
      font-size: 1.3rem;
      fill: currentColor;
      text-anchor: middle;
    }
  }

  .distillery-stamp-bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #111111;

    & path {
      fill: transparent;
    }

    & text {
      font-family: Stencilia-A Regular;
      font-size: 1.3rem;
      fill: currentColor;
      text-anchor: middle;
    }

    // & h6 {
    //   font-family: Stencilia-A Regular;
    //   font-size: 1.2rem;
    //   letter-spacing: unset;
    //   & span {
    //     position: absolute;
    //   }
    // }
  }

  .signature-stamp-wrapper {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    color: #111111;

    & h5 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & span {
        font-family: "Exmouth";
        font-size: 3rem;
        letter-spacing: 0;
        font-weight: 100;
        padding-left: 6px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 1rem;
        & span {
          font-size: 2.5rem;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size: 1.25rem;
        & span {
          font-size: 3rem;
        }
      }
    }
  }

  .fill-spacer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e76751;
    opacity: 0.44;
  }

  .fill-marker {
    position: absolute;
    right: 0;
    left: 0;

    .dashed-line {
      height: 8px;
      border-bottom: 2px dashed white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .cask-stats-spacer {
    position: absolute;
    right: 0;
    left: 0;

    .cask-stats-area {
      height: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      .cask-stats-label {
        // margin-top: 4px;
        display: inline-block;
        background: white;
        border-radius: 50px;
        margin-left: 0px;
        padding: 1px 9px;
        font-size: 0.8rem;
        color: black;
      }

      .cask-bottle-marker {
        height: 14px;
        margin-right: 6px;
        display: inline-block;
        width: inherit;
        position: relative;
        bottom: 1px;
      }
    }
  }
  .bottle-image {
    position: absolute;
    right: -22%;
    bottom: 0px;
    width: 53%;
  }
  .owned-cask-badge {
    position: absolute !important;
    right: -10%;
    top: 0%;
    transform: rotate(0deg);

    font-size: 2rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
      display: none;
    }

    & sup {
      font-size: unset;
      top: unset;
      color: #e76852;
    }

    & .custom-tooltip {
      left: -130px;
      .tooltip-arrow {
        left: 75%;
      }
    }
  }

  .early-bird-banner {
    background-color: #e76852;
    color: white;
    position: absolute;
    left: 0;
    bottom: -4px;
    padding: 4px 4px 2px 4px;

    h6 {
      margin: 0;
    }

    @include media-breakpoint-up(sm) {
      padding: 6px 4px 2px 4px;

      h6 {
        font-size: 1.1rem;
      }
    }
  }
}
.exclusive-cask-image-wrapper {
  @include media-breakpoint-down(lg) {
    .clipper {
      .cask-text {
        font-size: 1rem;
        top: calc(50% - 0.6rem);
      }
      .signature-stamp-wrapper {
        display: none !important;
      }
      .fill-marker,
      .fill-spacer {
        visibility: hidden;
      }
    }
    .cask-stats-spacer {
      visibility: hidden;
    }
  }
  @include media-breakpoint-down(sm) {
    .clipper {
      .cask-text {
        font-size: 1.75rem;
        top: calc(50% - 1rem);
      }
      .signature-stamp-wrapper {
        display: initial !important;
      }
      .fill-marker,
      .fill-spacer {
        visibility: visible;
      }
    }
    .cask-stats-spacer {
      visibility: visible;
    }
  }
  @include media-breakpoint-down(xs) {
    .clipper {
      .signature-stamp-wrapper {
        display: none !important;
      }
    }
  }
}

.account-orders-img-wrap {
  .cask-bottom-area {
    width: 100%;
    max-width: unset;
  }
}

.thumbnail-wrapper,
.ballot-img {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .thumbnail {
    max-width: 1024px;
    max-height: 1024px;
    padding: 0;
    display: flex;
    justify-content: center;

    .cask-bottom-area {
      width: 90%;
      max-width: unset;
    }
    .bottle-image {
      width: 45% !important;
      right: -10% !important;
      height: unset !important;
      bottom: 0%;
    }

    .cask-text {
      font-size: 6rem;
      top: unset;
      bottom: 30%;
      color: #222222;
    }

    .signature-stamp-wrapper {
      top: 30%;
      & h5 {
        font-family: Stencilia-A Regular;
        color: #222222;
        text-transform: uppercase;
        font-size: 4.5rem;
        text-align: center;
      }
    }

    .thumbnail-content-wrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem;
      & > * {
        color: #222;
        z-index: 0;
        font-size: 5rem;
        font-family: Stencilia-A Regular;
        text-transform: uppercase;
        text-align: center;
      }
      .cask-code {
        font-size: 3.5rem;
      }
      .distillery-name {
        margin: 3.5rem 0;
      }
    }
  }
}

.ballot-img {
  margin-top: 2rem;
  .thumbnail {
    padding-bottom: 12%;
    .cask-code {
      font-size: 2rem !important;
    }
    .distillery-name {
      font-size: 3rem !important;
      line-height: 1;
      margin: 1.5rem 0 !important;
    }
    .cask-year {
      font-size: 2rem !important;
    }
    @include media-breakpoint-down(md) {
      padding-bottom: 0%;
      .distillery-name,
      .cask-year {
        font-size: 1.5rem !important;
      }
    }
  }
}
