@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.addOnModalWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: $black, $alpha: 0.95);
  z-index: 2;
  @include media-breakpoint-down(sm) {
    background-color: white;
  }
}

// Desktop
/*------------------------------------------------------------------------------------------------*/
.giftingOuterWrap {
  @import "../../../styles/Global.scss";

  max-width: 100vw;
  overflow: hidden;
  .heroWrap {
    min-height: 500px;
    margin: 0;
    width: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../../assets/gifting-page/samplePack1.jpg") no-repeat center;
    background-size: cover;

    display: flex;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 1200px;
      .header {
        margin-bottom: 1rem;
        & h3 {
          color: $beige;
          letter-spacing: 6px;
          max-width: 510px;
        }
      }
      .note {
        & p {
          max-width: 520px;
          color: rgba($beige, 0.9);
        }
      }
      .links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 2rem;

        .link {
          border-color: transparent;
          color: rgba($beige, 0.9);
          font-weight: 400;
          margin-right: 2rem;
          white-space: nowrap;
          &:last-child {
            margin-right: 0;
          }
          & i {
            color: $orange;
            transform: translateY(0);
            transition: transform 0.15s ease-in;
          }
          &:hover {
            color: $beige;
            & i {
              transform: translateY(5px);
            }
          }
        }
      }
    }
  }

  // Gift a Voucher
  /*------------------------------------------------------------------------------------------------*/
  .voucherWrap {
    height: 600px;
    margin-top: 4rem;
    .imgWrap {
      position: relative;
      height: 100%;
      .top {
        height: 85%;
        width: 80%;
        margin-bottom: auto;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
          position: relative;
        }
      }
      .bottom {
        position: absolute;
        bottom: 0%;
        right: 10%;
        // height: 50%;
        // width: 40%;
        height: 300px;
        width: 300px;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .contentWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .header {
        & h4 {
          max-width: 110px;
          letter-spacing: 2px;
        }
      }
      .content {
        margin-top: 2rem;
        & p {
          max-width: 450px;
        }
        .btnSecondary {
          margin-top: 2rem;
        }
      }
    }
  }

  // Gift a Membership
  /*------------------------------------------------------------------------------------------------*/
  .membershipWrap {
    padding-bottom: 0;
    .headerRow {
      padding-top: 4rem;
      .content {
        & h4 {
          text-align: center;
          letter-spacing: 2px;
        }
        & p {
          text-align: center;
          max-width: 500px;
          margin: 2rem auto;
          margin-bottom: 0;
        }
      }
    }
    .cardsRow {
      padding-top: 4rem;
      .cardWrap {
        display: flex;
        justify-content: flex-end;
        &:first-child {
          .card {
            margin-right: auto !important;
          }
        }
        .card {
          position: relative;
          // max-width: 1100px;
          width: 100%;
          padding: 4rem;

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left bottom;
            z-index: -3;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            z-index: -2;
          }
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 0%;
          //   left: 0;
          //   width: 150%;
          //   height: 150%;
          //   background: url("../../../assets/home-page/.v2/woodgrain-lines.svg")
          //     no-repeat;
          //   opacity: 0.05;
          //   background-size: cover;
          //   z-index: -1;
          // }
          .title {
            margin-bottom: 2rem;
            & h4 {
              color: $white;
              margin-bottom: -0.4rem;
            }
            & small {
              color: rgba($white, 0.9);
            }
          }
          .benefits {
            min-height: 160px;
            margin-bottom: 2rem;
            & p {
              color: $white;
              font-weight: 500;
            }
            .note {
              margin-bottom: 1rem;
            }
            .benefit {
              display: flex;
              align-items: center;
              margin-bottom: 0.5rem;
              & span {
                height: 6px;
                width: 6px;
                border-radius: 50%;
                background-color: $orange;
                margin-right: 0.75rem;
              }
            }
          }
          .price {
            margin-bottom: 2rem;
            & p {
              color: $white;
              font-size: 2.5rem;
              font-weight: 300;
            }
          }
          .btnPrimary {
            width: fit-content;
          }
          .collectiveGiftNotice {
            margin-top: 0.5rem;
            color: $white;
            font-weight: 500;
          }
        }
      }
    }
  }

  // Gift a Caskshare
  /*------------------------------------------------------------------------------------------------*/
  .caskshareRow {
    padding-top: 4rem;
    .contentWrap {
      display: flex;
      flex-direction: row;
      .header {
        & h4 {
          max-width: 180px;
          letter-spacing: 2px;
        }
      }
      .content {
        margin-left: 10%;

        & p {
          max-width: 350px;
          margin-bottom: 2rem;
          text-align: justify;
        }
      }
    }
    .imgWrap {
      position: relative;
      height: 500px;
      @include media-breakpoint-down(md) {
        height: 550px;
      }
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        height: 100%;
        z-index: 2;
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: right;
          z-index: 2;
        }
      }
    }
  }
  // Footer Block
  /*------------------------------------------------------------------------------------------------*/
  .startCollection {
    position: relative;
    height: 600px;
    max-height: 90vh;
    background-color: $beige;
    overflow: hidden;
    padding: 0 1rem;
    margin-top: -70px;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../../../assets/images/share-dots-light.png") no-repeat;
      height: 400px;
      width: 400px;
      background-size: contain;
      opacity: 0.9;
    }
    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .contactDetails {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & a {
          margin-bottom: 0;
          margin-left: 0.5rem;
        }
      }

      & span {
        width: 100px;
        height: 6px;
        background-color: $black;
        margin-bottom: 4rem;
      }

      & > * {
        color: $black;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
      }

      & h2 {
        margin-bottom: 3rem;
      }

      & p,
      & a,
      & h5 {
        max-width: 530px;
        color: $black;
        margin-bottom: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      &::after {
        height: 300px;
        width: 300px;
      }
    }
  }

  // Tablet
  //*------------------------------------------------------------------------------------------------*/

  // Mobile
  //*------------------------------------------------------------------------------------------------*/
  @include media-breakpoint-down(sm) {
    .heroWrap {
      .content {
        .header {
          & h2 {
            max-width: 400px;
          }
        }
      }
    }

    // Gift a Voucher
    /*------------------------------------------------------------------------------------------------*/
    .voucherWrap {
      flex-direction: column;
      margin: 0;
      height: 100%;
      .imgWrap {
        order: 2;
        padding: 0;
        max-height: 300px;
        .top {
          display: none;
        }
        .bottom {
          display: flex;
          position: unset;
          width: 100%;
          height: 100%;
        }
      }
      .contentWrap {
        order: 1;
        padding: 2rem 1rem;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0;
          width: 100%;
          & p {
            margin-top: 2rem;
            text-align: center;
          }
          .btnSecondary {
            margin-top: 1rem;
          }
        }
        .header {
          & h4 {
            margin: 0 auto;
            max-width: 350px;
            text-align: center;
          }
        }
      }
    }

    // Gift a Membership
    /*------------------------------------------------------------------------------------------------*/
    .membershipWrap {
      .headerRow {
        margin-top: 1.5rem;
        padding: 2rem 0;
        .content {
          & p {
            margin: 1rem auto;
          }
        }
      }
      .cardsRow {
        padding-top: 1rem;
        .cardWrap {
          padding: 0;
          .card {
            margin-bottom: 1rem;
            padding: 2rem 1rem;
            &::before {
              background: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
            }
            .benefits {
              & span {
                display: none;
              }
            }
          }
        }
      }
    }

    // Gift a Voucher
    /*------------------------------------------------------------------------------------------------*/
    .caskshareRow {
      padding-top: 2rem;
      .contentWrap {
        flex-direction: column;
        align-items: center;
        margin: 1rem 0;
        order: 2;
        .header {
          margin-top: 1.5rem;
          width: fit-content;
          & h4 {
            max-width: 350px;
          }
        }
        .inner {
          margin-left: 0%;
          margin-top: 1.5rem;
          text-align: center;
          & p {
            text-align: center;
            max-width: 420px;
          }
        }
      }
      .imgWrap {
        order: 1;
        height: 100%;
        min-height: 300px;
        .img {
          width: 100%;
        }
      }
    }
    // Footer Block
    /*------------------------------------------------------------------------------------------------*/
    .startCollection {
      height: 100%;
      min-height: 400px;
      margin-top: 0;
      .inner {
        & span {
          width: 60px;
          height: 4px;
          margin-bottom: 2rem;
        }
        & h2 {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
  // XS
  /*------------------------------------------------------------------------------------------------*/
  @include media-breakpoint-down(xs) {
    .heroWrap {
      background-color: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
      .content {
        .header {
          & h2 {
            max-width: 300px;
            font-size: 2rem;
          }
        }
        .links {
          flex-direction: column;
          .link {
            margin-bottom: 2rem;
            color: $beige;
          }
        }
      }
    }
    // Gift a Membership
    /*------------------------------------------------------------------------------------------------*/
    .membershipWrap {
      .cardsRow {
        padding-top: 1rem;
        // .cardWrap {
        // }
      }
    }
  }
}

// Gift Success
/*------------------------------------------------------------------------------------------------*/
.successWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 30vh);
  text-align: center;
  margin: 0 0.5rem;
  white-space: break-spaces;
  & h4 {
    text-align: center;
  }
}
