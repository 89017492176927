.mollie-wrapper {
  display: flex;
  //margin: 40px auto 0;
  //width: 80%;
}

.mollie-wrapper .mollie-form {
  width: 100%;
}

.mollie-row {
  display: flex;
  width: 100%;
  margin: 1px 0;
}

/* form group */
.mollie-form-group {
  width: 100%;
  position: relative;
  margin: 0 8px;
  flex: 1;
}

.mollie-row .mollie-form-group:first-child {
  margin-left: 0;
}

.mollie-row .mollie-form-group:nth-last-child(1) {
  margin-right: 0;
}

.mollie-form-group--card-number {
  flex: 2 0 calc(50% - 8px);
  max-width: calc(50% - 8px);
}

.mollie-form-group .mollie-label {
  position: absolute;
  top: 0;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #a3b2c6;
  height: 48px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
}

.mollie-wrapper input:not(:placeholder-shown) ~ label,
.mollie-wrapper input:focus ~ label,
.mollie-wrapper .mollie-form-group--expiry-date label,
.mollie-wrapper .mollie-form-group.is-dirty label,
.mollie-wrapper .mollie-form-group.is-focussed label {
  font-size: 12px;
  transform: translateY(-18px);
}

.mollie-form-input {
  outline: none;
  background-color: transparent;
  font-size: 18px;
}

.mollie-form-input,
.mollie-component {
  width: 100%;
  position: relative;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid #677c96;
  font-weight: 500;
  margin-bottom: 16px;
}

.mollie-component::after {
  transition: 0.5s width cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #3a4;
  content: "";
  position: absolute;
  bottom: -1px;
  width: 0%;
  left: 0;
  border-bottom: 1px solid #ce0000;
}

.mollie-component.is-invalid::after {
  width: 100%;
}

.mollie-form-group.is-touched.is-valid .field-error {
  padding: 0;
}

.mollie-form-group.is-touched .field-error {
  color: #ce0000;
  font-weight: 400;
  padding: 1px 0;
  font-size: 10px;
  position: absolute;
  transform: translateY(-16px);
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_css
.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: donate; /* Use any supported button type. */
}
.apple-pay-button-black {
  -apple-pay-button-style: black;
}
.apple-pay-button-white {
  -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
