@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.hideNavbar {
  display: none;
}

.topNavContainer {
  @import "../../../styles/Global.scss";

  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 100;

  @include media-breakpoint-up(md) {
    transition: all 0.3s ease-in-out;
    transform: translateY(-48px);
    margin-bottom: -48px;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .storeCompWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $black;

    .storeOptionsComponent {
      padding: 0;
      margin: 0;
      height: auto;
      z-index: 12;
      padding: 0 1rem;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      order: 2;
      justify-content: flex-end;
      border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);

      transform: translateY(-60px);
      transition: all 0.3s ease-in-out;
      .storeOptionsWrap {
        padding: 0;
        padding: 0.5rem 0;
        width: 100%;
        margin: 0;
        gap: 1rem;

        .phoneNumber {
          flex-direction: row !important;
          & * {
            color: $beige;
            fill: $beige;
          }
          & svg {
            font-size: 0.8rem;
          }
          & p,
          & a {
            margin-left: 0.3rem;
            font-size: 0.8rem;
            font-family: $gtPressuraBody;
          }
          & a {
            &:hover {
              text-decoration: underline;
            }
          }
          margin-right: auto;
        }

        .memberAccount {
          border: 1px solid $beige;
          padding: 0.3rem 1rem;
          &:active,
          &:focus {
            color: $white;
          }
        }
        .collectiveActive {
          border-color: rgba($orange, $alpha: 0.7);
          transition: all ease-in 0.15s;
          text-decoration: none;
          & span {
            color: $orange;
          }
          &:hover {
            border-color: rgba($orange, $alpha: 1);
            text-decoration: none;
          }
        }

        & button,
        & a {
          font-size: 0.8rem;
          font-family: $gtPressuraBody;
          padding: 0;
          color: $beige;
          opacity: 0.9;
          &:hover {
            text-decoration: underline;
          }
        }
        & > a,
        & > button {
          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }

        & div {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          & a {
            color: rgba($color: $black, $alpha: 0.75);
            font-size: 0.9rem;
          }
          & button,
          & a {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .showStoreOptions {
      height: auto;
      transform: translateY(0px);
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .navBar {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #fff;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    &::after {
      background-color: $beige;
      content: "";
      width: 45%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      // @include media-breakpoint-down(sm) {
      //   position: unset;
      // }
      @media (max-width: 820px) {
        position: unset;
      }
    }
    // z-index: -1;

    .smallMenuArea {
      padding: 0;
      .smallMenuComponent {
        padding: 0;
        flex-wrap: nowrap;
        justify-content: center;
        & > div {
          padding-top: 1.25rem;
          padding-bottom: 1rem;
        }
        & div {
          display: flex;
          justify-content: center !important;
          align-items: center;
          flex-wrap: nowrap;
          & svg {
            max-width: 130px;
            max-height: 50px;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .basketWrapper {
          z-index: 2 !important;
          background-color: $beige;
          & a {
            z-index: 2 !important;
            width: 100%;
          }
        }
      }
      // @include media-breakpoint-up(md) {
      //   display: none;
      // }
      @media (min-width: 820px) {
        display: none;
      }
    }

    .mainMenuArea {
      padding: 0 1rem;
      margin: 0;
      .menuArea {
        width: 100%;
        margin: 0;
      }
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
      // @include media-breakpoint-down(sm) {
      //   display: none;
      // }
      @media (max-width: 819px) {
        display: none;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .menuLinks {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    z-index: 2;
    padding: 0 1.5rem;
    background-color: $white;
    z-index: 2;
    gap: 1.5rem;
    & a {
      font-family: $gtPressuraBody;
      font-size: 0.9rem !important;
      font-weight: normal;
      white-space: nowrap;
      &:last-child {
        margin: 0;
      }
      @include media-breakpoint-down(lg) {
        // margin-right: 1rem;
      }
    }
    .shopLink {
      border: 1px solid $orange;
      color: $orange;
      padding: 0.5rem 2rem;
    }
    & * {
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
  }

  .basketCol {
    background-color: $beige;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    padding: 0.5rem 0;
    max-width: 70px;
    margin-left: auto;
    & a {
      text-decoration: none;
    }
  }

  .caskshareLogo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0;
    & > div > svg {
      max-width: 200px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .accountLinks {
    padding-right: 0;
    .wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .joinButton {
        background-color: $blue;
        border: 1px solid $blue;
        padding: 0.375rem 1.25rem;
        font-weight: bolder;
        color: $white;
        font-size: 0.9rem;

        &:focus {
          background-color: $blue;
          border-color: $blue;
          outline: transparent;
        }
      }
      .accountButton {
        padding: 0.375rem 1.25rem;
        font-weight: bolder;
        color: $white;
        font-size: 0.9rem;
        border: 1px solid $orange;
        &:focus {
          background-color: $orange;
          border-color: $orange;
          outline: transparent;
        }
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .dropdownOutterContainer {
    position: relative;
    padding: 0;
    margin: 0;
    height: 0;

    .dropdownContainer {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      max-width: 100vw;

      will-change: auto;
      background-color: #fff;
      transform: translateX(-50%) translateY(-500px);
      transition: all 0.3s ease-in-out;

      z-index: -1;
      .dropdownWrap {
        flex: 100% !important;
        transform: translateY(-200px);
        transition: all 0.3s ease-in-out;
        .dropItem {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          padding-top: 0;
          padding-bottom: 0;
          margin: 3rem 0;
          border-right: 2px solid rgba($color: #000000, $alpha: 0.1);
          &:last-child {
            border-right: none;
          }
          .caskLinks {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            flex: 1;
            padding: 0 1rem;
            & h4 {
              font-weight: 500;
              text-transform: uppercase;
              font-family: "Bebas Neue";
            }
            & p {
              padding: 1rem 0;
            }
            .buttonsWrap {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              .btnLight {
                border-color: transparent;
                & svg {
                  transform: translateX(0.2rem);
                  transition: all 0.2s ease-in;
                }
                &:hover,
                &:focus,
                &:active:focus {
                  border-color: transparent !important;
                  background-color: transparent !important;
                  & svg {
                    transform: translateX(0.5rem);
                  }
                }
              }
            }
          }
          .caskImg {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            & img {
              width: auto;
              max-height: 200px;
              max-width: 100%;
            }
          }
        }
      }
    }
    .openDrop {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
      transform: translateX(-50%) translateY(0px);

      .dropdownWrap {
        transform: translateY(0px);
      }
    }
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .mobileMenu {
    display: none;
    position: fixed;
    background: black;
    color: #ece6dd;
    left: 0;
    top: 0;
    font-size: 1.1rem;
    overflow: scroll;
    .smallMenuComponent {
      padding: 0;
      flex-wrap: nowrap;
      justify-content: center;
      & > * {
        padding-top: 0.8rem;
        padding-bottom: 0.5rem;
      }
      & div {
        display: flex;
        justify-content: center !important;
        align-items: center;
        flex-wrap: nowrap;
        & svg {
          max-width: 130px;
          max-height: 50px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .basketWrapper {
        z-index: 2 !important;
        background-color: $beige;
        & a {
          z-index: 2 !important;
          width: 100%;
        }
      }
    }
    .showStoreOptions {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .storeOptionsWrap {
        margin-top: 2rem;
        & button,
        & a {
          font-size: 0.9rem;
          font-family: $gtPressuraBody;
          font-weight: 300 !important;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      & [class*="dropdown-menu show"] {
        & * {
          color: $black !important;
        }
      }
    }
    // .mobileMenuLinks {
    //   .linksWrap {
    //     list-style: none;
    //     padding: 0;
    //     margin: 0;
    //     & h3 {
    //       color: $beige;
    //       font-family: "Bebas Neue";
    //       font-weight: bold;
    //       text-align: center;
    //       margin: 2rem;
    //     }
    //   }
    //   .showArrow {
    //     font-size: 1.5rem;
    //     opacity: 1;
    //     transform: translateY(0);
    //     transition: all 0.3s ease-in-out;
    //   }
    //   .hideArrow {
    //     display: none;
    //   }
    //   .shopDropdown {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     & a {
    //       font-weight: unset;
    //       font-size: 0.9rem;
    //     }
    //   }
    //   .hideShopDropdown {
    //     height: 0;
    //     transition: all 0.3s ease-in-out;
    //     & a {
    //       opacity: 0;
    //       transform: translateY(50vh);
    //     }
    //   }
    //   .showShopDropdown {
    //     height: auto;
    //     transition: all 0.3s ease-in-out;
    //     margin-bottom: 1rem;
    //     & a {
    //       opacity: 1;
    //       transform: translateY(0vh);
    //     }
    //   }
    // }
    .join {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      flex: unset !important;
    }
    .phoneNumber {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 0.5rem;
      & svg {
        margin-right: 0.5rem;
        font-size: 1rem;
        & * {
          fill: black;
        }
      }
      & p,
      & a {
        font-size: 1rem;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .openMobileMenu {
    animation: fadeIn ease 0.5s;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    background: black;
    min-height: 100vh;
    width: 100%;
    height: calc(100% - 100px);

    .smallMenuComponent {
      background-color: white;

      & > div {
        padding-top: 1.25rem;
        padding-bottom: 1rem;
      }
      & > div > svg {
        max-height: 50px;
        max-width: 130px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .mobileInnerWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      .mobileMenuLinks {
        position: relative;
        min-height: 35%;
        height: min-content;
        padding: 2rem 0;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          width: 60%;
          background-color: white;
        }

        .menuLink {
          color: $beige;
        }
        .shopLink {
          color: $orange;
          height: 40px;
          padding: 0.3rem 2rem;
          border: 2px solid $orange;
        }
      }

      .userSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 40%;
        padding: 2rem 0;
        flex: 1;
        gap: 3rem;

        .storeWrap {
          .storeOptionsComponent {
            display: flex;
            flex-direction: column;
            padding: 0;
            .storeOptionsWrap {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin: 0;
              align-items: center;
              justify-content: center;

              .memberAccount {
                border: 1px solid rgba($beige, 0.9);
                color: rgba($beige, 0.9);
                padding: 0rem 1rem;
                height: 23px;
                &:hover {
                  border: 1px solid rgba($beige, 1);
                  color: rgba($beige, 1);
                }
              }
              .collectiveActive {
                border-color: rgba($orange, 0.9);
                color: rgba($beige, 0.9);
                & span {
                  color: rgba($orange, 0.9);
                }
                &:hover {
                  border-color: $orange;
                  color: beige;
                  & span {
                    color: rgba($orange, 1);
                  }
                }
              }
            }
            & * {
              color: beige;
            }
          }
        }
        .socialWrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          & div:first-child {
            order: 2;
          }

          .phoneNumber {
            margin-top: 0;
            order: 1;
            & * {
              color: $beige;
              fill: $beige;
            }
          }
        }
      }
    }
  }
}

.showStoreOptions {
  @include media-breakpoint-up(sm) {
    transform: translateY(0px);
    margin-bottom: 0px;
  }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.default {
  height: 0;
  left: 0;
  transition: all ease-in 0.2s;
  background-color: rgba($color: #000000, $alpha: 0.8);
  & * {
    display: none;
  }
}

.shopSelectionOverlay {
  @import "../../../styles/Global.scss";
  position: static;
  top: 0;
  left: 0;
  width: 100vw;
  height: fit-content;
  order: 1;
  background-color: rgba($color: #222222, $alpha: 1);
  animation: fadeIn ease 0.5s;
  z-index: 1111;
  padding: 1rem 0;

  .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;

    .text {
      flex: 1;
      font-size: 0.75rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 1rem;
      & p {
        max-width: 450px;
        color: rgba($color: $beige, $alpha: 0.7);
      }
    }

    .dropdown {
      @import "../../../components/app/CurrencyDropdown.module.scss";
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0 1rem;
      & > div {
        max-width: 450px;
        width: 100%;
        & button {
          width: 100%;
          &:first-child {
            border: rgba($beige, $alpha: 0.7) 1px solid !important;
            color: rgba($beige, $alpha: 0.7) !important;

            &:hover,
            &:focus,
            &:active {
              border: rgba($beige, $alpha: 1) 1px solid !important;
              color: rgba($beige, $alpha: 1) !important;
            }
          }
        }
        & > div {
          width: 100%;
          text-align: center;
        }
        & a {
          &:focus,
          &:hover {
            background-color: rgba($black, $alpha: 0.8);
            color: rgba($color: $beige, $alpha: 1);
          }
        }
      }
      .currencyButton {
        background-color: transparent;
        border-color: transparent;
        color: rgba($color: $beige, $alpha: 0.7);
        font-family: $gtPressuraBody;
        font-size: 1rem;

        &:hover,
        &:focus,
        &:active {
          // background-color: rgba($color: $black, $alpha: 0.5) !important;
          background-color: transparent;
          // border-color: rgba($color: $black, $alpha: 0.5) !important;
          border-color: transparent;
          color: rgba($color: $beige, $alpha: 1);
          box-shadow: 0 0 0 0 transparent !important;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .inner {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        text-align: center;
        justify-content: center;
        width: 100%;
        & p {
          max-width: 100%;
        }
      }
      .dropdown {
        width: 100%;
        & > * {
          flex: 1;
        }
      }
    }
  }
}
.addSpace {
  transform: translateY(48px);
  transition: transform 0.3s ease-in;
  @include media-breakpoint-down(md) {
    transform: unset;
  }
}
