@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.addOnSlider {
  @import "../../../styles/Global.scss";
  max-height: 400px;
  .addOnCard {
    padding-right: 1.5rem;
    padding-top: 0.8rem;
    max-width: 300px;
    min-width: 230px;
    .imgWrap {
      display: flex;
      height: 180px;
      & > div {
        max-height: 180px;
      }
    }
    .contentWrap {
      .price {
        margin: 1rem 0;
        font-weight: 400;
      }
      .title {
        min-height: 40px;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .description {
        min-height: 25px;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .cta {
        .btnPrimary {
          width: 100%;
        }
      }
    }
  }
}
