@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.dateWrapper {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 35px;
  &.active {
    font-weight: bold;
    .line {
      position: absolute;
      inset: 17px -150px;
      width: 100px;
      border-top: 2px solid black;
      margin: 0;
    }
  }
  & p {
    font-weight: unset;
    margin-bottom: 0;
    cursor: pointer;
  }
  & small {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
  }
  .foo {
    & .active {
      color: blue;
    }
  }
}
