@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.outerFooterWrap {
  @import "../../../styles/Global.scss";
  background-color: $black;
  margin-top: auto;

  .innerFooterWrap {
    margin: 4rem auto;
    .footerSection {
      .logoArea {
        .logoWrap {
          width: 100%;
          max-width: 150px;
          margin-bottom: 2rem;
          & * {
            fill: $beige;
          }
        }
        .copyRight {
          margin-bottom: 2rem;
          font-size: 0.75rem;
          & p {
            color: rgba($beige, 0.5);
            font-size: 0.75rem;
            max-width: 205px;
          }
        }
      }
      .footerLinks {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 0.9rem;

        .linksWrap {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 1rem 2rem;
          margin-bottom: 2rem;
          flex-wrap: wrap;
          .shopLink {
            color: $orange;
          }
          & * {
            color: rgba($beige, 0.9);
          }
        }

        .readMe {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          font-size: 0.75rem;

          & * {
            color: rgba($beige, 0.5);
            white-space: nowrap;
          }
        }

        .payment {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          margin-top: auto;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      margin: 2rem 0;
      .footerSection {
        gap: 2rem;
        .logoArea {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & > * {
            margin-bottom: 0rem !important;
          }
          .copyRight {
            & p {
              max-width: 340px;
              text-align: center;
              padding: 1rem;
            }
          }
        }
        .footerLinks {
          align-items: center;
          flex-wrap: wrap;

          .linksWrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            text-align: center;
            gap: 1rem;
          }
          .readMe {
            margin-bottom: 2rem;
            flex-wrap: wrap;
            gap: 1rem 2rem;
            justify-content: center;
          }
          .payment {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
