@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.cardWrapper {
  width: auto;
  min-height: 400px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    max-width: 250px;
    text-align: center;
  }

  .imgWrapper {
    width: 200px;
    height: 200px;
    margin-bottom: 40px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 50%;
    }
    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      margin-bottom: 3rem;
    }
  }
  .nameWrapper {
    & h4 {
      font-size: 26px;
    }
  }
  .typeWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .innerWrapper {
      display: flex;
      flex-direction: column;
      // margin-right: 10px;

      & small {
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }
}
