@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.slickWrapper {
  .imgWrap {
    display: flex !important;
    max-height: 500px;
    height: 100%;
    & img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.socialWrap {
  margin-bottom: 10px;
  .socialLink small:last-of-type {
    padding-left: 20px;
  }
}
