@import "./mixin.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

$orange: #e76852;
$beige: #ece6dd;
$blue: #003f5a;
$gold: #ffb500;
$lightCoral: #fead77;
$black: #000;
$white: #fff;
$helvetica: Helvetica Neue, Helvetica, Arial, sans-serif;
$gtPressuraHeading: GT-Pressura-Extended-Medium, Arial, sans-serif;
$domaineHeading: DomaineText-Regular, GT-Pressura-Extended-Medium;
$gtPressuraBody: GT-Pressura-Extended-Text, Arial, sans-serif;
$bebas: Bebas Neue, Bebas, Arial, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: $gtPressuraHeading;
  text-transform: uppercase;
  letter-spacing: 0 !important;
  line-height: 1;
}

a,
p {
  margin: 0;
  padding: 0;
  color: $black;
}

$min_width: 320px;
$max_width: 1200px;
$min_font: 0.7rem;
$max_font: 1rem;

a,
p,
small {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  // font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-family: $gtPressuraBody;
  letter-spacing: 0px;
}

// h4 {
//   font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
// }

.btnPrimary {
  padding: 0.7rem 1.25rem;
  color: $white;
  font-size: 1rem;
  border: 1px solid $orange;
  background-color: rgba($orange, 0.9) !important;
  font-family: $gtPressuraHeading;
  white-space: nowrap;
  &:hover {
    background-color: rgba($orange, 1) !important;
  }
  &:focus {
    background-color: $orange;
    border-color: $orange;
    outline: transparent;
  }
}
.btnSecondary {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // width: fit-content;
  background-color: rgba($blue, 0.9) !important;
  border: 1px solid $blue;
  padding: 0.7rem 1.25rem;
  font-weight: 500;
  color: $white !important;
  font-size: 1rem;
  font-family: $gtPressuraHeading;
  white-space: nowrap;
  &:hover {
    background-color: rgba($blue, 1) !important;
  }
  &:focus {
    background-color: $blue;
    border-color: $blue;
    outline: transparent;
    box-shadow: 0 0 0 0.2rem rgba($blue, 0.5);
  }
  &:active {
    border-color: $blue !important;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
    }
  }
}
.btnDark {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // width: fit-content;
  background-color: rgba($black, 0.9) !important;
  border: 1px solid $black;
  padding: 0.7rem 1.25rem;
  font-weight: 500;
  color: $beige !important;
  font-size: 1rem;
  font-family: $gtPressuraHeading;
  white-space: nowrap;
  &:hover {
    background-color: rgba($black, 1) !important;
  }
  &:focus {
    background-color: $black;
    border-color: $black;
    outline: transparent;
    box-shadow: 0 0 0 0.2rem rgba($black, 0.5) !important;
  }
  &:active {
    border-color: rgba($black, 0.8) !important;
  }
}
.btnLight {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent !important;
  border: 1px solid rgba($black, 0.8);
  padding: 0.7rem 1.25rem;
  font-weight: 500;
  color: rgba($black, 0.8) !important;
  font-size: 1rem;
  font-family: $gtPressuraHeading;
  white-space: nowrap;
  &:hover {
    color: rgba($black, 1) !important;
    background-color: rgba($black, 0.1) !important;
    border: 1px solid rgba($black, 1) !important;
  }
  &:focus,
  &:active:focus {
    background-color: transparent !important;
    color: $black !important;
    border-color: $black !important;
    outline-color: $black !important;
    box-shadow: none !important;
  }
}

.btnWhite {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($black, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($white, 0);
  background-color: rgba($white, 0.9);
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover {
    color: $black;
    border-color: $white;
    background-color: $white;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $black !important;
    border-color: $white !important;
    background-color: $white !important;
    outline: rgba($white, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($white, 0.5) !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btnCoralOutline {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($orange, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($orange, 0.7);
  background-color: transparent !important;
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover:not([disabled]) {
    color: $orange;
    border-color: $orange;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $orange !important;
    border-color: $orange !important;
    outline: rgba($orange, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($orange, 0.5) !important;
  }
  &:disabled {
    color: rgba($orange, 0.8);
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btnLightCoralOutline {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($lightCoral, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($lightCoral, 0.7);
  background-color: transparent !important;
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover {
    color: $lightCoral;
    border-color: $lightCoral;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $lightCoral !important;
    border-color: $lightCoral !important;
    outline: rgba($lightCoral, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($lightCoral, 0.5) !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btnWhiteOutline {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($white, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($white, 0.7);
  background-color: transparent !important;
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover {
    color: $white;
    border-color: $white;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $white !important;
    border-color: $white !important;
    outline: rgba($white, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($white, 0.5) !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btnBeigeOutline {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($beige, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($beige, 0.7);
  background-color: transparent !important;
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover {
    color: $beige;
    border-color: $beige;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $beige !important;
    border-color: $beige !important;
    outline: rgba($beige, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($beige, 0.5) !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btnBlackOutline {
  padding: 0rem 2rem;
  height: 40px;
  color: rgba($black, 0.8);
  font-size: 1rem;
  border: 2px solid rgba($black, 0.7);
  background-color: transparent !important;
  font-family: $gtPressuraBody;
  white-space: nowrap;
  &:hover {
    color: $black;
    border-color: $black;
  }
  &:focus,
  &:active:focus,
  &:active {
    color: $black !important;
    border-color: $black !important;
    outline: rgba($black, 0.1) !important;
    box-shadow: 0 0 0 0.1rem rgba($black, 0.5) !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}
