@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.signUpForNewComponent {
  @media screen and (min-device-width: 1600px) {
    max-width: 1600px;
    padding: 0;
    padding-top: 50px;
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0px calc((100% - 1600px) / 2);
  padding-top: 50px;
  margin: 0 auto;
  min-height: 450px;
  & > div {
    padding: 0;
  }
  & h3 {
    @include media-breakpoint-down(md) {
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    margin: 0;
  }
  .imageContainer {
    position: relative;
    width: 100%;
    height: 80%;
    overflow: hidden;
    justify-self: center;
    min-height: 400px;
    .usingPhoneDivImage {
      position: absolute;
      top: 0;
      right: 5%;
      z-index: 1;
      max-height: 280px;
      max-width: 240px;
      height: 100%;
      width: 100%;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
      @include media-breakpoint-down(md) {
        right: 0;
        max-height: 330px;
        max-width: 300px;
      }
      @include media-breakpoint-down(sm) {
        max-height: 280px;
        max-width: 240px;
      }
    }
    .lookOutWindowDivImage {
      position: absolute;
      max-height: 100%;
      width: 80%;
      top: 33%;
      right: 20%;
      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .signUpForNewArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px calc((100% - 350px) / 2);
    margin-left: 10px;

    & button {
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem 0;
      font-weight: 400;
      margin-top: 1rem;
    }
    @include media-breakpoint-down(md) {
      margin: 50px 0;
      align-items: baseline;
      padding: 0 50px;
      & h3 {
        padding-bottom: 0;
      }
    }
    @include media-breakpoint-up(md) {
      & h3 {
        padding-bottom: 40px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 0 calc((100vw - 500px) / 2);
      min-width: 100%;
      height: fit-content;
      & h3 {
        padding-bottom: 20px;
        & br {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      padding: 0 10px;
      margin-top: 60px;
    }
    & h3 {
      position: relative;
      font-size: 2.5rem;
      &::before {
        content: "";
        position: absolute;
        border-top: 5px solid #000;
        top: -25%;
        left: 0;
        width: 80px;
      }
    }
  }
  .howDoesItWorkArea {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-left: 15px;
    @include media-breakpoint-down(md) {
      min-height: 400px;
      padding-left: 0;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
      min-width: 100%;
      min-height: 400px;
    }
    .imgArea {
      position: relative;
      min-height: 400px;
      height: 100%;
      width: 100%;
      background: url("../../../assets/sign-up-for-new/pour-glass.png") left
        no-repeat;
      background-size: cover;
      @include media-breakpoint-down(sm) {
        background-position: top;
      }
    }
    .linkArea {
      position: absolute;
      top: 15%;
      left: 10%;
      & h4 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 2rem;
        color: #ece6dd;
      }
      & a {
        color: #ece6dd;
        font-size: 1.2rem;
        font-weight: 500;
        padding-top: 10px;
      }
    }
  }
}
