@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.timelineItem {
  & > * {
    margin: 0 10px;

    @include media-breakpoint-down(md) {
      margin: 0 50px !important;
    }
  }
  @include media-breakpoint-down(lg) {
    p {
      font-size: 0.9rem;
    }
  }

  & small {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    font-family: "Bebas Neue";
    font-size: 20px;
    font-weight: 500;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: black;
      width: 10px;
      height: 10px;
      inset: 90px 0 0 0px;
      transform: translateY(10%);
    }

    @include media-breakpoint-down(md) {
      &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 1);
        width: 10px;
        height: 10px;
        inset: 3px 0 0 -30px;
        transform: translateX(10%);
      }
    }
  }

  & p:first-of-type {
    padding-top: 30px;
    padding-bottom: 10px;

    @include media-breakpoint-down(sm) {
      padding-top: 0px;
    }
  }

  & h3 {
    padding-top: 5px;
    letter-spacing: 0;
    font-weight: 600;
  }

  &:last-of-type {
    & p {
      font-weight: bold;
    }
  }

  @include media-breakpoint-down(sm) {
    p:last-of-type {
      padding-bottom: 20px;
    }
  }
}
