@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.generalSliderComponent {
  @import "../../styles/Global.scss";
  .indicatorArea {
    .indicatorItem {
      transform-origin: left;
      line-height: 1.2;
      font-weight: bold;
      cursor: pointer;
      color: #e1e1e1;
      font-size: 2rem;
      transition: all ease-in-out 0.25s !important;
      letter-spacing: 3px !important;
      margin-bottom: 0.5rem;
    }
    .indicatorBar {
      height: 6px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1.1rem;
      background-color: black;
      transition: all ease-in-out 0.25s !important;
    }
    .active {
      color: black;
      width: 180px !important;
      left: -200px !important;
    }
  }
  .slideArea {
    height: 100%;
    min-height: 315px;
    .slide {
      min-height: 140px;
      & p {
        height: 100%;
        font-size: 1.3rem;
        padding: 1px;
        text-align: justify;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }
    .buttonWrap {
      margin-top: 2rem;
      .btnBlackOutline {
        padding: 0.3rem 2rem;
      }
    }
  }
}
//
.collectiveClass {
  padding: 2rem;
  padding-bottom: 0;
  .indicatorArea {
    .indicatorItem {
      font-size: 1rem;
      white-space: nowrap;
      letter-spacing: 1px !important;
    }
    .indicatorBar {
      height: 3px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 0.9rem;
      background-color: black;
      transition: all ease-in-out 0.5s !important;
    }
    .active {
      color: black;
      width: 20px !important;
      left: -40px !important;
      font-size: 1.25rem;
    }
  }
  .slideArea {
    min-height: 150px;
    .slide {
      & p {
        font-size: 1rem;
        max-width: 320px;
        text-align: left;
      }
    }
  }
}

.collectiveClass {
  .slideArea {
    min-height: 270px;
  }
}
