@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../styles/Global.scss";

.caskDetailRow {
  padding-top: 6rem;
  .imgWrap {
    max-height: 530px;
    position: relative;
    & > div {
      height: 100%;
    }
    @include media-breakpoint-down(lg) {
      max-height: 400px;
    }
  }

  .contentWrap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .titleWrap {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .distillery {
        border-bottom: 2px black solid;
        width: min-content;
        & p {
          font-weight: bold;
          white-space: nowrap;
          padding-bottom: 0.5rem;
        }
      }

      & h3 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .descriptionWrap {
        .description {
          max-width: 470px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .fullDescription {
          overflow: visible;
          height: auto;
          display: block;
          white-space: pre-wrap;
        }
        .more {
          color: $orange;
          text-decoration: underline;
          margin-top: -0.25rem;
          cursor: pointer;
        }
      }
    }
    .detailWrap {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .caskInfoWrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 0;
        .inner {
          & p {
            font-size: 0.8rem;
          }
          .info {
            color: rgba($black, 0.75);
          }
        }
      }
    }

    .priceWrap {
      display: flex;
      flex-direction: column;
      max-width: 350px;
      gap: 0rem;
      .infoRow {
        margin-top: 0.25rem;
      }
      .infoRow,
      .shippingInfo {
        margin-bottom: 1rem;
        & p,
        & a {
          color: $orange;
          font-size: 0.9rem;
          letter-spacing: 0;
        }
        & a {
          cursor: pointer;
          white-space: nowrap;
          & svg {
            transition: transform ease 0.2s;
            transform: translateX(0rem);
          }
          &:hover {
            & svg {
              transform: translateX(0.5rem);
              transition: transform ease 0.2s;
            }
          }
        }
        .priceNotice {
          margin-top: 0.25rem;
        }
      }
      .shippingInfo {
        color: $black;
        margin-bottom: 0.5rem;
        & p,
        & a {
          color: $black;
          & span {
            font-weight: bold;
          }
        }
      }
      .innerWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        gap: 0.25rem;
        .price {
          font-weight: bold;
        }
      }
      .buttonRow {
        .btnCoralOutline {
          width: 100%;
        }
      }
    }
  }
}

// -------------------------  Media Queries  -------------------------
// Tablet
@include media-breakpoint-down(md) {
  .caskDetailRow {
    margin-top: 1rem;
    .imgWrap {
      margin-top: 4rem;
    }
  }
}
// Mobile
@include media-breakpoint-down(sm) {
  .caskDetailRow {
    padding-top: 0;
    gap: 1.5rem;

    .imgWrap {
      margin-top: 1.75rem;
      max-height: 250px;
    }

    .contentWrap {
      align-items: center;
      padding: 0;
      & * {
        text-align: center;
      }
      .titleWrap {
        order: 1;
        align-items: center;
        padding: 0 1rem;
      }
      .detailWrap {
        width: 100%;
        order: 3;
        .caskInfoWrap {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .priceWrap {
        order: 2;
        background-color: $orange;
        max-width: 100%;
        width: 100%;
        padding: 1.5rem 1rem;
        & * {
          color: $beige !important;
        }
        align-items: center;
        .btnCoralOutline {
          width: 100%;
          border-color: $beige;
          max-width: 300px;
        }
      }
    }
  }
}
