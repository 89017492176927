@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.featuredBottlesSliderWrap {
  @import "../../../styles/Global.scss";
  @include media-breakpoint-up(md) {
    padding-right: 1.9rem !important;
  }

  & [class*="slick-arrow"] {
    display: block !important;
    top: unset !important;
    bottom: -5rem !important;
    width: 40px;
    height: 40px;

    &::before {
      font-size: 40px;
    }
  }
  & [class*="slick-prev"] {
    left: 0 !important;
  }
  & [class*="slick-next"] {
    left: 3rem !important;
  }

  @include media-breakpoint-down(sm) {
    & [class*="slick-arrow"] {
      display: none;
    }
  }

  & [class*="slick-slider"] {
    & [class*="slick-dots"] {
      bottom: -2rem;
      right: 0%;
      width: 100%;
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      & button {
        &::before {
          font-size: 0.75rem !important;
          opacity: 0.4;
          color: $beige;
        }
      }

      & [class*="slick-active"] {
        & button {
          &::before {
            opacity: 1;
            color: $orange;
          }
        }
      }
    }
  }
}
