@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.welcomeToCaskshareWrap {
  @import "../../../styles/Global.scss";
  .topWrap {
    .welcomeWrap {
      display: flex;
      flex-direction: row;
      height: 100%;
      margin: 3rem 0;
      .imgWrap {
        & h3 {
          max-width: 470px;
        }
      }
      .contentWrap {
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        & h4 {
          max-width: 440px;
        }
        & p {
          max-width: 440px;
        }
        .buttonWrap {
          & a {
            padding: 0.4rem 2rem;
          }
        }
      }
    }
  }

  .featuredBG {
    background-color: black;
    & [class*="featuredSection"] {
      padding-top: 4rem !important;
      padding-bottom: 4rem !important;
      @include media-breakpoint-down(sm) {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
      }
    }
  }
  // Media Queries
  /*------------------------------------------------------------------------------------------------*/

  @include media-breakpoint-down(sm) {
    .topWrap {
      .welcomeWrap {
        margin: 2rem 0;
        .contentWrap {
          // max-width: unset;
          & h4 {
            font-size: 1.4rem;
            max-width: 350px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
