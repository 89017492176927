@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.teamCardWrapper {
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  padding: 0 30px;

  &:nth-child(4n) {
    border-right: 0;
  }

  @include media-breakpoint-down(lg) {
    &:nth-child(4n) {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
    &:nth-child(3n) {
      border-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    &:nth-child(3n) {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
    &:nth-child(2n) {
      border-right: 0;
    }
  }
}
