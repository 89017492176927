@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

// Desktop
/*------------------------------------------------------------------------------------------------*/
.giftingOuterWrap {
  @import "../../../styles/Global.scss";

  max-width: 100vw;
  overflow: hidden;
  .giftingWrap {
    padding-top: 4rem;
    .giftingRow {
      min-height: 500px;
      .imgWrap {
        display: flex;
        .img {
          & img {
            width: 100%;
            height: 100%;
            max-height: 500px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .contentWrap {
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          .head {
            max-width: 344px;
            border-bottom: 2px solid rgba($black, $alpha: 0.1);
            & h3 {
              letter-spacing: 4px;
              margin-bottom: 0.5rem;
            }
            & p {
              text-align: justify;
              margin: 1rem 0;
            }
          }
          .list {
            max-width: 344px;
            width: 100%;
            padding: 1rem 0;
            .inner {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              margin-bottom: 0.75rem;
              & span {
                width: 6px;
                height: 6px;
                background-color: $orange;
                border-radius: 50%;
              }
              & p {
                margin-left: 0.7rem;
              }
            }
          }
          .faq {
            display: flex;
            flex-direction: column;
            max-width: 344px;
            width: 100%;
            margin-top: auto;
            & small {
              font-weight: 300;
              font-size: 0.8rem;
            }
            & a {
              font-size: 0.9rem;
              font-weight: 400;
              cursor: pointer;
              text-decoration: underline;
              width: fit-content;
            }
          }
        }
      }
      // Forms
      /*------------------------------------------------------------------------------------------------*/
      .formWrap {
        & form {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          max-width: 500px;
        }
        .formRow {
          .title {
            margin-bottom: 0.4rem;
          }
          .priceWrap {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            padding: 0 15px;

            .price {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.9rem;
              padding: 0.4rem;
              letter-spacing: 1px;
              border: 1px solid rgba($black, $alpha: 0.2);
              border-right: transparent;
              cursor: pointer;
              &:last-child {
                border-right: 1px solid rgba($black, $alpha: 0.2);
              }
            }
            .activePrice {
              color: white;
              background-color: $orange;
              border-color: $orange;
            }
          }
          .formInput {
            & input,
            & textarea {
              border-radius: 0;
              max-height: 90px;
            }
          }
          .half {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            & input {
              flex: 1;
              max-width: 48%;
              width: 100%;
            }
          }
          .sendVoucher {
            display: flex;
            align-items: center;
          }
          .checkbox {
            margin-top: 1rem;
          }
          .caskCol {
            .caskWrap {
              display: flex;
              flex-direction: column;
              margin-bottom: 0.5rem;
              .imgWrap {
                max-width: 200px;
                margin: 0 auto;
                & div > div {
                  max-width: 200px;
                }
              }
              & h5 {
                margin-top: 1rem;
                text-align: center;
              }
            }
          }
        }
        .btnWrap {
          margin-top: auto;
          padding-top: 2rem;
          .btnSecondary {
            width: 100%;

            max-width: 280px;
          }
        }
      }
    }
    // More Gifting Slider
    /*------------------------------------------------------------------------------------------------*/
    .moreGiftingIdeas {
      padding-top: 4rem;
      .heading {
        margin-bottom: 3rem;
      }
    }
  }
  // Confirmation Modal
  /*------------------------------------------------------------------------------------------------*/
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .confirmationModalWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba($black, $alpha: 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn ease 0.5s;

    .inner {
      padding: 2rem;
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & p {
        &:first-of-type {
          margin: 1.5rem;
        }
        & span {
          text-transform: capitalize;
        }
      }

      .btnPrimary {
        margin-top: 1.5rem;
      }
    }
  }
  // Footer Block
  /*------------------------------------------------------------------------------------------------*/
  .startCollection {
    position: relative;
    min-height: 600px;
    max-height: 90vh;
    background-color: $beige;
    overflow: hidden;
    margin-top: 2rem;
    padding: 0 1rem;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../../../assets/images/share-dots-light.png") no-repeat;
      height: 400px;
      width: 400px;
      background-size: contain;
      opacity: 0.9;
    }
    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;

      & span {
        margin-top: 4rem;
        width: 100px;
        height: 6px;
        background-color: $black;
      }

      & > * {
        margin-bottom: 3rem;
        color: $black;
        text-align: center;
        &:last-child {
          margin-bottom: 0;
        }
      }

      & p,
      & a {
        max-width: 530px;
        color: $black;
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 0;
      &::after {
        height: 300px;
        width: 300px;
      }
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      .inner {
        padding: 2.5rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .giftingWrap {
      padding: 0;
      .giftingRow {
        justify-content: center;
        .imgWrap,
        .contentWrap,
        .formWrap {
          flex: 50%;
          min-width: 350px;
        }
        .imgWrap {
          .img {
            width: 100%;
          }
        }
        .contentWrap {
          padding: 1rem 2rem 0rem 0;
        }
        .formWrap {
          padding: 1rem 2rem;
          margin: 3rem;
          display: flex;
          justify-content: center;
        }
      }
      .moreGiftingIdeas {
        padding-top: 2rem;
        .heading {
          text-align: center;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .startCollection {
      min-height: unset;
      .inner {
        & span {
          height: 4px;
          width: 60px;
          margin-bottom: 1.5rem;
        }
        & h2 {
          max-width: 230px;
        }
      }
    }
    .giftingWrap {
      .giftingRow {
        .formWrap {
          padding: 0;
        }
      }
    }
  }
  @media screen and (max-width: 670px) {
    .giftingWrap {
      .giftingRow {
        justify-content: center;
        .imgWrap {
          max-height: 400px;
        }
        .contentWrap {
          min-width: unset;
          padding: 1rem 2rem;
        }
      }
    }
  }
}
