@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.flavourCompWrap {
  @import "../../../../styles/Global.scss";

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: black;
  padding: 3rem 1rem;
  gap: 1rem;
  margin-top: 4rem;

  & > div {
    flex: 1;
  }
  .content {
    .title {
      color: $orange;
      font-size: 0.9rem;
    }
    .notes {
      max-width: 430px;
      color: $beige;
      font-size: 0.9rem;
    }
  }
  .flavourWrap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 1rem;

      & p {
        white-space: nowrap;
        font-size: 0.8rem;
      }
      .title {
        color: $beige;
        min-width: 80px;
      }
      .progress {
        width: 100%;

        & [class*="progress"] {
          background-color: rgba($white, 0.3);
          height: 0.35rem;
        }
      }
      .smoky {
        & [class*="progress-bar"] {
          background-color: $beige;
        }
      }
      .floral {
        & [class*="progress-bar"] {
          background-color: $orange;
        }
      }
      .spicy {
        & [class*="progress-bar"] {
          background-color: #8c8279;
        }
      }
      .sweet {
        & [class*="progress-bar"] {
          background-color: #fead77;
        }
      }
      .rich {
        & [class*="progress-bar"] {
          background-color: #ffb500;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    padding: 2rem 1rem;
    .content {
      padding: 0;
      & * {
        text-align: center;
        max-width: 100% !important;
      }
    }
    .flavourWrap {
      padding: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-top: 0;
    gap: 2rem;
  }
}
