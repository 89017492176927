@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.charityRaffleWrapper {
  max-width: 100vw;
  background-color: #ece6dd;
  overflow: hidden !important;
  .charityRaffleContainer {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    margin: 0 auto;
    min-height: calc(100vh - 100px);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .innerWrap {
      margin: 0;
      padding: 2rem 0;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .textWrap {
        margin: 0;
        padding: 0;
        z-index: 2;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;

        .titleWrap {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;

          .title {
            font: 8rem "Bebas Neue";
            font-weight: 300;
          }
          .price {
            white-space: nowrap;
            font: 5rem "Bebas Neue";
            letter-spacing: 2px;
            transform: rotate(-90deg);
            height: min-content;
            width: min-content;
            color: #003f5a;
            margin-top: 0.5rem;
            margin-left: -2.5rem;
            padding: 0;
            & span {
              font-size: 3rem;
            }
          }
        }
        .descriptionWrap {
          text-align: justify;
          padding-right: 1rem;
          margin-top: 1rem;
          margin-bottom: 2.5rem;
          font-size: 1.1rem;
        }
        .buttonsWrap {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;

          & a {
            flex: 1;
          }

          .primary {
            color: #ece6dd;
          }

          .secondary {
            & h5 {
              color: #003f5a !important;
              margin: 0;
              padding: 0;
              text-align: center;
              &:hover {
                text-decoration: underline;
                text-decoration-color: #003f5a;
              }
            }
          }
        }
      }

      .bottleWrap {
        margin: 0;
        padding: 0;
        max-height: calc(100vh - 200px);
        // filter: drop-shadow(0 0 2rem #000);

        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 800px;
        }
        .shadow {
          width: 100%;
          height: 5rem;
          background: linear-gradient(
            0deg,
            rgba(236, 230, 221, 1) 0%,
            rgba(236, 230, 221, 0) 100%
          );
          margin-top: -5rem;
          z-index: 2;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .innerWrap {
        .textWrap {
          .titleWrap {
            .title {
              font-size: 6.5rem;
            }
            .price {
              font-size: 4rem;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      .innerWrap {
        .textWrap {
          flex: 2;
          .titleWrap {
            .title {
              font-size: 4.5rem;
            }
            .price {
              font-size: 3rem;
              margin-left: -1.5rem;
              & span {
                font-size: 2rem;
              }
            }
          }
        }
        .bottleWrap {
          flex: 1;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      .innerWrap {
        padding: 2rem 1rem;
        .textWrap {
          .descriptionWrap {
            padding: 0;
            font-size: 1rem;
          }
        }
        .bottleWrap {
          display: none;
        }
      }
    }
    @media (max-width: 350px) {
      .innerWrap {
        .textWrap {
          .titleWrap {
            .price {
              display: none;
            }
          }
        }
      }
    }
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .detailInfoWrap {
    margin: 0;
    padding: 2rem 0;
    margin-bottom: 6rem;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .raffleInfoWrap {
      padding: 0;
      margin: 0;
      margin-top: 2rem;
      .title {
        white-space: nowrap;
        font: 5rem "Bebas Neue";
        font-weight: 500;
        text-align: center;
      }
      .innerRaffleWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 6rem;
        .img {
          flex: 1;
          max-height: 500px;
          position: relative;
          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .first {
            z-index: 1;
            transform: rotate(-10deg);
            margin-left: -10%;
            margin-top: -2%;
          }
          .second {
            position: absolute;
            top: 0%;
            left: 5%;
            transform: rotate(10deg);
            z-index: 2;
          }
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: justify;
          & h3 {
            color: #003f5a;
            margin-bottom: 2rem;
            font-size: 3rem;
            font-weight: 400;
            letter-spacing: 2px;
          }
          & p {
            font-size: 1.1rem;
            margin-bottom: 2rem;
            & span {
              font-weight: bolder;
              // text-decoration: underline;
              // text-decoration-thickness: 2px;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      .raffleInfoWrap {
        .innerRaffleWrap {
          .img {
            align-self: center;
            & img {
              max-height: 400px;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
      padding: 0;
      .raffleInfoWrap {
        .innerRaffleWrap {
          flex-direction: column;
          margin: 0;
          .img {
            transform: rotate(90deg);
            & img {
              max-height: 400px;
            }
          }
          .text {
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
      .raffleInfoWrap {
        .innerRaffleWrap {
          flex-direction: column;
          margin: 0;
          .img {
            & img {
              max-height: 350px;
            }
          }
          .text {
            padding: 0 1rem;
            & p {
              font-size: 1rem;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(xs) {
      .raffleInfoWrap {
        .innerRaffleWrap {
          .img {
            & img {
              max-height: 280px;
            }
          }
        }
      }
    }
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  .charityInfoWrap {
    margin: 0;
    padding: 2rem 0;
    min-height: 600px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;

    & > div {
      padding: 0;
      margin: 0;
    }
    .title {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      & a:hover {
        text-decoration: underline;
        text-decoration-color: #003f5a;
      }

      & div {
        transform: rotate(-90deg);
        font: 3.8rem "Bebas Neue";
        letter-spacing: 2px;
        font-weight: 400;
        height: fit-content;
        color: #003f5a;
        & span {
          white-space: nowrap;
        }
      }
    }
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;
      margin-left: -1.5rem;
      & p,
      & ul {
        font-size: 1.1rem;
        margin-bottom: 2rem;
        & span,
        & a {
          font-weight: bolder;
        }
      }
    }
    .logo {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(10%);
      z-index: 0;
      & img {
        height: 80%;
        width: 80%;
        object-fit: contain;
        opacity: 0.1;
      }
    }
    @include media-breakpoint-down(md) {
      .text {
        margin: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      min-height: unset;
      .title {
        & div {
          font-size: 2.8rem;
        }
      }
      .text {
        margin: 0;
        & p,
        & ul {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      .title {
        margin-bottom: 2rem;
        & div {
          font-size: 1.8rem;
        }
        & div {
          transform: rotate(0deg);
          height: unset;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .empty {
      display: none;
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // .separator {
  //   width: 100px;
  //   height: 6px;
  //   background-color: black;
  //   margin: 15px auto;
  // }
}
