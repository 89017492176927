@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.bold {
  font-weight: 500;
}

.item {
  display: grid;
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
}

.item1 {
  grid-column: 1/4;
  grid-row: 1/2;
  height: 100%;

  .socialLink {
    display: flex;
    justify-content: space-between;

    & small {
      font-size: 16px;
    }
  }
}
.item2 {
  grid-column: 4 / 7;
  grid-row: 1 / 3;
}
.item3 {
  grid-column: 7 / 9;
  grid-row: 1 / 3;
  align-self: flex-end;
}
.item4 {
  grid-column: 9 / 12;
  grid-row: 2 / 4;
}
.item5 {
  grid-column: 1 / 4;
  grid-row: 2 / 4;
  & img {
    object-position: left top;
  }
}
.item6 {
  grid-column: 4 / 6;
  grid-row: 3 / 5;
  align-self: flex-start;
}
.item7 {
  grid-column: 6 / 9;
  grid-row: 3 / 5;
}
.item8 {
  grid-column: 9 / 12;
  grid-row: 4 / 5;

  .socialLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    & small {
      font-size: 14px;
    }

    i {
      margin-top: 20px;
      color: black;
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 300px;
  max-width: 300px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
