@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.dateWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    & p {
      margin: 0;
    }
    & small {
      padding-left: 10px;
    }
    & .plus {
      margin-left: auto;
    }
  }
  .articleOuter {
    padding-left: 20px;
    .articleWrapper {
      display: none;
    }
  }
  &.active {
    border-bottom: 0;
    height: 100%;
    transition: all 500ms;
    .articleOuter {
      .articleWrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        a {
          position: absolute;
          top: -5px;
          right: 0;
        }
      }
    }
    .inner {
      margin-bottom: 20px;
      .plus {
        transform: rotate(135deg);
        transition: transform 500ms;
      }
    }
  }
}
