@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.homePageWrap {
  @import "../../../styles/Global.scss";

  max-width: 100vw;
  visibility: hidden;
  opacity: 0;
  background-color: $beige;
  position: relative;

  .dots {
    & [class*="cls-1"],
    & [class*="cls-2"],
    & [class*="cls-3"] {
      opacity: 1 !important;
      stroke: none !important;
      fill: rgba($color: $beige, $alpha: 0.25) !important;
      transition: all ease-in 0.75s;
    }

    & [class*="1"] {
      & [class*="cls-1"] {
        fill: rgba($color: $beige, $alpha: 1) !important;
        transition: all ease-in 0.75s;
      }
    }
    & [class*="2"] {
      & [class*="cls-3"] {
        fill: rgba($color: $beige, $alpha: 1) !important;
        transition: all ease-in 0.75s;
      }
    }
    & [class*="3"] {
      & [class*="cls-2"] {
        fill: rgba($color: $beige, $alpha: 1) !important;
        transition: all ease-in 0.75s;
      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .howItWorks {
    width: min-content;
    border-bottom: 2px solid $beige;
    & p {
      font-family: $domaineHeading;
      color: $beige;
      padding-bottom: 0.5rem;
      white-space: nowrap;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .spacer {
    height: 7rem;
    @include media-breakpoint-down(xs) {
      height: 3.5rem;
    }
  }

  .heroSection {
    width: 100vw;
    min-height: 500px;
    flex-direction: column;
    margin: 0;

    .heroCarouselWrapper {
      height: 100%;
      width: 100%;

      // & [data-index*="0"] {
      //   .slideWrap {
      //     &::before {
      //       background-color: rgba($color: $black, $alpha: 0.65);
      //     }
      //   }
      // }

      & [class*="slick-slider"] {
        & [class*="slick-dots"] {
          bottom: 0%;
          right: 0;
          width: unset;
          height: 100%;
          display: flex !important;
          gap: 0.5rem;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-right: 2rem;

          & button {
            &::before {
              font-size: 1rem !important;
            }
          }
        }
      }

      .slideWrap {
        position: relative;
        width: 100%;
        height: calc(85vh - 90px);
        max-height: 865px;
        overflow: hidden;
        padding: 0;
        margin-bottom: -0.5rem;

        &::before {
          content: "";
          position: absolute;
          top: 0%;
          left: 0;
          background-color: rgba($color: $black, $alpha: 0.45);
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .innerWrap {
          height: 100%;
          .imgWrap {
            height: 100%;
            width: 100%;

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .content {
            position: absolute;
            bottom: 100px;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, 0%);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0 1rem;

            .head {
              & h2 {
                color: $black;
                background-color: $white;
                padding: 1rem 2rem;
                width: min-content;
                white-space: nowrap;
                & span {
                  font-family: $domaineHeading;
                }
                &:nth-child(2) {
                  margin-top: -1rem;
                }
              }
            }

            .buttonWrap {
              .newButton {
                background-color: $white;
                border-color: $white;
                padding: 5px 30px;
                &:hover {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }

  .contentSection {
    padding-bottom: 0;
    padding: 0 2rem;

    .sectionNav {
      display: flex;
      flex-direction: row;
      min-height: 150px;
      margin-top: -50px;
      margin-bottom: 100px;

      .colItem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(1) {
          background-color: black;
        }
        &:nth-child(2) {
          background-color: #00313c;
        }
        &:nth-child(3) {
          background-color: $orange;
        }

        .active {
          color: $beige;
          border-color: $beige;
        }
      }
    }
  }

  .stepOneSection {
    position: relative;
    margin-bottom: 4rem;

    .inner {
      z-index: 1;
      padding: 4rem;
      padding-right: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      width: calc(100% - 135px);
      background-color: $black;
      .content {
        display: flex;
        flex-direction: column;
        max-width: 40%;
        flex: 1;
        gap: 2rem;
        transform: translate(0);
        & * {
          color: $beige;
        }
        .innerContent {
          max-width: 350px;
          padding-right: 1.5rem;
          & * {
            margin-bottom: 1rem;
          }
          & a {
            color: $lightCoral;
          }
          & button {
            margin-bottom: 0rem;
          }
        }
      }
      .topImg {
        position: absolute;
        padding-left: 2rem;
        top: 4rem;
        right: 0;

        height: 70%;
        max-width: 55%;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }
      .bottomImg {
        position: absolute;
        padding-left: 2rem;
        bottom: -3rem;
        right: 3rem;

        width: 55%;
        height: 44%;
        max-height: 300px;

        @include media-breakpoint-down(lg) {
          height: 55%;
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  .stepTwoSection {
    position: relative;
    margin-bottom: 4rem;
    .inner {
      background-color: #00313c;
      height: 100%;
      // width: clamp(300px, calc(100% - 450px), 750px);
      width: 60%;
      // padding: clamp(1rem, 3vw, 4rem);
      padding: 5rem 0;
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        & * {
          color: $beige;
        }
        .innerContent {
          max-width: 290px;
          @include media-breakpoint-down(lg) {
            padding-right: 2rem;
          }
          & * {
            margin-bottom: 1rem;
          }
          & a {
            color: $lightCoral;
          }
          & button {
            margin-bottom: 0rem;
          }
        }
      }
    }
    .leftImg {
      position: absolute;
      bottom: -3rem;
      max-width: 50%;
      height: 90%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;
      }
    }
    .rightImg {
      position: absolute;
      bottom: -3rem;
      right: -130px;
      // transform: translateX(-25%);
      max-width: 300px;
      height: 140%;
      padding-top: 5rem;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .enjoyBG {
    background-color: $orange;
  }

  .stepThreeSection {
    position: relative;
    margin-bottom: 4rem;
    height: 100%;
    .inner {
      padding: 4rem 0;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        & * {
          color: $beige;
        }
        .innerContent {
          max-width: 310px;
          & * {
            margin-bottom: 1rem;
          }
          & a {
            color: $orange;
          }
          & button {
            margin-bottom: 0rem;
          }
        }
      }
    }
    .leftImg {
      width: 60%;
      height: auto;
      z-index: 1;
      margin-top: -1rem;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .rightImg {
      position: absolute;
      top: -3rem;
      right: 0;
      width: 55%;
      height: auto;
      z-index: 2;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .featuredBG {
    background-color: $black;
    margin-top: -200px;
  }

  .pressBG {
    background-color: $white;
  }

  .pressSection {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5rem 0;
    padding-bottom: 7rem;
    .imgWrap {
      width: 60%;
      height: auto;
      margin-bottom: 2rem;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .head {
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 3rem;
      padding-right: 0;
      gap: 2rem;

      & h3 {
        & span {
          font-family: $domaineHeading;
        }
      }

      & button {
        width: fit-content;
      }
    }

    .content {
      position: absolute;
      bottom: 9%;
      right: 20%;
      max-width: 700px;
      background-color: $black;
      padding: 6rem;

      & p {
        color: $beige;
      }
    }

    @include media-breakpoint-down(lg) {
      .content {
        padding: 4rem;
      }
    }
  }

  .collectiveBG {
    background: url("../../../assets/home-page/.v3/image6.jpg") no-repeat center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba($color: $black, $alpha: 0.65);
  }

  .collectiveSection {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-top: 5rem;

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .innerContent {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 500px;
        & h3,
        & p {
          color: $beige;
        }
        & h3 {
          & span {
            font-family: $domaineHeading;
          }
        }
        & button {
          width: fit-content;
        }
      }

      .benefits {
        margin-top: 2rem;
        .benefitsWrap {
          margin: 3rem 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;

          & p {
            position: relative;
            max-width: 120px;
            color: $lightCoral;

            &::after {
              position: absolute;
              content: "";
              top: -0.5rem;
              left: 0;
              width: 12px;
              height: 2px;
              background-color: $beige;
            }
          }
        }
      }
      .imgWrap {
        & img {
          max-width: 750px;
        }
      }
      .joinWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 3rem;
        padding-right: 2rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
      }
    }

    .leftImg {
      position: absolute;
      bottom: 0%;
      left: 10%;
      width: 100%;
      height: 100%;
      max-height: 350px;
      max-width: 45%;

      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: bottom right;
      }
    }
  }

  // Media Queries

  @include media-breakpoint-down(md) {
    .heroSection {
      .heroCarouselWrapper {
        .slideWrap {
          &::before {
          }
          .innerWrap {
            .imgWrap {
              & img {
              }
            }
            .content {
              .head {
                & h2 {
                  & span {
                  }
                  &:nth-child(2) {
                  }
                }
              }
            }
          }
        }
      }
    }

    .contentSection {
      .sectionNav {
        min-height: 130px;
        .colItem {
        }
      }
    }

    .stepOneSection {
      .inner {
        width: calc(100% - 95px);
        padding: 3rem 2rem;
        .content {
          max-width: 50%;
          .innerContent {
          }
        }
        .topImg {
          height: 65%;
          & img {
            object-position: bottom;
          }
        }
        .bottomImg {
          width: 55%;
          height: 40%;
          right: 2rem;
        }
      }
    }

    .stepTwoSection {
      .inner {
        padding: 3rem 0;
        .content {
          .innerContent {
            padding-right: 0;
          }
        }
      }
      .leftImg {
        max-width: 46%;
        height: 70%;
        & img {
        }
      }
      .rightImg {
        max-width: 215px;
        height: 100%;
        right: -100px;
        bottom: -3rem !important;
        padding-top: 2rem;
        & img {
        }
      }
    }

    .enjoyBG {
    }

    .stepThreeSection {
      flex-direction: column;
      .inner {
        padding: 2rem 1rem;
        // justify-content: flex-start;
        .content {
          gap: 1rem;
          .innerContent {
            max-width: 290px;
          }
        }
      }
      .leftImg {
        width: 50%;
        margin: 0 auto;
        margin-top: 0rem;
        & img {
        }
      }
      .rightImg {
        top: -2rem;
        width: 55%;
        max-width: 450px;
        & img {
        }
      }
    }

    .pressSection {
      .imgWrap {
        width: 60%;
        & img {
        }
      }

      .head {
        padding: 2rem;
        padding-right: 0;
        & h3 {
          & span {
          }
        }

        & button {
        }
      }

      .content {
        right: 0;
        bottom: 7%;
        padding: 3rem;
        max-width: 650px;
        & p {
          max-width: 500px;
        }
      }
    }

    .collectiveBanner {
      margin-top: 0.5rem;
      justify-content: center;
      padding: 2rem 0;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
        gap: 2rem;
        .howItWorks {
          display: block;
        }
        .innerContent {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          justify-content: center;
          align-items: center;
          text-align: center;
          & h3 {
            color: $beige;
          }
          & button {
            width: fit-content;
          }
        }
      }
    }

    .collectiveContentBG {
      background-color: black;
      .collectiveContent {
        justify-content: center;
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
          padding: 2rem 0;
          width: 100%;
          & > p {
            color: $beige;
            max-width: 300px;
            text-align: center;
          }
          .benefits {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 540px;
            width: 100%;
            .howItWorks {
              display: block;
            }
            .benefitsWrap {
              margin: 3rem 0;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              width: 100%;
              gap: 2rem;
              justify-content: center;

              & p {
                position: relative;
                text-align: left;
                max-width: 120px;
                color: $lightCoral;

                &::after {
                  position: absolute;
                  content: "";
                  top: -0.5rem;
                  left: 0;
                  width: 12px;
                  height: 2px;
                  background-color: $beige;
                }
              }
            }
          }
          .imgWrap {
            height: 300px;
            width: 100%;
            margin-bottom: -2rem;
            z-index: 2;
            & img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .heroSection {
      .heroCarouselWrapper {
        & [class*="slick-slider"] {
          & [class*="slick-dots"] {
            bottom: 0%;
            right: 0%;
            width: 100%;
            height: auto;
            padding-bottom: 2rem;
            padding-right: 0;
            display: flex !important;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            & button {
              &::before {
                font-size: 1rem;
              }
            }
          }
        }
        .slideWrap {
          height: calc(98vh - 100px);
          .innerWrap {
            .imgWrap {
              & img {
              }
            }
            .content {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 1rem;
              .head {
                width: 100%;
                & h2 {
                  padding: 1rem;
                  white-space: unset;
                  width: 100%;
                  & span {
                  }
                  &:nth-child(2) {
                    margin-top: -2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .contentSection {
      .sectionNav {
        min-height: unset;
        padding: 1rem;
        margin-top: 0;
        background-color: black;
        margin: 0 -2rem;
        margin-bottom: 1.5rem;
        .colItem {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            background-color: black;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }

          .active {
            color: $beige;
            border-color: $beige;
          }
        }
      }
    }

    .stepOneSection {
      margin-bottom: 1.5rem;
      .inner {
        width: 100%;
        flex-direction: column;
        padding: 2rem 1.5rem;
        flex-wrap: nowrap;
        gap: 2rem;
        .content {
          gap: 0;
          max-width: 50%;
          .innerContent {
          }
        }
        .topImg {
          position: absolute;
          width: 90%;
          height: 100%;
          max-width: 250px;
          padding-left: unset;
          margin-left: auto;
          // margin-right: -1rem;
          // margin-bottom: -2rem;
          & img {
            object-position: center;
          }
        }
        .bottomImg {
          padding-left: unset;
          height: 45%;
          width: 50%;
          right: 5%;
          bottom: -4rem;
        }
      }
    }

    .stepTwoSection {
      margin-bottom: 1.5rem;
      background-color: #00313c;
      .inner {
        width: 100%;
        flex-direction: column;
        padding: 2rem 1.5rem;
        flex-wrap: nowrap;
        gap: 2rem;
        .content {
          gap: 0;
          max-width: 45%;
          & * {
          }
          .innerContent {
            & * {
            }
            & a {
            }
            & button {
            }
          }
        }
      }
      .leftImg {
        position: absolute;
        width: 90%;
        height: 100%;
        max-width: 45%;
        padding-left: unset;
        margin-left: auto;
        margin-top: 1.5rem;
        right: 0;
        & img {
        }
      }
      .rightImg {
        max-width: 100px;
        padding-top: 0;
        right: unset;
        left: 55%;
        transform: translateX(-50%);
        bottom: -1.5rem !important;
        height: auto;
        & img {
          object-position: bottom;
        }
      }
    }

    .enjoyBG {
    }

    .stepThreeSection {
      flex-direction: column;
      margin-bottom: 0;
      .inner {
        padding: 2rem 1.5rem;
        width: 100%;
        justify-content: flex-start;
        .content {
          gap: 1rem;
          .innerContent {
            max-width: 290px;
          }
        }
      }
      .leftImg {
        width: 80%;
        margin: unset;
        margin-top: 1rem;
        padding-bottom: 3rem;
        & img {
        }
      }
      .rightImg {
        top: 50%;
        transform: translateY(-50%);
        max-width: 40%;
        & img {
        }
      }
    }

    .featuredBG {
      margin-top: 0;
    }

    .pressSection {
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
      padding-bottom: 0;
      .imgWrap {
        width: 101vw;
        margin: 0 -1.2rem;
        margin-top: -2rem;
        height: 340px;
        order: 3;
        & img {
        }
      }

      .head {
        order: 1;
        align-items: center;
        padding: 2rem 0;
        & h3 {
          & span {
          }
        }

        & button {
        }
      }

      .content {
        order: 2;
        position: unset;
        padding: 2rem 1.5rem;
        z-index: 2;
        & p {
        }
      }
    }

    .collectiveBanner {
      margin-top: 0.5rem;
      justify-content: center;
      padding: 2rem 0;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 1rem;
        gap: 2rem;
        .howItWorks {
          display: block;
        }
        .innerContent {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          justify-content: center;
          align-items: center;
          text-align: center;
          & h3 {
            color: $beige;
          }
          & button {
            width: fit-content;
          }
        }
      }
    }

    .collectiveContentBG {
      background-color: black;
      .collectiveContent {
        justify-content: center;
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;
          padding: 2rem 0;
          width: 100%;
          & > p {
            color: $beige;
            max-width: 300px;
            text-align: center;
          }
          .benefits {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 540px;
            width: 100%;
            .howItWorks {
              display: block;
            }
            .benefitsWrap {
              margin: 3rem 0;
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              width: 100%;
              gap: 2rem;
              justify-content: center;

              & p {
                position: relative;
                text-align: left;
                max-width: 120px;
                color: $lightCoral;

                &::after {
                  position: absolute;
                  content: "";
                  top: -0.5rem;
                  left: 0;
                  width: 12px;
                  height: 2px;
                  background-color: $beige;
                }
              }
            }
          }
          .imgWrap {
            height: 300px;
            width: 100%;
            margin-bottom: -2rem;
            z-index: 2;
            & img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .heroSection {
      .heroCarouselWrapper {
        & [class*="slick-slider"] {
          & [class*="slick-dots"] {
            bottom: 0%;
            right: 0%;
            width: 100%;
            height: auto;
            padding-bottom: 2rem;
            padding-right: 0;
            display: flex !important;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            & button {
              &::before {
                font-size: 1rem;
              }
            }
          }
        }
        .slideWrap {
          height: calc(98vh - 100px);
          .innerWrap {
            .imgWrap {
              & img {
              }
            }
            .content {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 1rem;
              .head {
                width: 100%;
                & h2 {
                  padding: 1rem;
                  white-space: unset;
                  width: 100%;
                  & span {
                  }
                  &:nth-child(2) {
                    margin-top: -2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .contentSection {
      .sectionNav {
        min-height: unset;
        padding: 1rem;
        margin-top: 0;
        background-color: black;
        margin: 0 -2rem;
        margin-bottom: 1.5rem;
        .colItem {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &:nth-child(1) {
            background-color: black;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }

          .active {
            color: $beige;
            border-color: $beige;
          }
        }
      }
    }

    .stepOneSection {
      margin-bottom: 1.5rem;
      .inner {
        width: 100%;
        flex-direction: column;
        padding: 2rem 1.5rem;
        flex-wrap: nowrap;
        gap: 2rem;
        .content {
          gap: 0;
          max-width: 100%;
          .innerContent {
          }
        }
        .topImg {
          position: unset;
          width: 90%;
          height: 100%;
          max-width: unset;
          padding-left: unset;
          margin-left: auto;
          margin-right: -1.5rem;
          margin-bottom: -2rem;
          & img {
          }
        }
        .bottomImg {
          padding-left: unset;
          height: 24%;
          width: 80%;
          right: unset;
          left: 0;
          bottom: -2rem;
        }
      }
    }

    .stepTwoSection {
      margin-bottom: 1.5rem;
      background-color: #00313c;
      .inner {
        width: 100%;
        flex-direction: column;
        padding: 2rem 1.5rem;
        flex-wrap: nowrap;
        gap: 2rem;
        .content {
          gap: 0;
          max-width: 100%;
          & * {
          }
          .innerContent {
            & * {
            }
            & a {
            }
            & button {
            }
          }
        }
      }
      .leftImg {
        position: unset;
        width: 80%;
        height: 100%;
        max-width: unset;
        padding-left: unset;
        margin-left: auto;
        margin-top: 1.5rem;
      }
      .rightImg {
        max-width: 120px;
        padding-top: 0;
        right: unset;
        left: 10%;
        bottom: -1.5rem !important;
        height: height;
        transform: unset;
        @media (max-width: 470px) {
          max-width: 100px;
        }
        @media (max-width: 380px) {
          max-width: 90px;
        }
        @media (max-width: 340px) {
          max-width: 80px;
        }
        & img {
          object-position: bottom;
        }
      }
    }

    .enjoyBG {
    }

    .stepThreeSection {
      flex-direction: column;
      margin-bottom: 0;
      .inner {
        padding: 2rem 1.5rem;
        width: 100%;
        justify-content: flex-start;
        .content {
          gap: 1rem;
          .innerContent {
            max-width: 290px;
          }
        }
      }
      .leftImg {
        width: 80%;
        margin: unset;
        margin-top: 1rem;
        padding-bottom: 3rem;
        & img {
        }
      }
      .rightImg {
        top: unset;
        bottom: 2%;
        max-width: 40%;
        & img {
        }
      }
    }

    .featuredBG {
      margin-top: 0;
    }
  }

  .iconsOfWhisky {
    width: 125px;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      width: 90px;
    }
  }
}
