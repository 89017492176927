@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.imgInfoWrapper {
  padding-right: 50px;
  @include media-breakpoint-down(md) {
    padding: 0;
  }
  .caskTitle {
    font-size: 2rem;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }
  .caskCode {
    margin: 0;
    font-size: 1.3rem;
  }
  .caskDescription {
    margin-top: 10px;
  }
  .caskInfoTitle {
    font-weight: 200;
    letter-spacing: 1.5px;
    display: block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .caskInfoText {
    font-size: 1rem;
    font-weight: 300;
  }
  .caskInfoCenter {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .priceInfo {
    @import "../../styles/Global.scss";
    .priceWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      .price {
        flex: 1;
        & small {
          font-size: 0.8rem;
          font-weight: 300;
        }
        .collectivePrice {
          color: $orange;
          background-color: transparent !important;
          font-size: 1.5rem;
        }
        .nonCollective {
          color: $black;
          background-color: transparent !important;
          font-size: 1.5rem;
        }
      }
      .button {
        flex: 1;
        & button {
          width: 100%;
        }
      }
    }
    .banner {
      margin-top: 0.5rem;
      width: 100%;
      text-align: center;
      & p {
        font-size: 0.9rem;
        padding: 0.2rem;
        font-weight: 500;
      }
    }
    .collectivePrice {
      background-color: rgba($color: $orange, $alpha: 1);
      & p {
        color: $white;
      }
    }
    .nonCollective {
      background-color: rgba($color: $blue, $alpha: 0.2);
      & p {
        color: $black;
      }
    }
  }
}
.flavourNotesArea {
  @include media-breakpoint-up(xs) {
    padding-top: 1rem;
  }
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0rem;
    padding-left: 3rem;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
  }
  .flavourNotesTitle {
    font-weight: 200;
    letter-spacing: 1.5px;
  }
  .flavourNotesText {
    margin-top: 10px;
  }
  .flavourMapGraphicArea {
    max-width: 18rem;
    padding: 2rem;
    margin: auto;
    @include media-breakpoint-down(sm) {
      padding: 0 2rem;
    }
  }
}
// BioSlider
.bioSliderComponent {
  .indicatorArea {
    @include media-breakpoint-up(sm) {
      padding-right: 0;
    }
    .indicatorItem {
      transform-origin: left;
      line-height: 1.2;
      font-weight: bold;
      cursor: pointer;
      transform: scale(1);
      color: #e1e1e1;
      font-size: 1.8rem;
      transition: all ease-in-out 0.5s !important;
      letter-spacing: 1px !important;
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
      }
    }
    .indicatorBar {
      height: 4px;
      width: 0%;
      position: relative;
      left: -1.8rem;
      top: 1rem;
      background-color: black;
      transition: all ease-in-out 0.5s !important;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .activeBar {
      color: black;
      width: 100px !important;
      left: -110px !important;
    }
    .activeItem {
      color: black;
      transform: scale(1.1);
    }
  }
  .slideArea {
    position: relative;
    @include media-breakpoint-up(sm) {
      padding-left: 0.4rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 2rem;
    }
    .slide {
      min-height: 320px;
      @include media-breakpoint-down(lg) {
        min-height: 380px;
      }
      @include media-breakpoint-down(md) {
        min-height: 100%;
      }
      & p {
        font-size: 14px !important;
        text-align: justify;
        padding-right: 0.9rem;
      }
    }
  }
}
