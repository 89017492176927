.gifting-page-head {
  flex-direction: column;
  z-index: 28;

  .hero-banner {
    background-size: cover;
    background-position: right;
    padding-top: 20vh;
    width: 100%;
    z-index: 30;
    padding-bottom: 150px;
    position: relative;
    margin-bottom: -76px;

    .how-it-works-desktop {
      color: white;
      position: absolute;
      right: 50%;
      bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 85px;
    }

    .hero-container {
      padding-bottom: 0;
      position: relative;
      z-index: 2;

      .header-title {
        color: white;
      }

      .overlay-box {
        position: absolute;
        top: 45%;
      }

      .caskshare-stamp-div-image {
        position: absolute;
        background: #e76852;
        border-radius: 50%;
        bottom: -20rem;
        right: -6rem;

        img {
          width: 14rem;
        }

        @include media-breakpoint-down(lg) {
          right: -8rem;
        }

        @include media-breakpoint-down(md) {
          bottom: -16rem;
        }

        @include media-breakpoint-down(xs) {
          bottom: -10rem;
          right: -2rem;

          img {
            width: 10rem;
          }
        }
      }
    }
  }

  .caskshare-bottle-div-image {
    @include media-breakpoint-up(md) {
      max-height: 45vw;
      height: 48%;
      width: 70%;
    }
    @include media-breakpoint-up(lg) {
      height: 38%;
      width: 70%;
    }
    max-height: 30vw;
    max-width: 28vw;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -170px;
    left: 0;
    z-index: 1;

    @include media-breakpoint-down(md) {
      bottom: -200px;
    }

    @include media-breakpoint-down(xs) {
      bottom: -30vw;
      height: 42vw;
      width: 35vw;
      max-height: unset;
      max-width: unset;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
    }

    .foreground {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
    }
  }
}

.gifting-page-subbar {
  position: relative;
  height: 250px;
  z-index: 25;
  background-color: rgb(245, 242, 238);

  @include media-breakpoint-down(xs) {
    height: 200px;
  }

  .caskshare-wood-grain {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.gifting-how-does-it-work {
  & > .row {
    background-color: #f5f2ee;
  }

  .icon-bar {
    img {
      max-height: 30px;
    }
  }

  .btn {
    color: white;
    background-color: #003f5a;
    border-color: #003f5a;
  }

  .separator-1 {
    padding-top: 3rem;
  }
}

.gifting-need-help-bar {
  height: 200px;
  position: relative;

  @include media-breakpoint-up(sm) {
    height: 220px;
  }
}

.gifting-warehouse {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 30vw;
    position: absolute;
    top: -3rem;
    bottom: -3rem;
    right: 0;
  }
}

.gift-the-unique-experience-bar {
  background-color: #111111;
  color: #896e4b;

  .separator {
    background-color: #896e4b;
  }
}

.gifting-modal {
  @include media-breakpoint-up(sm) {
    width: 80%;
  }

  // max-width: none !important;

  .modal-body {
    background-color: #f5f2ee;
    padding-left: 0;
    padding-right: 0;

    & [class*="col-"] {
      flex: 100%;
      max-width: 100%;

      .cask-image-component {
        max-width: 200px;
        margin: 0 auto;

        .signature-stamp-wrapper {
          display: none;
        }
      }
    }
  }
}
