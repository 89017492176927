@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.range {
  height: 80px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 0 65px 0 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .sliderValue {
    position: relative;
    width: 97%;
    margin: 0 auto;
    & span {
      position: absolute;
      height: 45px;
      width: 45px;
      transform: translateX(-50%) translateY(100%) scale(0);
      font-weight: 500;
      top: -40px;
      line-height: 55px;
      z-index: 2;
      color: #fff;
      transform-origin: bottom;
      transition: transform 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background: #e76852;
        border: 3px solid #fff;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        border-bottom-left-radius: 50%;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 95%;
    }
    @include media-breakpoint-down(xs) {
      width: 90%;
    }
  }
  .show {
    left: 50%;
    transform: translateX(-50%) translateY(0%) scale(1) !important;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    .value {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: #e76852;
      font-weight: 600;
    }
    .left {
      left: -25px;
    }
    .right {
      right: -50px;
    }
    input[type="range"] {
      height: 26px;
      -webkit-appearance: none;
      margin: 10px 0;
      width: 100%;
      &:focus {
        outline: none;
        &::-webkit-slider-runnable-track {
          background: #f5f2ee;
        }
        &::-ms-fill-lower {
          background: #f5f2ee;
        }
        &::-ms-fill-upper {
          background: #f5f2ee;
        }
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        animation: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #f5f2ee;
        border-radius: 50px;
        border: 0px solid #000000;
      }
      &::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #2497e3;
        height: 20px;
        width: 20px;
        border-radius: 50px;
        background: #e76852;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8px;
      }
      &::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        animation: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #f5f2ee;
        border-radius: 50px;
        border: 0px solid #000000;
      }
      &::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #2497e3;
        height: 20px;
        width: 20px;
        border-radius: 50px;
        background: #e76852;
        cursor: pointer;
      }
      &::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        animation: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      &::-ms-fill-lower {
        background: #f5f2ee;
        border: 0px solid #000000;
        border-radius: 100px;
        box-shadow: 0px 0px 0px #000000;
      }
      &::-ms-fill-upper {
        background: #f5f2ee;
        border: 0px solid #000000;
        border-radius: 100px;
        box-shadow: 0px 0px 0px #000000;
      }
      &::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #2497e3;
        height: 20px;
        width: 20px;
        border-radius: 50px;
        background: #e76852;
        cursor: pointer;
      }
    }
    .dataList {
      position: absolute;
      bottom: 40%;
      width: 97%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      & span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 10px;
          width: 1px;
          border: 2px solid #e76852ab;
          border-radius: 25px;
          transform: translateX(-50%);
        }
      }
      @include media-breakpoint-down(md) {
        width: 96%;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}

.submit {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & button {
    @include media-breakpoint-down(xs) {
      padding: 0.38rem 0.7rem;
    }
  }
  .inputNumber[type="number"] {
    margin-right: 0px;
    padding: 0.32rem 1.2rem;
    border-radius: 0px;
    border: 2px solid #e76852;
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}
