@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.quizPageContainer {
  @import "../../../styles/Global.scss";

  min-height: 100vh;
  display: flex;
  // visibility: hidden;
  // opacity: 0;
  & p,
  & label {
    // font-family: $helvetica;
    font-family: $gtPressuraBody;
    margin-bottom: 0;
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: bolder;
    line-height: normal;
  }

  .bodyWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerCol {
      // Welcome Page
      .welcomePage {
        max-width: 750px;
        display: flex;
        flex-direction: column;
        .header {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .body {
          text-align: center;
          & p {
            padding: 2rem 1rem;
            line-height: 1.4;
            & span {
              font-weight: bold;
            }
          }
        }
      }

      // Questions Page
      .questionsWrap {
        max-width: 650px;
        .questions {
          padding: 1rem 0;
          .radioWrap {
            display: flex;
            align-items: center;
            margin: 0.75rem 0;

            & input[type="radio"] {
              appearance: none;
              background-color: #fff;
              margin: 0;
              width: 1.5rem;
              height: 1.5rem;
              cursor: pointer;
              border: 0.115rem solid $black;
              border-radius: 50%;

              &:checked {
                border: 0.115rem solid $orange;
              }

              display: grid;
              place-content: center;
              &::before {
                content: "";
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
              }
              &:checked::before {
                background-color: $orange;
              }
            }

            & label {
              margin-bottom: 0;
              margin-left: 1rem;
              text-transform: capitalize;
              cursor: pointer;
              font-size: 1.1rem;
              letter-spacing: 1px;
              font-weight: 300;
              line-height: normal;
            }
            .activeLabel {
              color: $orange;
            }
          }
        }
        .buttonWrap {
          display: flex;
          width: 100%;
          & button {
            &:disabled {
              background-color: rgba($orange, $alpha: 0.6) !important;
              border-color: rgba($orange, $alpha: 0.45) !important;
              color: rgba($beige, $alpha: 0.6) !important;
            }
            &:last-child {
              margin-left: 2rem;
            }
          }
        }
      }
      // Email Page
      .emailPage {
        max-width: 650px;
        display: flex;
        flex-direction: column;
        .header {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .email {
          width: 100%;
          padding: 2rem 0rem;
          & input {
            width: 100%;
            border: 0.15rem solid rgba($orange, $alpha: 0.8);
            padding: 1rem;
            &:focus {
              outline: none !important;
              border-radius: 0;
              border: 0.15rem solid rgba($orange, $alpha: 0.8);
            }
          }
          .errorMessage {
            color: red;
            padding-top: 1rem;
          }
        }
        .body {
          display: flex;
          flex-direction: column;
          & p {
            padding-bottom: 2rem;
          }
          .btnPrimary {
            align-self: center;
          }
        }
      }
      // Results Page
      .resultsPage {
        max-width: 900px;
        display: flex;
        flex-direction: column;
        .flavourMapWrap {
          margin-top: 4rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          .content {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & h4 {
              & span {
                color: $orange;
              }
            }
            & p {
              & span {
                color: $orange;
              }
              &:first-of-type {
                padding: 1rem 0;
              }
            }
          }
          .flavourMap {
            width: 40%;
            .flavour {
              max-width: 18rem;
              padding: 0.1rem 2rem;
              margin: auto;
            }
          }
          @include media-breakpoint-down(sm) {
            .content {
              width: 100%;
            }
            .flavourMap {
              width: 100%;
              margin-top: 3rem;
            }
          }
        }
        .caskSelection {
          display: flex;
          flex-direction: row;
          min-height: 600px;
          flex-wrap: wrap;
          margin-top: 4rem;
          margin-bottom: 4rem;
          justify-content: center;
          .caskCardWrap {
            flex: 1;
            min-width: 280px;
            max-width: 350px;
            padding: 1rem;

            & > h5 {
              margin: 0 auto;
              width: fit-content;
              margin-bottom: 1rem;
            }
            @include media-breakpoint-down(md) {
              padding: 2rem 1rem;
            }
          }
          @include media-breakpoint-down(sm) {
            margin-top: 0rem;
            margin-bottom: 2rem;
          }
        }
        .startAgain {
          margin: 2rem 0;
        }
      }
    }
  }
}
